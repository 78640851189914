import gql from 'graphql-tag'
import * as Urql from 'urql'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
	[_ in K]?: never
}
export type Incremental<T> =
	| T
	| { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: { input: string; output: string }
	String: { input: string; output: string }
	Boolean: { input: boolean; output: boolean }
	Int: { input: number; output: number }
	Float: { input: number; output: number }
	BigInt: { input: any; output: any }
	DateTimeISO: { input: any; output: any }
	Decimal: { input: any; output: any }
	JSON: { input: any; output: any }
}

export enum ActionItemResoltionTypes {
	AddedToPims = 'added_to_pims',
	GetLockboxId = 'get_lockbox_id',
	LockboxMailed = 'lockbox_mailed',
	NoResolution = 'no_resolution',
	NotCollected = 'not_collected',
	PaidByOtherMeans = 'paid_by_other_means',
	PaymentEntered = 'payment_entered',
	ReranCheck = 'reran_check'
}

export enum ActionItemTypes {
	ArPaymentReceived = 'AR_PAYMENT_RECEIVED',
	FailedCheck = 'FAILED_CHECK',
	FailedLockbox = 'FAILED_LOCKBOX',
	MobilePayment = 'MOBILE_PAYMENT',
	OfflinePaymentFailed = 'OFFLINE_PAYMENT_FAILED',
	OfflinePaymentSucceeded = 'OFFLINE_PAYMENT_SUCCEEDED'
}

/** ActionNeeded item */
export type ActionNeeded = {
	__typename?: 'ActionNeeded'
	actionItemType: ActionItemTypes
	amount: Scalars['Float']['output']
	chargeId?: Maybe<Scalars['String']['output']>
	clientId?: Maybe<Scalars['String']['output']>
	clientName?: Maybe<Scalars['String']['output']>
	completedAt?: Maybe<Scalars['DateTimeISO']['output']>
	completedBy?: Maybe<Scalars['String']['output']>
	consolidator?: Maybe<Scalars['String']['output']>
	createdAt?: Maybe<Scalars['DateTimeISO']['output']>
	dateAdjustedInPims?: Maybe<Scalars['DateTimeISO']['output']>
	failureCode?: Maybe<Scalars['String']['output']>
	failureMessage?: Maybe<Scalars['String']['output']>
	id: Scalars['ID']['output']
	invoiceId?: Maybe<Scalars['String']['output']>
	isComplete: Scalars['Boolean']['output']
	metadata?: Maybe<Scalars['JSON']['output']>
	notes?: Maybe<Scalars['String']['output']>
	paid?: Maybe<Scalars['Boolean']['output']>
	paymentIntentId?: Maybe<Scalars['String']['output']>
	practice: ActionNeededPractice
	resolution?: Maybe<ActionItemResoltionTypes>
	resolutionNote?: Maybe<Scalars['String']['output']>
	scratchPaymentMethod?: Maybe<Scalars['String']['output']>
	stripeCreatedDate?: Maybe<Scalars['DateTimeISO']['output']>
	updatedAt?: Maybe<Scalars['DateTimeISO']['output']>
}

/** List of ActionNeeded items with pagination */
export type ActionNeededList = {
	__typename?: 'ActionNeededList'
	args: BaseListArgs
	items: Array<ActionNeeded>
	skip: Scalars['Float']['output']
	take?: Maybe<Scalars['Float']['output']>
	total: Scalars['Float']['output']
}

/** A sort order for a field on Action Needed */
export type ActionNeededOrderByInput = {
	actionItemType?: InputMaybe<SortOrder>
	amount?: InputMaybe<SortOrder>
	clientName?: InputMaybe<SortOrder>
	dateAdjustedInPims?: InputMaybe<SortOrder>
	id?: InputMaybe<SortOrder>
	practice?: InputMaybe<PracticeOrderByInput>
	practiceId?: InputMaybe<SortOrder>
	stripeCreatedDate?: InputMaybe<SortOrder>
}

/** ActionNeeded practice */
export type ActionNeededPractice = {
	__typename?: 'ActionNeededPractice'
	consolidatorReferenceCode?: Maybe<Scalars['String']['output']>
	consolidatorReferenceCode2?: Maybe<Scalars['String']['output']>
	id: Scalars['String']['output']
	name: Scalars['String']['output']
	netsuiteId?: Maybe<Scalars['String']['output']>
	skyworldId?: Maybe<Scalars['String']['output']>
}

/** A query filter for a list of actionNeeded rows */
export type ActionNeededWhereInput = {
	AND?: InputMaybe<Array<ActionNeededWhereInput>>
	OR?: InputMaybe<Array<ActionNeededWhereInput>>
	actionItemType?: InputMaybe<StringNullableFilter>
	amount?: InputMaybe<FloatFilter>
	clientName?: InputMaybe<StringFilter>
	id?: InputMaybe<StringFilter>
	isComplete?: InputMaybe<Scalars['Boolean']['input']>
	practice?: InputMaybe<PracticeWhereInput>
	stripeCreatedDate?: InputMaybe<DateTimeFilter>
}

/** Base Args for List queries */
export type BaseListArgs = {
	__typename?: 'BaseListArgs'
	skip: Scalars['Int']['output']
	take?: Maybe<Scalars['Int']['output']>
}

export type BoolFilter = {
	equals?: InputMaybe<Scalars['Boolean']['input']>
	not?: InputMaybe<NestedBoolFilter>
}

export type BoolNullableFilter = {
	equals?: InputMaybe<Scalars['Boolean']['input']>
	not?: InputMaybe<NestedBoolNullableFilter>
}

export type ConsolidatedPractice = {
	__typename?: 'ConsolidatedPractice'
	createdAt?: Maybe<Scalars['DateTimeISO']['output']>
	deletedAt?: Maybe<Scalars['DateTimeISO']['output']>
	id: Scalars['String']['output']
	isLendingEnabled?: Maybe<Scalars['Boolean']['output']>
	isPaymentEnabled?: Maybe<Scalars['Boolean']['output']>
	lendingId?: Maybe<Scalars['Int']['output']>
	paymentId?: Maybe<Scalars['String']['output']>
	status?: Maybe<Scalars['JSON']['output']>
	updatedAt?: Maybe<Scalars['DateTimeISO']['output']>
}

export type Consolidator = {
	__typename?: 'Consolidator'
	canadaAccountInfo?: Maybe<Scalars['JSON']['output']>
	customFields?: Maybe<Scalars['JSON']['output']>
	customization?: Maybe<Scalars['JSON']['output']>
	featureFlags?: Maybe<Scalars['JSON']['output']>
	financingFlags?: Maybe<Scalars['JSON']['output']>
	id: Scalars['String']['output']
	mcc?: Maybe<Scalars['String']['output']>
	name?: Maybe<Scalars['String']['output']>
	ownerLastFour?: Maybe<Scalars['String']['output']>
	timezone?: Maybe<Scalars['String']['output']>
}

export type ConsolidatorOrderByInput = {
	createdAt?: InputMaybe<SortOrder>
	id?: InputMaybe<SortOrder>
	name?: InputMaybe<SortOrder>
	updatedAt?: InputMaybe<SortOrder>
}

export type ConsolidatorRelationFilter = {
	is?: InputMaybe<ConsolidatorWhereInput>
	isNot?: InputMaybe<ConsolidatorWhereInput>
}

export type ConsolidatorWhereInput = {
	AND?: InputMaybe<Array<ConsolidatorWhereInput>>
	NOT?: InputMaybe<Array<ConsolidatorWhereInput>>
	OR?: InputMaybe<Array<ConsolidatorWhereInput>>
	createdAt?: InputMaybe<DateTimeFilter>
	id?: InputMaybe<StringFilter>
	name?: InputMaybe<StringNullableFilter>
	updatedAt?: InputMaybe<DateTimeFilter>
}

export enum Currency {
	Cad = 'CAD',
	Usd = 'USD'
}

export enum CustomPayoutBatchStatus {
	Failed = 'FAILED',
	Processed = 'PROCESSED',
	Processing = 'PROCESSING',
	Scheduled = 'SCHEDULED'
}

export enum CustomPayoutBatchTarget {
	Consolidator = 'CONSOLIDATOR',
	Practice = 'PRACTICE'
}

export enum CustomPayoutBatchTypes {
	Card = 'CARD',
	Check = 'CHECK'
}

/** List of CustomPayoutPracticeBatch Details with pagination */
export type CustomPayoutDetailList = {
	__typename?: 'CustomPayoutDetailList'
	args: BaseListArgs
	items: Array<CustomPayoutDetails>
	skip: Scalars['Float']['output']
	take?: Maybe<Scalars['Float']['output']>
	total: Scalars['Float']['output']
}

/** CustomPayoutDetails item */
export type CustomPayoutDetails = {
	__typename?: 'CustomPayoutDetails'
	cardBrand?: Maybe<Scalars['String']['output']>
	cardFunding?: Maybe<Scalars['String']['output']>
	cardholderName?: Maybe<Scalars['String']['output']>
	consPayoutEstimatedArrivalAt?: Maybe<Scalars['DateTimeISO']['output']>
	consolidatorId?: Maybe<Scalars['String']['output']>
	createdAt?: Maybe<Scalars['DateTimeISO']['output']>
	currency: Scalars['String']['output']
	customPayoutConsolidatorBatchId?: Maybe<Scalars['String']['output']>
	customPayoutPracticeBatchId?: Maybe<Scalars['String']['output']>
	id: Scalars['ID']['output']
	last4?: Maybe<Scalars['String']['output']>
	lockboxId?: Maybe<Scalars['String']['output']>
	paymentIntentId?: Maybe<Scalars['String']['output']>
	paymentMethodType?: Maybe<Scalars['String']['output']>
	paymentType?: Maybe<Scalars['String']['output']>
	payoutDescription?: Maybe<Scalars['String']['output']>
	payoutEstimatedArrivalAt?: Maybe<Scalars['DateTimeISO']['output']>
	payoutStatementDescriptor?: Maybe<Scalars['String']['output']>
	pimsClientId?: Maybe<Scalars['String']['output']>
	practiceConsolidatorReferenceCode?: Maybe<Scalars['String']['output']>
	practiceConsolidatorReferenceCode2?: Maybe<Scalars['String']['output']>
	practiceId?: Maybe<Scalars['String']['output']>
	practiceName?: Maybe<Scalars['String']['output']>
	scratchPaymentMethod?: Maybe<Scalars['String']['output']>
	scratchPaymentRequestType?: Maybe<Scalars['String']['output']>
	stripePayoutId?: Maybe<Scalars['String']['output']>
	surchargeFee?: Maybe<Scalars['Float']['output']>
	transactionAmount?: Maybe<Scalars['Float']['output']>
	transactionDate?: Maybe<Scalars['DateTimeISO']['output']>
	transactionFee?: Maybe<Scalars['Float']['output']>
	transactionNet?: Maybe<Scalars['Float']['output']>
	transactionType: CustomPayoutTransactionType
}

/** A sort order for a field on Custom Payout Detail */
export type CustomPayoutDetailsOrderByInput = {
	id?: InputMaybe<SortOrder>
	payoutEstimatedArrivalAt?: InputMaybe<SortOrder>
	transactionDate?: InputMaybe<SortOrder>
}

/** Sum of Custom Payout Details by Card Brand */
export type CustomPayoutDetailsSumByCardBrandList = {
	__typename?: 'CustomPayoutDetailsSumByCardBrandList'
	items: Array<CustomPayoutDetails>
}

/** A query filter for a list of customPayoutDetail rows */
export type CustomPayoutDetailsWhereInput = {
	AND?: InputMaybe<Array<CustomPayoutDetailsWhereInput>>
	OR?: InputMaybe<Array<CustomPayoutDetailsWhereInput>>
	consPayoutEstimatedArrivalAt?: InputMaybe<DateTimeFilter>
	id?: InputMaybe<StringFilter>
	paymentType?: InputMaybe<StringNullableFilter>
	payoutEstimatedArrivalAt?: InputMaybe<DateTimeFilter>
	transactionDate?: InputMaybe<DateTimeFilter>
	transactionType?: InputMaybe<StringNullableFilter>
}

/** CustomPayout practice */
export type CustomPayoutPractice = {
	__typename?: 'CustomPayoutPractice'
	consolidatorReferenceCode?: Maybe<Scalars['String']['output']>
	consolidatorReferenceCode2?: Maybe<Scalars['String']['output']>
	id: Scalars['String']['output']
	name: Scalars['String']['output']
}

/** CustomPayoutPracticeBatch item */
export type CustomPayoutPracticeBatch = {
	__typename?: 'CustomPayoutPracticeBatch'
	batchCriteria?: Maybe<Scalars['JSON']['output']>
	batchType: CustomPayoutBatchTypes
	completedAt?: Maybe<Scalars['DateTimeISO']['output']>
	consolidator?: Maybe<Scalars['String']['output']>
	createdAt?: Maybe<Scalars['DateTimeISO']['output']>
	currency: Currency
	customPayoutConsolidatorBatchId?: Maybe<Scalars['String']['output']>
	customPayoutCronjobLogId: Scalars['Int']['output']
	description?: Maybe<Scalars['String']['output']>
	disputeAmount?: Maybe<Scalars['Float']['output']>
	disputeCount?: Maybe<Scalars['Int']['output']>
	failureReason?: Maybe<Scalars['String']['output']>
	id: Scalars['ID']['output']
	notes?: Maybe<Scalars['String']['output']>
	paymentAmount?: Maybe<Scalars['Float']['output']>
	paymentApplicationFee?: Maybe<Scalars['Float']['output']>
	paymentCount?: Maybe<Scalars['Int']['output']>
	payoutAmount?: Maybe<Scalars['Float']['output']>
	payoutDate?: Maybe<Scalars['DateTimeISO']['output']>
	payoutEstimatedArrivalAt?: Maybe<Scalars['DateTimeISO']['output']>
	payoutSentAt?: Maybe<Scalars['DateTimeISO']['output']>
	practice: CustomPayoutPractice
	refundAmount?: Maybe<Scalars['Float']['output']>
	refundApplicationFee?: Maybe<Scalars['Float']['output']>
	refundCount?: Maybe<Scalars['Int']['output']>
	retryCount: Scalars['Int']['output']
	startWindowDate?: Maybe<Scalars['DateTimeISO']['output']>
	startedAt?: Maybe<Scalars['DateTimeISO']['output']>
	statementDescriptor?: Maybe<Scalars['String']['output']>
	status: CustomPayoutBatchStatus
	stripePayoutId?: Maybe<Scalars['String']['output']>
	stripeTransferId?: Maybe<Scalars['String']['output']>
	target: CustomPayoutBatchTarget
	updatedAt?: Maybe<Scalars['DateTimeISO']['output']>
}

/** List of CustomPayoutPracticeBatch items with pagination */
export type CustomPayoutPracticeBatchList = {
	__typename?: 'CustomPayoutPracticeBatchList'
	args: BaseListArgs
	items: Array<CustomPayoutPracticeBatch>
	skip: Scalars['Float']['output']
	take?: Maybe<Scalars['Float']['output']>
	total: Scalars['Float']['output']
}

/** A sort order for a field on Custom Payout Practice Batch */
export type CustomPayoutPracticeBatchOrderByInput = {
	id?: InputMaybe<SortOrder>
	payoutDate?: InputMaybe<SortOrder>
	payoutEstimatedArrivalAt?: InputMaybe<SortOrder>
	practice?: InputMaybe<PracticeOrderByInput>
}

/** A query filter for a list of customPayoutPracticeBatch rows */
export type CustomPayoutPracticeBatchWhereInput = {
	AND?: InputMaybe<Array<CustomPayoutPracticeBatchWhereInput>>
	OR?: InputMaybe<Array<CustomPayoutPracticeBatchWhereInput>>
	customPayoutBatchType?: InputMaybe<StringNullableFilter>
	id?: InputMaybe<StringFilter>
	payoutDate?: InputMaybe<DateTimeFilter>
	payoutEstimatedArrivalAt?: InputMaybe<DateTimeFilter>
	practice?: InputMaybe<PracticeWhereInput>
}

export type CustomPayoutSettingsInput = {
	delayProcessingHours?: InputMaybe<Scalars['Float']['input']>
	moveToPlatform?: InputMaybe<Scalars['Boolean']['input']>
	payoutDescriptor1?: InputMaybe<Scalars['String']['input']>
	payoutDescriptor2?: InputMaybe<Scalars['String']['input']>
	startWindowHour?: InputMaybe<Scalars['Float']['input']>
	subtractedDays?: InputMaybe<Scalars['Float']['input']>
	timezone?: InputMaybe<Scalars['String']['input']>
}

export enum CustomPayoutTransactionType {
	Charge = 'Charge',
	Dispute = 'Dispute',
	Refund = 'Refund'
}

/** Daily Totals grouped by card */
export type DailyTotalsByCard = {
	__typename?: 'DailyTotalsByCard'
	/** The brand of the card used for the payment (e.g., Visa, MasterCard). */
	cardBrand: Scalars['String']['output']
	/** Total sum of payments made on the day. */
	sumPayments: Scalars['BigInt']['output']
	/** Total sum of refunds made on the day. */
	sumRefunds: Scalars['BigInt']['output']
	/** Net total, considering both payments and refunds. */
	total: Scalars['BigInt']['output']
}

/** Daily totals grouped by payment type */
export type DailyTotalsByType = {
	__typename?: 'DailyTotalsByType'
	/** The payment type used (e.g., Credit Card, Pix, Boleto). */
	paymentType: Scalars['String']['output']
	/** Total sum of payments made on the day. */
	sumPayments: Scalars['BigInt']['output']
	/** Total sum of refunds made on the day. */
	sumRefunds: Scalars['BigInt']['output']
	/** Net total, considering both payments and refunds. */
	total: Scalars['BigInt']['output']
}

/** Response containing daily totals grouped by card or by payment type. */
export type DailyTotalsResponse = {
	__typename?: 'DailyTotalsResponse'
	/** Daily totals grouped by card brand. */
	byCard: Array<DailyTotalsByCard>
	/** Daily totals grouped by payment type. */
	byPaymentType: Array<DailyTotalsByType>
	practiceId: Scalars['String']['output']
}

/** A query filter for daily totals */
export type DailyTotalsWhereInput = {
	AND?: InputMaybe<Array<DailyTotalsWhereInput>>
	NOT?: InputMaybe<Array<DailyTotalsWhereInput>>
	OR?: InputMaybe<Array<DailyTotalsWhereInput>>
	dateToFilter?: InputMaybe<DateTimeNullableFilter>
	practice?: InputMaybe<PracticeWhereInput>
	practiceId?: InputMaybe<StringFilter>
}

export type DateTimeFilter = {
	equals?: InputMaybe<Scalars['DateTimeISO']['input']>
	gt?: InputMaybe<Scalars['DateTimeISO']['input']>
	gte?: InputMaybe<Scalars['DateTimeISO']['input']>
	in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>
	lt?: InputMaybe<Scalars['DateTimeISO']['input']>
	lte?: InputMaybe<Scalars['DateTimeISO']['input']>
	not?: InputMaybe<NestedDateTimeFilter>
	notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>
}

export type DateTimeNullableFilter = {
	equals?: InputMaybe<Scalars['DateTimeISO']['input']>
	gt?: InputMaybe<Scalars['DateTimeISO']['input']>
	gte?: InputMaybe<Scalars['DateTimeISO']['input']>
	in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>
	lt?: InputMaybe<Scalars['DateTimeISO']['input']>
	lte?: InputMaybe<Scalars['DateTimeISO']['input']>
	not?: InputMaybe<NestedDateTimeNullableFilter>
	notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>
}

/** Enable CustomPayoutV2 Response */
export type EnableCustomPayoutV2Response = {
	__typename?: 'EnableCustomPayoutV2Response'
	practicePayoutInfo: PracticeCustomPayoutInfo
}

export type FloatFilter = {
	equals?: InputMaybe<Scalars['Float']['input']>
	gt?: InputMaybe<Scalars['Float']['input']>
	gte?: InputMaybe<Scalars['Float']['input']>
	in?: InputMaybe<Array<Scalars['Float']['input']>>
	lt?: InputMaybe<Scalars['Float']['input']>
	lte?: InputMaybe<Scalars['Float']['input']>
	not?: InputMaybe<NestedFloatFilter>
	notIn?: InputMaybe<Array<Scalars['Float']['input']>>
}

export type IntFilter = {
	equals?: InputMaybe<Scalars['Int']['input']>
	gt?: InputMaybe<Scalars['Int']['input']>
	gte?: InputMaybe<Scalars['Int']['input']>
	in?: InputMaybe<Array<Scalars['Int']['input']>>
	lt?: InputMaybe<Scalars['Int']['input']>
	lte?: InputMaybe<Scalars['Int']['input']>
	not?: InputMaybe<NestedIntFilter>
	notIn?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type IntNullableFilter = {
	equals?: InputMaybe<Scalars['Int']['input']>
	gt?: InputMaybe<Scalars['Int']['input']>
	gte?: InputMaybe<Scalars['Int']['input']>
	in?: InputMaybe<Array<Scalars['Int']['input']>>
	lt?: InputMaybe<Scalars['Int']['input']>
	lte?: InputMaybe<Scalars['Int']['input']>
	not?: InputMaybe<NestedIntNullableFilter>
	notIn?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type JsonNullableFilter = {
	array_contains?: InputMaybe<Scalars['JSON']['input']>
	array_ends_with?: InputMaybe<Scalars['JSON']['input']>
	array_starts_with?: InputMaybe<Scalars['JSON']['input']>
	equals?: InputMaybe<Scalars['JSON']['input']>
	gt?: InputMaybe<Scalars['JSON']['input']>
	gte?: InputMaybe<Scalars['JSON']['input']>
	lt?: InputMaybe<Scalars['JSON']['input']>
	lte?: InputMaybe<Scalars['JSON']['input']>
	not?: InputMaybe<Scalars['JSON']['input']>
	path?: InputMaybe<Array<Scalars['String']['input']>>
	string_contains?: InputMaybe<Scalars['String']['input']>
	string_ends_with?: InputMaybe<Scalars['String']['input']>
	string_starts_with?: InputMaybe<Scalars['String']['input']>
}

export type MonthlyStatement = {
	__typename?: 'MonthlyStatement'
	amountSettled: Scalars['Decimal']['output']
	currency: Scalars['String']['output']
	dayLines?: Maybe<Array<MonthlyStatementDayLine>>
	feeLines?: Maybe<Array<MonthlyStatementFeeLine>>
	feeTier: Scalars['String']['output']
	hardwareCost?: Maybe<Scalars['Decimal']['output']>
	hardwareOrders?: Maybe<Array<MonthlyStatementHardwareOrder>>
	id: Scalars['String']['output']
	month: Scalars['DateTimeISO']['output']
	numAuthorized: Scalars['Float']['output']
	numSettled: Scalars['Float']['output']
	otherFees?: Maybe<Scalars['Decimal']['output']>
	paymentLines?: Maybe<Array<MonthlyStatementPaymentLine>>
	practiceId: Scalars['String']['output']
	surchargeFees?: Maybe<Scalars['Decimal']['output']>
	surchargeFeesSaved?: Maybe<Scalars['Decimal']['output']>
	surchargeVolume?: Maybe<Scalars['Decimal']['output']>
	totalFees: Scalars['Decimal']['output']
}

export type MonthlyStatementDayLine = {
	__typename?: 'MonthlyStatementDayLine'
	amountSettled: Scalars['Decimal']['output']
	day: Scalars['DateTimeISO']['output']
	numAuthorized: Scalars['Float']['output']
	numSettled: Scalars['Float']['output']
	surchargeFees?: Maybe<Scalars['Decimal']['output']>
	surchargeFeesSaved?: Maybe<Scalars['Decimal']['output']>
	surchargeVolume?: Maybe<Scalars['Decimal']['output']>
	totalFees: Scalars['Decimal']['output']
}

export type MonthlyStatementFeeLine = {
	__typename?: 'MonthlyStatementFeeLine'
	amount?: Maybe<Scalars['Decimal']['output']>
	feeType: Scalars['String']['output']
	rate?: Maybe<Scalars['Decimal']['output']>
	totalFees?: Maybe<Scalars['Decimal']['output']>
}

export type MonthlyStatementHardwareOrder = {
	__typename?: 'MonthlyStatementHardwareOrder'
	amountDue?: Maybe<Scalars['Decimal']['output']>
	details?: Maybe<Scalars['JSON']['output']>
	hardwareOrderId: Scalars['String']['output']
	metadata?: Maybe<Scalars['JSON']['output']>
	shipping?: Maybe<Scalars['Decimal']['output']>
	tax?: Maybe<Scalars['Decimal']['output']>
	totalAmount?: Maybe<Scalars['Decimal']['output']>
}

export type MonthlyStatementOrderByInput = {
	id?: InputMaybe<SortOrder>
	month?: InputMaybe<SortOrder>
}

export type MonthlyStatementPaymentLine = {
	__typename?: 'MonthlyStatementPaymentLine'
	amountSettled: Scalars['Decimal']['output']
	numAuthorized: Scalars['Float']['output']
	numSettled: Scalars['Float']['output']
	paymentMethodType: Scalars['String']['output']
	surchargeFees?: Maybe<Scalars['Decimal']['output']>
	surchargeFeesSaved?: Maybe<Scalars['Decimal']['output']>
	surchargeVolume?: Maybe<Scalars['Decimal']['output']>
	totalFees: Scalars['Decimal']['output']
}

export type MonthlyStatementWhereInput = {
	AND?: InputMaybe<Array<MonthlyStatementWhereInput>>
	NOT?: InputMaybe<Array<MonthlyStatementWhereInput>>
	OR?: InputMaybe<Array<MonthlyStatementWhereInput>>
	id?: InputMaybe<StringFilter>
	month?: InputMaybe<DateTimeFilter>
	practice?: InputMaybe<PracticeRelationFilter>
	practiceId?: InputMaybe<StringFilter>
}

export type Mutation = {
	__typename?: 'Mutation'
	enableCustomPayoutV2: EnableCustomPayoutV2Response
	processUploadedFile: PaymentRequestFile
	updateConsolidatedPracticeStatus: ConsolidatedPractice
	updatePaymentRequest: PaymentRequest
	upsertCustomPayoutSettings: UpsertCustomPayoutSettingsResponse
	upsertRefundRequest: RefundRequest
}

export type MutationEnableCustomPayoutV2Args = {
	practiceId: Scalars['ID']['input']
}

export type MutationProcessUploadedFileArgs = {
	fileName: Scalars['String']['input']
}

export type MutationUpdateConsolidatedPracticeStatusArgs = {
	id: Scalars['String']['input']
	processStatus?: InputMaybe<Scalars['String']['input']>
	processType?: InputMaybe<Scalars['String']['input']>
}

export type MutationUpdatePaymentRequestArgs = {
	arDeliveryMethod?: InputMaybe<Scalars['String']['input']>
	customerEmail?: InputMaybe<Scalars['String']['input']>
	customerPhone?: InputMaybe<Scalars['String']['input']>
	id?: InputMaybe<Scalars['String']['input']>
}

export type MutationUpsertCustomPayoutSettingsArgs = {
	practiceId: Scalars['String']['input']
	settings: CustomPayoutSettingsInput
}

export type MutationUpsertRefundRequestArgs = {
	chargeId?: InputMaybe<Scalars['String']['input']>
	id?: InputMaybe<Scalars['ID']['input']>
	notes?: InputMaybe<Scalars['String']['input']>
	paymentIntentId?: InputMaybe<Scalars['String']['input']>
	pimsClientId?: InputMaybe<Scalars['String']['input']>
	requestedRefundAmount?: InputMaybe<Scalars['Float']['input']>
	status?: InputMaybe<Scalars['String']['input']>
	writebackPimsClientId?: InputMaybe<Scalars['String']['input']>
}

export type NestedBoolFilter = {
	equals?: InputMaybe<Scalars['Boolean']['input']>
	not?: InputMaybe<NestedBoolFilter>
}

export type NestedBoolNullableFilter = {
	equals?: InputMaybe<Scalars['Boolean']['input']>
	not?: InputMaybe<NestedBoolNullableFilter>
}

export type NestedDateTimeFilter = {
	equals?: InputMaybe<Scalars['DateTimeISO']['input']>
	gt?: InputMaybe<Scalars['DateTimeISO']['input']>
	gte?: InputMaybe<Scalars['DateTimeISO']['input']>
	in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>
	lt?: InputMaybe<Scalars['DateTimeISO']['input']>
	lte?: InputMaybe<Scalars['DateTimeISO']['input']>
	not?: InputMaybe<NestedDateTimeFilter>
	notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>
}

export type NestedDateTimeNullableFilter = {
	equals?: InputMaybe<Scalars['DateTimeISO']['input']>
	gt?: InputMaybe<Scalars['DateTimeISO']['input']>
	gte?: InputMaybe<Scalars['DateTimeISO']['input']>
	in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>
	lt?: InputMaybe<Scalars['DateTimeISO']['input']>
	lte?: InputMaybe<Scalars['DateTimeISO']['input']>
	not?: InputMaybe<NestedDateTimeNullableFilter>
	notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>
}

export type NestedFloatFilter = {
	equals?: InputMaybe<Scalars['Float']['input']>
	gt?: InputMaybe<Scalars['Float']['input']>
	gte?: InputMaybe<Scalars['Float']['input']>
	in?: InputMaybe<Array<Scalars['Float']['input']>>
	lt?: InputMaybe<Scalars['Float']['input']>
	lte?: InputMaybe<Scalars['Float']['input']>
	not?: InputMaybe<NestedFloatFilter>
	notIn?: InputMaybe<Array<Scalars['Float']['input']>>
}

export type NestedIntFilter = {
	equals?: InputMaybe<Scalars['Int']['input']>
	gt?: InputMaybe<Scalars['Int']['input']>
	gte?: InputMaybe<Scalars['Int']['input']>
	in?: InputMaybe<Array<Scalars['Int']['input']>>
	lt?: InputMaybe<Scalars['Int']['input']>
	lte?: InputMaybe<Scalars['Int']['input']>
	not?: InputMaybe<NestedIntFilter>
	notIn?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type NestedIntNullableFilter = {
	equals?: InputMaybe<Scalars['Int']['input']>
	gt?: InputMaybe<Scalars['Int']['input']>
	gte?: InputMaybe<Scalars['Int']['input']>
	in?: InputMaybe<Array<Scalars['Int']['input']>>
	lt?: InputMaybe<Scalars['Int']['input']>
	lte?: InputMaybe<Scalars['Int']['input']>
	not?: InputMaybe<NestedIntNullableFilter>
	notIn?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type NestedStringFilter = {
	contains?: InputMaybe<Scalars['String']['input']>
	endsWith?: InputMaybe<Scalars['String']['input']>
	equals?: InputMaybe<Scalars['String']['input']>
	gt?: InputMaybe<Scalars['String']['input']>
	gte?: InputMaybe<Scalars['String']['input']>
	in?: InputMaybe<Array<Scalars['String']['input']>>
	lt?: InputMaybe<Scalars['String']['input']>
	lte?: InputMaybe<Scalars['String']['input']>
	not?: InputMaybe<NestedStringFilter>
	notIn?: InputMaybe<Array<Scalars['String']['input']>>
	startsWith?: InputMaybe<Scalars['String']['input']>
}

export type NestedStringNullableFilter = {
	contains?: InputMaybe<Scalars['String']['input']>
	endsWith?: InputMaybe<Scalars['String']['input']>
	equals?: InputMaybe<Scalars['String']['input']>
	gt?: InputMaybe<Scalars['String']['input']>
	gte?: InputMaybe<Scalars['String']['input']>
	in?: InputMaybe<Array<Scalars['String']['input']>>
	lt?: InputMaybe<Scalars['String']['input']>
	lte?: InputMaybe<Scalars['String']['input']>
	not?: InputMaybe<NestedStringNullableFilter>
	notIn?: InputMaybe<Array<Scalars['String']['input']>>
	startsWith?: InputMaybe<Scalars['String']['input']>
}

export enum NullsOrder {
	First = 'first',
	Last = 'last'
}

/** A payment history row, including charges, refunds, checks, and disputes. */
export type PaymentHistory = {
	__typename?: 'PaymentHistory'
	additionalNotes?: Maybe<Scalars['String']['output']>
	/** The total amount paid or refunded in cents */
	amount: Scalars['Int']['output']
	amountCapturable?: Maybe<Scalars['Int']['output']>
	amountCaptured?: Maybe<Scalars['Int']['output']>
	/** The total amount due to or from the customer without surcharge, refunds negative */
	amountDue: Scalars['Int']['output']
	/** The total amount due to or from the customer without surcharge, refunds positive */
	amountDueAbs: Scalars['Int']['output']
	amountReceived?: Maybe<Scalars['Int']['output']>
	amountRefunded?: Maybe<Scalars['Int']['output']>
	amountRefundedSurchargeFee?: Maybe<Scalars['Int']['output']>
	amountRefundedWithoutSurcharge?: Maybe<Scalars['Int']['output']>
	amountWithoutSurcharge?: Maybe<Scalars['Int']['output']>
	applicationFeeAmount?: Maybe<Scalars['Int']['output']>
	applicationId?: Maybe<Scalars['String']['output']>
	canceledAt?: Maybe<Scalars['DateTimeISO']['output']>
	cancellationReason?: Maybe<Scalars['String']['output']>
	captureMethod?: Maybe<Scalars['String']['output']>
	cardBrand?: Maybe<Scalars['String']['output']>
	cardType?: Maybe<Scalars['String']['output']>
	cardholderName?: Maybe<Scalars['String']['output']>
	chargeId?: Maybe<Scalars['String']['output']>
	charges?: Maybe<Scalars['JSON']['output']>
	clinicName?: Maybe<Scalars['String']['output']>
	confirmationMethod?: Maybe<Scalars['String']['output']>
	consolidatorId?: Maybe<Scalars['String']['output']>
	consolidatorReferenceCode?: Maybe<Scalars['String']['output']>
	consolidatorReferenceCode2?: Maybe<Scalars['String']['output']>
	createdAt: Scalars['DateTimeISO']['output']
	createdByEmail?: Maybe<Scalars['String']['output']>
	createdByUser?: Maybe<Scalars['String']['output']>
	currency: Scalars['String']['output']
	customerId?: Maybe<Scalars['String']['output']>
	customerName?: Maybe<Scalars['String']['output']>
	dateAdjustedInPims?: Maybe<Scalars['DateTimeISO']['output']>
	dateToFilter?: Maybe<Scalars['DateTimeISO']['output']>
	description?: Maybe<Scalars['String']['output']>
	disputeId?: Maybe<Scalars['String']['output']>
	disputeStatus?: Maybe<Scalars['String']['output']>
	disputed?: Maybe<Scalars['Boolean']['output']>
	failureCode?: Maybe<Scalars['String']['output']>
	failureReason?: Maybe<Scalars['String']['output']>
	id: Scalars['String']['output']
	invoiceId?: Maybe<Scalars['String']['output']>
	last4?: Maybe<Scalars['String']['output']>
	lockboxId?: Maybe<Scalars['String']['output']>
	metadata?: Maybe<Scalars['JSON']['output']>
	notes?: Maybe<Scalars['JSON']['output']>
	/** The type of object of this payment: charge, refund, lockbox, or dispute */
	object?: Maybe<Scalars['String']['output']>
	originalChargeAmount?: Maybe<Scalars['Int']['output']>
	originalChargeAmountDue?: Maybe<Scalars['Int']['output']>
	originalChargeDate?: Maybe<Scalars['DateTimeISO']['output']>
	originalChargeSurcharge?: Maybe<Scalars['Boolean']['output']>
	originalChargeSurchargeFee?: Maybe<Scalars['Int']['output']>
	paymentIntentId?: Maybe<Scalars['String']['output']>
	paymentMethodId?: Maybe<Scalars['String']['output']>
	paymentMethodOptions?: Maybe<Scalars['JSON']['output']>
	paymentMethodType?: Maybe<Scalars['String']['output']>
	payoutDate?: Maybe<Scalars['DateTimeISO']['output']>
	payoutDescription?: Maybe<Scalars['String']['output']>
	payoutEstimatedArrivalAt?: Maybe<Scalars['DateTimeISO']['output']>
	payoutSentAt?: Maybe<Scalars['DateTimeISO']['output']>
	payoutStatus?: Maybe<Scalars['String']['output']>
	pimsClientId?: Maybe<Scalars['String']['output']>
	practice: Practice
	practiceId: Scalars['String']['output']
	refundReason?: Maybe<Scalars['String']['output']>
	refundType?: Maybe<Scalars['String']['output']>
	scratchPaymentMethod?: Maybe<Scalars['String']['output']>
	scratchPaymentRequestType?: Maybe<Scalars['String']['output']>
	statementDescriptor?: Maybe<Scalars['String']['output']>
	/** The stripe status of the underlying transaction */
	status: Scalars['String']['output']
	stripePayoutiD?: Maybe<Scalars['String']['output']>
	subscriptionId?: Maybe<Scalars['String']['output']>
	subscriptionScheduleId?: Maybe<Scalars['String']['output']>
	succeededAt?: Maybe<Scalars['DateTimeISO']['output']>
	surcharge?: Maybe<Scalars['Boolean']['output']>
	surchargeFee?: Maybe<Scalars['Int']['output']>
	/** The overall status of the transaction, possibly 'refunded-transactions' or 'uncaptured'. */
	transactionStatus: Scalars['String']['output']
	updatedAt: Scalars['DateTimeISO']['output']
}

/** List of PaymentHistory items with a count for pagination */
export type PaymentHistoryListWithCount = {
	__typename?: 'PaymentHistoryListWithCount'
	items: Array<PaymentHistory>
	total: Scalars['Float']['output']
}

/** List of PaymentHistory items with a summaries all at once */
export type PaymentHistoryListWithSummary = {
	__typename?: 'PaymentHistoryListWithSummary'
	items: Array<PaymentHistory>
	summary: PaymentHistorySummary
}

/** A sort order for a field on PaymentHistory */
export type PaymentHistoryOrderByInput = {
	amount?: InputMaybe<SortOrder>
	amountCapturable?: InputMaybe<SortOrder>
	amountDue?: InputMaybe<SortOrder>
	amountDueAbs?: InputMaybe<SortOrder>
	amountReceived?: InputMaybe<SortOrderInput>
	amountRefunded?: InputMaybe<SortOrderInput>
	amountWithoutSurcharge?: InputMaybe<SortOrderInput>
	applicationFeeAmount?: InputMaybe<SortOrder>
	applicationId?: InputMaybe<SortOrderInput>
	canceledAt?: InputMaybe<SortOrderInput>
	cancellationReason?: InputMaybe<SortOrderInput>
	captureMethod?: InputMaybe<SortOrder>
	cardBrand?: InputMaybe<SortOrderInput>
	cardType?: InputMaybe<SortOrderInput>
	cardholderName?: InputMaybe<SortOrderInput>
	charges?: InputMaybe<SortOrderInput>
	confirmationMethod?: InputMaybe<SortOrderInput>
	consolidatorId?: InputMaybe<SortOrderInput>
	createdAt?: InputMaybe<SortOrder>
	createdByEmail?: InputMaybe<SortOrderInput>
	createdByUser?: InputMaybe<SortOrderInput>
	currency?: InputMaybe<SortOrder>
	customerId?: InputMaybe<SortOrderInput>
	customerName?: InputMaybe<SortOrderInput>
	dateToFilter?: InputMaybe<SortOrder>
	description?: InputMaybe<SortOrderInput>
	failureCode?: InputMaybe<SortOrderInput>
	failureReason?: InputMaybe<SortOrderInput>
	id?: InputMaybe<SortOrder>
	invoiceId?: InputMaybe<SortOrderInput>
	last4?: InputMaybe<SortOrderInput>
	metadata?: InputMaybe<SortOrderInput>
	notes?: InputMaybe<SortOrderInput>
	paymentMethodId?: InputMaybe<SortOrderInput>
	paymentMethodOptions?: InputMaybe<SortOrderInput>
	paymentMethodType?: InputMaybe<SortOrderInput>
	pimsClientId?: InputMaybe<SortOrderInput>
	practice?: InputMaybe<PracticeOrderByInput>
	practiceId?: InputMaybe<SortOrder>
	scratchPaymentMethod?: InputMaybe<SortOrderInput>
	scratchPaymentRequestType?: InputMaybe<SortOrderInput>
	status?: InputMaybe<SortOrder>
	subscriptionId?: InputMaybe<SortOrderInput>
	subscriptionScheduleId?: InputMaybe<SortOrderInput>
	succeededAt?: InputMaybe<SortOrderInput>
	surchargeFee?: InputMaybe<SortOrderInput>
	updatedAt?: InputMaybe<SortOrder>
}

/** Payment History Summary for a set of rows, including count and aggregation of amounts. */
export type PaymentHistorySummary = {
	__typename?: 'PaymentHistorySummary'
	/** Total number of payments */
	count: Scalars['Int']['output']
	/** Total amount of the list in cents */
	totalAmount?: Maybe<Scalars['BigInt']['output']>
	/** Total amount failed for the list in cents */
	totalFailed?: Maybe<Scalars['BigInt']['output']>
	/** Total amount for failed echecks for the list in cents */
	totalFailedECheck?: Maybe<Scalars['BigInt']['output']>
	/** Total amount paid for the list in cents */
	totalPaid?: Maybe<Scalars['BigInt']['output']>
	/** Total amount pending for the list in cents */
	totalPending?: Maybe<Scalars['BigInt']['output']>
	/** Total amount refunded for the list in cents */
	totalRefunded?: Maybe<Scalars['BigInt']['output']>
	/** Total amount for returned echecks for the list in cents */
	totalReturnedECheck?: Maybe<Scalars['BigInt']['output']>
	/** Total amount uncaptured for the list in cents */
	totalUncaptured?: Maybe<Scalars['BigInt']['output']>
}

/** A query filter for a list of PaymentHistory rows */
export type PaymentHistoryWhereInput = {
	AND?: InputMaybe<Array<PaymentHistoryWhereInput>>
	NOT?: InputMaybe<Array<PaymentHistoryWhereInput>>
	OR?: InputMaybe<Array<PaymentHistoryWhereInput>>
	amount?: InputMaybe<IntFilter>
	amountCapturable?: InputMaybe<IntFilter>
	amountReceived?: InputMaybe<IntNullableFilter>
	amountRefunded?: InputMaybe<IntNullableFilter>
	amountWithoutSurcharge?: InputMaybe<IntNullableFilter>
	applicationFeeAmount?: InputMaybe<IntFilter>
	applicationId?: InputMaybe<StringNullableFilter>
	canceledAt?: InputMaybe<DateTimeNullableFilter>
	cancellationReason?: InputMaybe<StringNullableFilter>
	captureMethod?: InputMaybe<StringFilter>
	cardBrand?: InputMaybe<StringNullableFilter>
	cardType?: InputMaybe<StringNullableFilter>
	cardholderName?: InputMaybe<StringNullableFilter>
	charges?: InputMaybe<JsonNullableFilter>
	clinicName?: InputMaybe<StringNullableFilter>
	confirmationMethod?: InputMaybe<StringNullableFilter>
	consolidatorId?: InputMaybe<StringNullableFilter>
	consolidatorReferenceCode?: InputMaybe<StringNullableFilter>
	consolidatorReferenceCode2?: InputMaybe<StringNullableFilter>
	createdAt?: InputMaybe<DateTimeFilter>
	createdByEmail?: InputMaybe<StringNullableFilter>
	createdByUser?: InputMaybe<StringNullableFilter>
	currency?: InputMaybe<StringFilter>
	customerId?: InputMaybe<StringNullableFilter>
	customerName?: InputMaybe<StringNullableFilter>
	dateToFilter?: InputMaybe<DateTimeNullableFilter>
	description?: InputMaybe<StringNullableFilter>
	failureCode?: InputMaybe<StringNullableFilter>
	failureReason?: InputMaybe<StringNullableFilter>
	id?: InputMaybe<StringFilter>
	invoiceId?: InputMaybe<StringNullableFilter>
	last4?: InputMaybe<StringNullableFilter>
	metadata?: InputMaybe<JsonNullableFilter>
	notes?: InputMaybe<JsonNullableFilter>
	object?: InputMaybe<StringFilter>
	paymentMethodId?: InputMaybe<StringNullableFilter>
	paymentMethodOptions?: InputMaybe<JsonNullableFilter>
	paymentMethodType?: InputMaybe<StringNullableFilter>
	payoutDescription?: InputMaybe<StringNullableFilter>
	payoutEstimatedArrivalAt?: InputMaybe<DateTimeNullableFilter>
	pimsClientId?: InputMaybe<StringNullableFilter>
	practice?: InputMaybe<PracticeRelationFilter>
	practiceId?: InputMaybe<StringFilter>
	scratchPaymentMethod?: InputMaybe<StringNullableFilter>
	scratchPaymentRequestType?: InputMaybe<StringNullableFilter>
	statementDescriptor?: InputMaybe<StringNullableFilter>
	status?: InputMaybe<StringFilter>
	subscriptionId?: InputMaybe<StringNullableFilter>
	subscriptionScheduleId?: InputMaybe<StringNullableFilter>
	succeededAt?: InputMaybe<DateTimeNullableFilter>
	transactionStatus?: InputMaybe<StringFilter>
	updatedAt?: InputMaybe<DateTimeFilter>
}

/** Payment Intent item */
export type PaymentIntent = {
	__typename?: 'PaymentIntent'
	amount: Scalars['Float']['output']
	amountCapturable: Scalars['Float']['output']
	amountReceived?: Maybe<Scalars['Float']['output']>
	amountRefunded?: Maybe<Scalars['Float']['output']>
	amountWithoutSurcharge?: Maybe<Scalars['Float']['output']>
	id: Scalars['ID']['output']
	metadata?: Maybe<Scalars['JSON']['output']>
}

/** A payment request row */
export type PaymentRequest = {
	__typename?: 'PaymentRequest'
	amount: Scalars['Int']['output']
	consolidatorId?: Maybe<Scalars['String']['output']>
	createdAt: Scalars['DateTimeISO']['output']
	customerId?: Maybe<Scalars['String']['output']>
	customerName?: Maybe<Scalars['String']['output']>
	description?: Maybe<Scalars['String']['output']>
	id: Scalars['String']['output']
	metadata?: Maybe<Scalars['JSON']['output']>
	paidSeparately?: Maybe<Scalars['Boolean']['output']>
	pimsClientId?: Maybe<Scalars['String']['output']>
	practice: PaymentRequestPractice
	practiceId: Scalars['String']['output']
	scratchPaymentMethod?: Maybe<Scalars['String']['output']>
	status: Scalars['String']['output']
	succeededAt?: Maybe<Scalars['DateTimeISO']['output']>
	updatedAt: Scalars['DateTimeISO']['output']
}

/** A payment request file record */
export type PaymentRequestFile = {
	__typename?: 'PaymentRequestFile'
	completedAt?: Maybe<Scalars['DateTimeISO']['output']>
	consolidatorId?: Maybe<Scalars['String']['output']>
	createdAt: Scalars['DateTimeISO']['output']
	createdBy: Scalars['String']['output']
	createdByUser: User
	failedRecordsCount: Scalars['Float']['output']
	failureReason?: Maybe<Scalars['String']['output']>
	fileName: Scalars['String']['output']
	id: Scalars['String']['output']
	recordsCount: Scalars['Float']['output']
	startedAt?: Maybe<Scalars['DateTimeISO']['output']>
	status: Scalars['String']['output']
	succeededRecordsCount: Scalars['Float']['output']
	updatedAt: Scalars['DateTimeISO']['output']
}

/** List of PaymentRequestFile items with total count */
export type PaymentRequestFileList = {
	__typename?: 'PaymentRequestFileList'
	items: Array<PaymentRequestFile>
	total: Scalars['Float']['output']
}

/** A sort order for a field on PaymentRequestFile */
export type PaymentRequestFileOrderByInput = {
	consolidatorId?: InputMaybe<SortOrderInput>
	createdAt?: InputMaybe<SortOrder>
	createdBy?: InputMaybe<SortOrderInput>
	createdByUser?: InputMaybe<UserOrderByInput>
	id?: InputMaybe<SortOrder>
	status?: InputMaybe<SortOrder>
	updatedAt?: InputMaybe<SortOrder>
}

/** A query filter for a list of PaymentRequestFile rows */
export type PaymentRequestFileWhereInput = {
	AND?: InputMaybe<Array<PaymentRequestFileWhereInput>>
	NOT?: InputMaybe<Array<PaymentRequestFileWhereInput>>
	OR?: InputMaybe<Array<PaymentRequestFileWhereInput>>
	consolidatorId?: InputMaybe<StringNullableFilter>
	createdAt?: InputMaybe<DateTimeFilter>
	createdBy?: InputMaybe<StringNullableFilter>
	createdByUser?: InputMaybe<UserWhereInput>
	id?: InputMaybe<StringFilter>
	status?: InputMaybe<StringFilter>
	updatedAt?: InputMaybe<DateTimeFilter>
}

/** List of PaymentRequest items with a amount all at once */
export type PaymentRequestListWithSummary = {
	__typename?: 'PaymentRequestListWithSummary'
	amount?: Maybe<Scalars['BigInt']['output']>
	items: Array<PaymentRequest>
	total: Scalars['Float']['output']
}

/** A sort order for a field on PaymentRequest */
export type PaymentRequestOrderByInput = {
	amount?: InputMaybe<SortOrder>
	consolidatorId?: InputMaybe<SortOrderInput>
	createdAt?: InputMaybe<SortOrder>
	customerId?: InputMaybe<SortOrder>
	customerName?: InputMaybe<SortOrder>
	id?: InputMaybe<SortOrder>
	metadata?: InputMaybe<SortOrderInput>
	pimsClientId?: InputMaybe<SortOrder>
	practice?: InputMaybe<PracticeOrderByInput>
	practiceId?: InputMaybe<SortOrder>
	scratchPaymentMethod?: InputMaybe<SortOrder>
	status?: InputMaybe<SortOrder>
	succeededAt?: InputMaybe<SortOrder>
	updatedAt?: InputMaybe<SortOrder>
}

/** PaymentRequest practice */
export type PaymentRequestPractice = {
	__typename?: 'PaymentRequestPractice'
	consolidatorReferenceCode?: Maybe<Scalars['String']['output']>
	consolidatorReferenceCode2?: Maybe<Scalars['String']['output']>
	id: Scalars['String']['output']
	name: Scalars['String']['output']
	netsuiteId?: Maybe<Scalars['String']['output']>
	skyworldId?: Maybe<Scalars['String']['output']>
}

/** A query filter for a list of PaymentRequest rows */
export type PaymentRequestWhereInput = {
	AND?: InputMaybe<Array<PaymentRequestWhereInput>>
	NOT?: InputMaybe<Array<PaymentRequestWhereInput>>
	OR?: InputMaybe<Array<PaymentRequestWhereInput>>
	amount?: InputMaybe<IntFilter>
	consolidatorId?: InputMaybe<StringNullableFilter>
	createdAt?: InputMaybe<DateTimeFilter>
	customerId?: InputMaybe<StringNullableFilter>
	customerName?: InputMaybe<StringNullableFilter>
	description?: InputMaybe<StringNullableFilter>
	id?: InputMaybe<StringFilter>
	metadata?: InputMaybe<JsonNullableFilter>
	paidSeparately?: InputMaybe<Scalars['Boolean']['input']>
	pimsClientId?: InputMaybe<StringNullableFilter>
	practice?: InputMaybe<PracticeRelationFilter>
	practiceId?: InputMaybe<StringFilter>
	scratchPaymentMethod?: InputMaybe<StringNullableFilter>
	status?: InputMaybe<StringFilter>
	succeededAt?: InputMaybe<DateTimeNullableFilter>
	updatedAt?: InputMaybe<DateTimeFilter>
}

/** Payout item */
export type Payout = {
	__typename?: 'Payout'
	batchCriteria?: Maybe<Scalars['JSON']['output']>
	batchType: CustomPayoutBatchTypes
	completedAt?: Maybe<Scalars['DateTimeISO']['output']>
	consolidator?: Maybe<Scalars['String']['output']>
	createdAt?: Maybe<Scalars['DateTimeISO']['output']>
	currency: Currency
	custom: Scalars['Boolean']['output']
	customPayoutConsolidatorBatchId?: Maybe<Scalars['String']['output']>
	customPayoutCronjobLogId: Scalars['Int']['output']
	description?: Maybe<Scalars['String']['output']>
	disputeAmount?: Maybe<Scalars['Float']['output']>
	disputeCount?: Maybe<Scalars['Int']['output']>
	failureReason?: Maybe<Scalars['String']['output']>
	id: Scalars['ID']['output']
	notes?: Maybe<Scalars['String']['output']>
	paymentAmount?: Maybe<Scalars['Float']['output']>
	paymentApplicationFee?: Maybe<Scalars['Float']['output']>
	paymentCount?: Maybe<Scalars['Int']['output']>
	payoutAmount?: Maybe<Scalars['Float']['output']>
	payoutDate?: Maybe<Scalars['DateTimeISO']['output']>
	payoutEstimatedArrivalAt?: Maybe<Scalars['DateTimeISO']['output']>
	payoutSentAt?: Maybe<Scalars['DateTimeISO']['output']>
	practice: CustomPayoutPractice
	refundAmount?: Maybe<Scalars['Float']['output']>
	refundApplicationFee?: Maybe<Scalars['Float']['output']>
	refundCount?: Maybe<Scalars['Int']['output']>
	retryCount: Scalars['Int']['output']
	startWindowDate?: Maybe<Scalars['DateTimeISO']['output']>
	startedAt?: Maybe<Scalars['DateTimeISO']['output']>
	statementDescriptor?: Maybe<Scalars['String']['output']>
	status: CustomPayoutBatchStatus
	stripePayoutId?: Maybe<Scalars['String']['output']>
	stripeTransferId?: Maybe<Scalars['String']['output']>
	surchargeFee: Scalars['Int']['output']
	target: CustomPayoutBatchTarget
	updatedAt?: Maybe<Scalars['DateTimeISO']['output']>
}

/** List of Payout items with pagination */
export type PayoutList = {
	__typename?: 'PayoutList'
	args: BaseListArgs
	items: Array<Payout>
	skip: Scalars['Float']['output']
	take?: Maybe<Scalars['Float']['output']>
	total: Scalars['Float']['output']
}

export type Practice = {
	__typename?: 'Practice'
	accountNumber?: Maybe<Scalars['String']['output']>
	activeTerminalsCount?: Maybe<Scalars['Int']['output']>
	address?: Maybe<Scalars['String']['output']>
	city?: Maybe<Scalars['String']['output']>
	consolidator?: Maybe<Consolidator>
	consolidatorId?: Maybe<Scalars['String']['output']>
	consolidatorReferenceCode?: Maybe<Scalars['String']['output']>
	consolidatorReferenceCode2?: Maybe<Scalars['String']['output']>
	consolidatorReferenceCode3?: Maybe<Scalars['String']['output']>
	country?: Maybe<Scalars['String']['output']>
	createdAt?: Maybe<Scalars['DateTimeISO']['output']>
	currentPims?: Maybe<Scalars['String']['output']>
	customPayoutSettings?: Maybe<Scalars['JSON']['output']>
	customPayoutTarget?: Maybe<Scalars['String']['output']>
	deleted: Scalars['Boolean']['output']
	email?: Maybe<Scalars['String']['output']>
	financingStatus?: Maybe<Scalars['String']['output']>
	hospitalManagerEmail?: Maybe<Scalars['String']['output']>
	id: Scalars['String']['output']
	integrationId?: Maybe<Scalars['String']['output']>
	isCustomPayoutEnabled?: Maybe<Scalars['Boolean']['output']>
	isDashboardEnabled?: Maybe<Scalars['Boolean']['output']>
	isUnderwritten?: Maybe<Scalars['Boolean']['output']>
	locale?: Maybe<Scalars['String']['output']>
	logoUrl?: Maybe<Scalars['String']['output']>
	name?: Maybe<Scalars['String']['output']>
	netsuiteId?: Maybe<Scalars['String']['output']>
	originationFlags?: Maybe<Scalars['JSON']['output']>
	products?: Maybe<Scalars['JSON']['output']>
	regionalManagerId?: Maybe<Scalars['String']['output']>
	routingNumber?: Maybe<Scalars['String']['output']>
	settings?: Maybe<Scalars['JSON']['output']>
	skyworldId?: Maybe<Scalars['String']['output']>
	socialUrl?: Maybe<Scalars['JSON']['output']>
	state?: Maybe<Scalars['String']['output']>
	stripeAccountId?: Maybe<Scalars['String']['output']>
	surchargeEnabled?: Maybe<Scalars['Boolean']['output']>
	surchargeFeePct?: Maybe<Scalars['Float']['output']>
	terminalLocation?: Maybe<Scalars['String']['output']>
	timezone?: Maybe<Scalars['String']['output']>
	totalTerminalsCount?: Maybe<Scalars['Int']['output']>
	underwrittingStatus?: Maybe<Scalars['String']['output']>
	uniqueId?: Maybe<Scalars['String']['output']>
	updatedAt?: Maybe<Scalars['DateTimeISO']['output']>
	users?: Maybe<Array<PracticeUser>>
	vetClinicId?: Maybe<Scalars['Int']['output']>
	website?: Maybe<Scalars['String']['output']>
	zip?: Maybe<Scalars['String']['output']>
}

export type PracticeCustomPayoutInfo = {
	__typename?: 'PracticeCustomPayoutInfo'
	consolidatorId?: Maybe<Scalars['String']['output']>
	customPayoutSettings?: Maybe<Scalars['JSON']['output']>
	customPayoutTarget?: Maybe<CustomPayoutBatchTarget>
	id: Scalars['ID']['output']
	isCustomPayoutEnabled?: Maybe<Scalars['Boolean']['output']>
	name?: Maybe<Scalars['String']['output']>
	stripeAccountId?: Maybe<Scalars['String']['output']>
}

/** List of Practice items with a count for pagination */
export type PracticeListWithCount = {
	__typename?: 'PracticeListWithCount'
	items: Array<Practice>
	total: Scalars['Float']['output']
}

export type PracticeOrderByInput = {
	activeTerminalsCount?: InputMaybe<SortOrderInput>
	address?: InputMaybe<SortOrderInput>
	consolidatorId?: InputMaybe<SortOrderInput>
	consolidatorReferenceCode?: InputMaybe<SortOrder>
	consolidatorReferenceCode2?: InputMaybe<SortOrder>
	consolidatorReferenceCode3?: InputMaybe<SortOrder>
	createdAt?: InputMaybe<SortOrder>
	createdBy?: InputMaybe<SortOrderInput>
	email?: InputMaybe<SortOrderInput>
	id?: InputMaybe<SortOrder>
	legalBusinessName?: InputMaybe<SortOrderInput>
	name?: InputMaybe<SortOrder>
	stripeAccountId?: InputMaybe<SortOrderInput>
	totalTerminalsCount?: InputMaybe<SortOrderInput>
	updatedAt?: InputMaybe<SortOrder>
	vetClinicId?: InputMaybe<SortOrderInput>
	zip?: InputMaybe<SortOrderInput>
}

export type PracticeRelationFilter = {
	is?: InputMaybe<PracticeWhereInput>
	isNot?: InputMaybe<PracticeWhereInput>
}

export type PracticeUser = {
	__typename?: 'PracticeUser'
	isHospitalManager: Scalars['Boolean']['output']
	isRegionalManager: Scalars['Boolean']['output']
	isStaff?: Maybe<Scalars['Boolean']['output']>
	practice: Practice
	practiceId: Scalars['String']['output']
	user?: Maybe<User>
	userId: Scalars['String']['output']
}

export type PracticeUserWhereInput = {
	AND?: InputMaybe<Array<PracticeUserWhereInput>>
	NOT?: InputMaybe<Array<PracticeUserWhereInput>>
	OR?: InputMaybe<Array<PracticeUserWhereInput>>
	fromFinancing?: InputMaybe<Scalars['Boolean']['input']>
	fromPayments?: InputMaybe<Scalars['Boolean']['input']>
	practice?: InputMaybe<PracticeWhereInput>
	practiceId?: InputMaybe<StringNullableFilter>
	userId?: InputMaybe<StringNullableFilter>
}

export type PracticeWhereInput = {
	AND?: InputMaybe<Array<PracticeWhereInput>>
	NOT?: InputMaybe<Array<PracticeWhereInput>>
	OR?: InputMaybe<Array<PracticeWhereInput>>
	city?: InputMaybe<StringNullableFilter>
	consolidator?: InputMaybe<ConsolidatorRelationFilter>
	consolidatorId?: InputMaybe<StringNullableFilter>
	consolidatorReferenceCode?: InputMaybe<StringNullableFilter>
	consolidatorReferenceCode2?: InputMaybe<StringNullableFilter>
	consolidatorReferenceCode3?: InputMaybe<StringNullableFilter>
	country?: InputMaybe<StringNullableFilter>
	createdAt?: InputMaybe<DateTimeFilter>
	createdBy?: InputMaybe<StringNullableFilter>
	customPayoutTarget?: InputMaybe<StringNullableFilter>
	deleted?: InputMaybe<BoolFilter>
	email?: InputMaybe<StringNullableFilter>
	hospitalManagerEmail?: InputMaybe<StringNullableFilter>
	id?: InputMaybe<StringFilter>
	isCustomPayoutEnabled?: InputMaybe<BoolFilter>
	name?: InputMaybe<StringNullableFilter>
	products?: InputMaybe<JsonNullableFilter>
	settings?: InputMaybe<JsonNullableFilter>
	stripeAccountId?: InputMaybe<StringNullableFilter>
	surchargeEnabled?: InputMaybe<BoolNullableFilter>
	timezone?: InputMaybe<StringNullableFilter>
	updatedAt?: InputMaybe<DateTimeFilter>
	vetClinicId?: InputMaybe<IntNullableFilter>
	zip?: InputMaybe<StringNullableFilter>
}

export type Query = {
	__typename?: 'Query'
	consolidators: Array<Consolidator>
	dailyTotals: DailyTotalsResponse
	listActionNeeded: ActionNeededList
	listCustomPayoutDetails: CustomPayoutDetailList
	listCustomPayoutPracticeBatch: CustomPayoutPracticeBatchList
	listPayouts: PayoutList
	listRefundRequests: RefundRequestList
	listUsers: UserList
	listUsersWithCount: UserListWithCount
	monthlyStatements: Array<MonthlyStatement>
	paymentHistories: Array<PaymentHistory>
	paymentHistoriesWithCount: PaymentHistoryListWithCount
	paymentHistoriesWithSummary: PaymentHistoryListWithSummary
	paymentHistorySummary: PaymentHistorySummary
	paymentRequestFiles: PaymentRequestFileList
	paymentRequests: Array<PaymentRequest>
	paymentRequestsWithSummary: PaymentRequestListWithSummary
	practices: Array<Practice>
	practicesWithCount: PracticeListWithCount
	sumCustomPayoutDetailsByCardBrand: CustomPayoutDetailsSumByCardBrandList
}

export type QueryConsolidatorsArgs = {
	orderBy?: InputMaybe<Array<ConsolidatorOrderByInput>>
	skip?: Scalars['Int']['input']
	take?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<ConsolidatorWhereInput>
}

export type QueryDailyTotalsArgs = {
	where?: InputMaybe<DailyTotalsWhereInput>
}

export type QueryListActionNeededArgs = {
	orderBy?: InputMaybe<Array<ActionNeededOrderByInput>>
	skip?: Scalars['Int']['input']
	take?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<ActionNeededWhereInput>
}

export type QueryListCustomPayoutDetailsArgs = {
	orderBy?: InputMaybe<Array<CustomPayoutDetailsOrderByInput>>
	skip?: Scalars['Int']['input']
	take?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<CustomPayoutDetailsWhereInput>
}

export type QueryListCustomPayoutPracticeBatchArgs = {
	orderBy?: InputMaybe<Array<CustomPayoutPracticeBatchOrderByInput>>
	skip?: Scalars['Int']['input']
	take?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<CustomPayoutPracticeBatchWhereInput>
}

export type QueryListPayoutsArgs = {
	orderBy?: InputMaybe<Array<CustomPayoutPracticeBatchOrderByInput>>
	skip?: Scalars['Int']['input']
	take?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<CustomPayoutPracticeBatchWhereInput>
}

export type QueryListRefundRequestsArgs = {
	all?: InputMaybe<Scalars['Boolean']['input']>
	orderBy?: InputMaybe<Array<RefundRequestOrderByInput>>
	skip?: Scalars['Int']['input']
	take?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<RefundRequestWhereInput>
}

export type QueryListUsersArgs = {
	all?: InputMaybe<Scalars['Boolean']['input']>
	orderBy?: InputMaybe<Array<UserOrderByInput>>
	skip?: Scalars['Int']['input']
	take?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<UserWhereInput>
}

export type QueryListUsersWithCountArgs = {
	all?: InputMaybe<Scalars['Boolean']['input']>
	orderBy?: InputMaybe<Array<UserOrderByInput>>
	skip?: Scalars['Int']['input']
	take?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<UserWhereInput>
}

export type QueryMonthlyStatementsArgs = {
	orderBy?: InputMaybe<Array<MonthlyStatementOrderByInput>>
	skip?: Scalars['Int']['input']
	take?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<MonthlyStatementWhereInput>
}

export type QueryPaymentHistoriesArgs = {
	orderBy?: InputMaybe<Array<PaymentHistoryOrderByInput>>
	skip?: Scalars['Int']['input']
	take?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<PaymentHistoryWhereInput>
}

export type QueryPaymentHistoriesWithCountArgs = {
	orderBy?: InputMaybe<Array<PaymentHistoryOrderByInput>>
	skip?: Scalars['Int']['input']
	take?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<PaymentHistoryWhereInput>
}

export type QueryPaymentHistoriesWithSummaryArgs = {
	orderBy?: InputMaybe<Array<PaymentHistoryOrderByInput>>
	skip?: Scalars['Int']['input']
	take?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<PaymentHistoryWhereInput>
}

export type QueryPaymentHistorySummaryArgs = {
	where: PaymentHistoryWhereInput
}

export type QueryPaymentRequestFilesArgs = {
	orderBy?: InputMaybe<Array<PaymentRequestFileOrderByInput>>
	skip?: Scalars['Int']['input']
	take?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<PaymentRequestFileWhereInput>
}

export type QueryPaymentRequestsArgs = {
	orderBy?: InputMaybe<Array<PaymentRequestOrderByInput>>
	skip?: Scalars['Int']['input']
	take?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<PaymentRequestWhereInput>
}

export type QueryPaymentRequestsWithSummaryArgs = {
	orderBy?: InputMaybe<Array<PaymentRequestOrderByInput>>
	skip?: Scalars['Int']['input']
	take?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<PaymentRequestWhereInput>
}

export type QueryPracticesArgs = {
	orderBy?: InputMaybe<Array<PracticeOrderByInput>>
	skip?: Scalars['Int']['input']
	take?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<PracticeWhereInput>
}

export type QueryPracticesWithCountArgs = {
	orderBy?: InputMaybe<Array<PracticeOrderByInput>>
	skip?: Scalars['Int']['input']
	take?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<PracticeWhereInput>
}

export type QuerySumCustomPayoutDetailsByCardBrandArgs = {
	orderBy?: InputMaybe<Array<CustomPayoutDetailsOrderByInput>>
	skip?: Scalars['Int']['input']
	take?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<CustomPayoutDetailsWhereInput>
}

export enum QueryMode {
	Default = 'default',
	Insensitive = 'insensitive'
}

/** Refund Request item */
export type RefundRequest = {
	__typename?: 'RefundRequest'
	/** User role required to approve the request */
	approverRole: Scalars['String']['output']
	charge?: Maybe<StripeCharge>
	chargeId?: Maybe<Scalars['String']['output']>
	consolidatorId: Scalars['String']['output']
	/** Date when the Refund Request was created */
	createdAt?: Maybe<Scalars['DateTimeISO']['output']>
	id: Scalars['ID']['output']
	/** Note left by the approver */
	notesApprover?: Maybe<Scalars['String']['output']>
	/** Note left by the requestor */
	notesRequestor?: Maybe<Scalars['String']['output']>
	/** Original Payment Amonut */
	originalPaymentAmount: Scalars['Float']['output']
	/** Date when payment was processed */
	originalPaymentProcessedAt: Scalars['DateTimeISO']['output']
	paymentIntent: PaymentIntent
	paymentIntentId: Scalars['String']['output']
	/** Client Id on PIMS */
	pimsClientId?: Maybe<Scalars['String']['output']>
	practice: Practice
	practiceId: Scalars['String']['output']
	requestedBy: User
	/** User email that requested the refund */
	requestedByEmail: Scalars['String']['output']
	/** User id that requested the refund */
	requestedById: Scalars['String']['output']
	/** Amount requested to refund */
	requestedRefundAmount: Scalars['Float']['output']
	/** Date when the request was resolved */
	resolvedAt?: Maybe<Scalars['DateTimeISO']['output']>
	/** User who resolved the request */
	resolvedById?: Maybe<Scalars['String']['output']>
	/** Refund Request Status */
	status: RefundRequestStatuses
	/** Date when the Refund Request was last updated */
	updatedAt?: Maybe<Scalars['DateTimeISO']['output']>
	writebackPimsClientId?: Maybe<Scalars['String']['output']>
}

/** List of Refund Request items with pagination */
export type RefundRequestList = {
	__typename?: 'RefundRequestList'
	all?: Maybe<Scalars['Boolean']['output']>
	args: BaseListArgs
	items: Array<RefundRequest>
	skip: Scalars['Float']['output']
	take?: Maybe<Scalars['Float']['output']>
	total: Scalars['Float']['output']
}

/** A sort order for a field on Request Refund */
export type RefundRequestOrderByInput = {
	createdAt?: InputMaybe<SortOrder>
	id?: InputMaybe<SortOrder>
	originalPaymentAmount?: InputMaybe<SortOrder>
	originalPaymentProcessedAt?: InputMaybe<SortOrder>
	practice?: InputMaybe<PracticeOrderByInput>
	practiceId?: InputMaybe<SortOrder>
	requestedRefundAmount?: InputMaybe<SortOrder>
	updatedAt?: InputMaybe<SortOrder>
}

export enum RefundRequestStatuses {
	Approved = 'APPROVED',
	Denied = 'DENIED',
	Open = 'OPEN'
}

/** A query filter for a list of refundRequest rows */
export type RefundRequestWhereInput = {
	AND?: InputMaybe<Array<RefundRequestWhereInput>>
	OR?: InputMaybe<Array<RefundRequestWhereInput>>
	approverRole?: InputMaybe<StringFilter>
	consolidatorId?: InputMaybe<StringFilter>
	id?: InputMaybe<StringFilter>
	pimsClientId?: InputMaybe<StringFilter>
	practice?: InputMaybe<PracticeWhereInput>
	practiceId?: InputMaybe<StringFilter>
	requestedById?: InputMaybe<StringFilter>
	status?: InputMaybe<StringNullableFilter>
}

export enum SortOrder {
	Asc = 'asc',
	Desc = 'desc'
}

export type SortOrderInput = {
	nulls?: InputMaybe<NullsOrder>
	sort: SortOrder
}

export type StringFilter = {
	contains?: InputMaybe<Scalars['String']['input']>
	endsWith?: InputMaybe<Scalars['String']['input']>
	equals?: InputMaybe<Scalars['String']['input']>
	gt?: InputMaybe<Scalars['String']['input']>
	gte?: InputMaybe<Scalars['String']['input']>
	in?: InputMaybe<Array<Scalars['String']['input']>>
	lt?: InputMaybe<Scalars['String']['input']>
	lte?: InputMaybe<Scalars['String']['input']>
	mode?: InputMaybe<QueryMode>
	not?: InputMaybe<NestedStringFilter>
	notIn?: InputMaybe<Array<Scalars['String']['input']>>
	startsWith?: InputMaybe<Scalars['String']['input']>
}

export type StringNullableFilter = {
	contains?: InputMaybe<Scalars['String']['input']>
	endsWith?: InputMaybe<Scalars['String']['input']>
	equals?: InputMaybe<Scalars['String']['input']>
	gt?: InputMaybe<Scalars['String']['input']>
	gte?: InputMaybe<Scalars['String']['input']>
	in?: InputMaybe<Array<Scalars['String']['input']>>
	lt?: InputMaybe<Scalars['String']['input']>
	lte?: InputMaybe<Scalars['String']['input']>
	mode?: InputMaybe<QueryMode>
	not?: InputMaybe<NestedStringNullableFilter>
	notIn?: InputMaybe<Array<Scalars['String']['input']>>
	startsWith?: InputMaybe<Scalars['String']['input']>
}

/** Stripe Charge item */
export type StripeCharge = {
	__typename?: 'StripeCharge'
	amount: Scalars['Float']['output']
	amountCaptured: Scalars['Float']['output']
	amountRefunded?: Maybe<Scalars['Float']['output']>
	amountWithoutSurcharge?: Maybe<Scalars['Float']['output']>
	id: Scalars['ID']['output']
	metadata?: Maybe<Scalars['JSON']['output']>
}

export type UpsertCustomPayoutSettingsResponse = {
	__typename?: 'UpsertCustomPayoutSettingsResponse'
	customPayoutSettings?: Maybe<Scalars['JSON']['output']>
	success: Scalars['Boolean']['output']
}

/** User item */
export type User = {
	__typename?: 'User'
	consolidatorId?: Maybe<Scalars['String']['output']>
	createdAt?: Maybe<Scalars['DateTimeISO']['output']>
	createdBy?: Maybe<Scalars['String']['output']>
	dashboardUserId?: Maybe<Scalars['Float']['output']>
	disabled?: Maybe<Scalars['Boolean']['output']>
	displayName?: Maybe<Scalars['String']['output']>
	email?: Maybe<Scalars['String']['output']>
	emailMapped?: Maybe<Scalars['Boolean']['output']>
	extraEmailForNotifications?: Maybe<Scalars['String']['output']>
	firstName?: Maybe<Scalars['String']['output']>
	id: Scalars['ID']['output']
	initialRole?: Maybe<Scalars['String']['output']>
	isAdmin?: Maybe<Scalars['Boolean']['output']>
	isRegionalManager?: Maybe<Scalars['Boolean']['output']>
	isScratchAdmin?: Maybe<Scalars['Boolean']['output']>
	isUserNameLogin?: Maybe<Scalars['Boolean']['output']>
	lastLoginDate?: Maybe<Scalars['DateTimeISO']['output']>
	lastName?: Maybe<Scalars['String']['output']>
	name?: Maybe<Scalars['String']['output']>
	passwordLastUpdatedAt?: Maybe<Scalars['DateTimeISO']['output']>
	practices: Array<PracticeUser>
	primaryClinic?: Maybe<Practice>
	primaryClinicId?: Maybe<Scalars['String']['output']>
	primaryStripeAccountId?: Maybe<Scalars['String']['output']>
	uid?: Maybe<Scalars['String']['output']>
	updatedAt?: Maybe<Scalars['DateTimeISO']['output']>
	username?: Maybe<Scalars['String']['output']>
}

/** List of Users items with pagination */
export type UserList = {
	__typename?: 'UserList'
	all?: Maybe<Scalars['Boolean']['output']>
	args: BaseListArgs
	items: Array<User>
	skip: Scalars['Float']['output']
	take?: Maybe<Scalars['Float']['output']>
	total: Scalars['Float']['output']
}

/** List of Users items with pagination */
export type UserListWithCount = {
	__typename?: 'UserListWithCount'
	all?: Maybe<Scalars['Boolean']['output']>
	args: BaseListArgs
	items: Array<User>
	skip: Scalars['Float']['output']
	take?: Maybe<Scalars['Float']['output']>
	total: Scalars['Float']['output']
}

/** A sort order for a field on User */
export type UserOrderByInput = {
	consolidatorId?: InputMaybe<SortOrder>
	createdAt?: InputMaybe<SortOrder>
	disabled?: InputMaybe<SortOrder>
	email?: InputMaybe<SortOrder>
	id?: InputMaybe<SortOrder>
	initialRole?: InputMaybe<SortOrder>
	lastLoginDate?: InputMaybe<SortOrder>
	name?: InputMaybe<SortOrder>
	primaryClinic?: InputMaybe<PracticeOrderByInput>
	updatedAt?: InputMaybe<SortOrder>
	username?: InputMaybe<SortOrder>
}

/** A query filter for a list of User Practices rows */
export type UserPracticesWhereInput = {
	some?: InputMaybe<PracticeUserWhereInput>
}

/** A query filter for a list of User rows */
export type UserWhereInput = {
	AND?: InputMaybe<Array<UserWhereInput>>
	OR?: InputMaybe<Array<UserWhereInput>>
	consolidatorId?: InputMaybe<StringFilter>
	disabled?: InputMaybe<BoolFilter>
	displayName?: InputMaybe<StringFilter>
	email?: InputMaybe<StringFilter>
	id?: InputMaybe<StringFilter>
	initialRole?: InputMaybe<StringFilter>
	lastLoginDate?: InputMaybe<DateTimeFilter>
	name?: InputMaybe<StringFilter>
	practices?: InputMaybe<UserPracticesWhereInput>
	primaryClinic?: InputMaybe<PracticeWhereInput>
	uid?: InputMaybe<StringFilter>
	username?: InputMaybe<StringFilter>
}

export type ListActionNeededQueryVariables = Exact<{
	skip?: InputMaybe<Scalars['Int']['input']>
	take?: InputMaybe<Scalars['Int']['input']>
	orderBy?: InputMaybe<Array<ActionNeededOrderByInput> | ActionNeededOrderByInput>
	where?: InputMaybe<ActionNeededWhereInput>
}>

export type ListActionNeededQuery = {
	__typename?: 'Query'
	listActionNeeded: {
		__typename?: 'ActionNeededList'
		skip: number
		take?: number | null
		total: number
		items: Array<{
			__typename?: 'ActionNeeded'
			id: string
			isComplete: boolean
			amount: number
			clientId?: string | null
			clientName?: string | null
			actionItemType: ActionItemTypes
			paid?: boolean | null
			chargeId?: string | null
			paymentIntentId?: string | null
			invoiceId?: string | null
			metadata?: any | null
			failureCode?: string | null
			failureMessage?: string | null
			scratchPaymentMethod?: string | null
			createdAt?: any | null
			updatedAt?: any | null
			dateAdjustedInPims?: any | null
			resolution?: ActionItemResoltionTypes | null
			resolutionNote?: string | null
			completedBy?: string | null
			completedAt?: any | null
			notes?: string | null
			stripeCreatedDate?: any | null
			practice: {
				__typename?: 'ActionNeededPractice'
				name: string
				id: string
				consolidatorReferenceCode?: string | null
				consolidatorReferenceCode2?: string | null
				netsuiteId?: string | null
				skyworldId?: string | null
			}
		}>
	}
}

export type UpdateConsolidatedPracticeStatusMutationVariables = Exact<{
	id: Scalars['String']['input']
	processType?: InputMaybe<Scalars['String']['input']>
	processStatus?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateConsolidatedPracticeStatusMutation = {
	__typename?: 'Mutation'
	updateConsolidatedPracticeStatus: {
		__typename?: 'ConsolidatedPractice'
		id: string
		status?: any | null
	}
}

export type ListCustomPayoutDetailsQueryVariables = Exact<{
	skip?: InputMaybe<Scalars['Int']['input']>
	take?: InputMaybe<Scalars['Int']['input']>
	orderBy?: InputMaybe<Array<CustomPayoutDetailsOrderByInput> | CustomPayoutDetailsOrderByInput>
	where?: InputMaybe<CustomPayoutDetailsWhereInput>
}>

export type ListCustomPayoutDetailsQuery = {
	__typename?: 'Query'
	listCustomPayoutDetails: {
		__typename?: 'CustomPayoutDetailList'
		skip: number
		take?: number | null
		total: number
		items: Array<{
			__typename?: 'CustomPayoutDetails'
			id: string
			payoutEstimatedArrivalAt?: any | null
			practiceConsolidatorReferenceCode?: string | null
			practiceConsolidatorReferenceCode2?: string | null
			practiceName?: string | null
			transactionAmount?: number | null
			transactionFee?: number | null
			transactionNet?: number | null
			paymentIntentId?: string | null
			transactionDate?: any | null
			transactionType: CustomPayoutTransactionType
			paymentType?: string | null
			cardBrand?: string | null
			cardholderName?: string | null
			payoutDescription?: string | null
			payoutStatementDescriptor?: string | null
			stripePayoutId?: string | null
			practiceId?: string | null
			customPayoutPracticeBatchId?: string | null
			customPayoutConsolidatorBatchId?: string | null
			consolidatorId?: string | null
			pimsClientId?: string | null
			currency: string
			lockboxId?: string | null
			createdAt?: any | null
			surchargeFee?: number | null
		}>
	}
}

export type SumCustomPayoutDetailsByCardBrandQueryVariables = Exact<{
	where?: InputMaybe<CustomPayoutDetailsWhereInput>
}>

export type SumCustomPayoutDetailsByCardBrandQuery = {
	__typename?: 'Query'
	sumCustomPayoutDetailsByCardBrand: {
		__typename?: 'CustomPayoutDetailsSumByCardBrandList'
		items: Array<{
			__typename?: 'CustomPayoutDetails'
			cardBrand?: string | null
			transactionAmount?: number | null
			transactionFee?: number | null
			transactionNet?: number | null
		}>
	}
}

export type ListPayoutsQueryVariables = Exact<{
	skip?: InputMaybe<Scalars['Int']['input']>
	take?: InputMaybe<Scalars['Int']['input']>
	orderBy?: InputMaybe<
		Array<CustomPayoutPracticeBatchOrderByInput> | CustomPayoutPracticeBatchOrderByInput
	>
	where?: InputMaybe<CustomPayoutPracticeBatchWhereInput>
}>

export type ListPayoutsQuery = {
	__typename?: 'Query'
	listPayouts: {
		__typename?: 'PayoutList'
		skip: number
		take?: number | null
		total: number
		items: Array<{
			__typename?: 'Payout'
			id: string
			paymentAmount?: number | null
			paymentApplicationFee?: number | null
			refundAmount?: number | null
			refundApplicationFee?: number | null
			payoutAmount?: number | null
			payoutEstimatedArrivalAt?: any | null
			startWindowDate?: any | null
			stripePayoutId?: string | null
			description?: string | null
			surchargeFee: number
			notes?: string | null
			practice: {
				__typename?: 'CustomPayoutPractice'
				id: string
				name: string
				consolidatorReferenceCode?: string | null
			}
		}>
	}
}

export type ListPayoutsWithDetailsQueryVariables = Exact<{
	skip?: InputMaybe<Scalars['Int']['input']>
	take?: InputMaybe<Scalars['Int']['input']>
	orderBy?: InputMaybe<
		Array<CustomPayoutPracticeBatchOrderByInput> | CustomPayoutPracticeBatchOrderByInput
	>
	where?: InputMaybe<CustomPayoutPracticeBatchWhereInput>
}>

export type ListPayoutsWithDetailsQuery = {
	__typename?: 'Query'
	listPayouts: {
		__typename?: 'PayoutList'
		skip: number
		take?: number | null
		total: number
		items: Array<{
			__typename?: 'Payout'
			id: string
			paymentAmount?: number | null
			paymentApplicationFee?: number | null
			refundAmount?: number | null
			refundApplicationFee?: number | null
			payoutAmount?: number | null
			payoutEstimatedArrivalAt?: any | null
			startWindowDate?: any | null
			stripePayoutId?: string | null
			description?: string | null
			surchargeFee: number
			practice: {
				__typename?: 'CustomPayoutPractice'
				id: string
				name: string
				consolidatorReferenceCode?: string | null
				netsuiteId?: string
				skyworldId?: string
			}
		}>
	}
}

export type PaymentHistoryQueryVariables = Exact<{
	skip?: InputMaybe<Scalars['Int']['input']>
	take?: InputMaybe<Scalars['Int']['input']>
	orderBy?: InputMaybe<Array<PaymentHistoryOrderByInput> | PaymentHistoryOrderByInput>
	where?: InputMaybe<PaymentHistoryWhereInput>
}>

export type PaymentHistoryQuery = {
	__typename?: 'Query'
	paymentHistories: Array<{
		__typename?: 'PaymentHistory'
		amount: number
		amountDue: number
		amountCaptured?: number | null
		amountCapturable?: number | null
		amountReceived?: number | null
		amountRefunded?: number | null
		amountWithoutSurcharge?: number | null
		applicationId?: string | null
		canceledAt?: any | null
		cancellationReason?: string | null
		captureMethod?: string | null
		cardBrand?: string | null
		cardType?: string | null
		cardholderName?: string | null
		charges?: any | null
		chargeId?: string | null
		clinicName?: string | null
		confirmationMethod?: string | null
		consolidatorReferenceCode?: string | null
		consolidatorReferenceCode2?: string | null
		createdAt: any
		createdByEmail?: string | null
		createdByUser?: string | null
		currency: string
		customerId?: string | null
		customerName?: string | null
		dateToFilter?: any | null
		description?: string | null
		failureCode?: string | null
		failureReason?: string | null
		id: string
		invoiceId?: string | null
		lockboxId?: string | null
		last4?: string | null
		metadata?: any | null
		notes?: any | null
		object?: string | null
		originalChargeAmountDue?: number | null
		originalChargeDate?: any | null
		paymentMethodId?: string | null
		paymentMethodOptions?: any | null
		paymentMethodType?: string | null
		pimsClientId?: string | null
		practiceId: string
		scratchPaymentMethod?: string | null
		scratchPaymentRequestType?: string | null
		status: string
		transactionStatus: string
		subscriptionId?: string | null
		subscriptionScheduleId?: string | null
		succeededAt?: any | null
		surcharge?: boolean | null
		surchargeFee?: number | null
		updatedAt: any
		disputed?: boolean | null
		disputeId?: string | null
		disputeStatus?: string | null
	}>
}

export type PaymentHistoryWithCountQueryVariables = Exact<{
	skip?: InputMaybe<Scalars['Int']['input']>
	take?: InputMaybe<Scalars['Int']['input']>
	orderBy?: InputMaybe<Array<PaymentHistoryOrderByInput> | PaymentHistoryOrderByInput>
	where?: InputMaybe<PaymentHistoryWhereInput>
}>

export type PaymentHistoryWithCountQuery = {
	__typename?: 'Query'
	paymentHistoriesWithCount: {
		__typename?: 'PaymentHistoryListWithCount'
		total: number
		items: Array<{
			__typename?: 'PaymentHistory'
			amount: number
			amountDue: number
			amountCaptured?: number | null
			amountCapturable?: number | null
			amountReceived?: number | null
			amountRefunded?: number | null
			amountWithoutSurcharge?: number | null
			applicationId?: string | null
			canceledAt?: any | null
			cancellationReason?: string | null
			captureMethod?: string | null
			cardBrand?: string | null
			cardType?: string | null
			cardholderName?: string | null
			charges?: any | null
			chargeId?: string | null
			clinicName?: string | null
			confirmationMethod?: string | null
			consolidatorReferenceCode?: string | null
			consolidatorReferenceCode2?: string | null
			createdAt: any
			createdByEmail?: string | null
			createdByUser?: string | null
			currency: string
			customerId?: string | null
			customerName?: string | null
			dateToFilter?: any | null
			description?: string | null
			failureCode?: string | null
			failureReason?: string | null
			id: string
			invoiceId?: string | null
			lockboxId?: string | null
			last4?: string | null
			metadata?: any | null
			notes?: any | null
			object?: string | null
			originalChargeAmountDue?: number | null
			originalChargeDate?: any | null
			paymentMethodId?: string | null
			paymentMethodOptions?: any | null
			paymentMethodType?: string | null
			pimsClientId?: string | null
			practiceId: string
			scratchPaymentMethod?: string | null
			scratchPaymentRequestType?: string | null
			status: string
			transactionStatus: string
			subscriptionId?: string | null
			subscriptionScheduleId?: string | null
			succeededAt?: any | null
			surcharge?: boolean | null
			surchargeFee?: number | null
			updatedAt: any
			disputed?: boolean | null
			disputeId?: string | null
			disputeStatus?: string | null
		}>
	}
}

export type PaymentHistoryListWithSummaryQueryVariables = Exact<{
	skip?: InputMaybe<Scalars['Int']['input']>
	take?: InputMaybe<Scalars['Int']['input']>
	orderBy?: InputMaybe<Array<PaymentHistoryOrderByInput> | PaymentHistoryOrderByInput>
	where?: InputMaybe<PaymentHistoryWhereInput>
}>

export type PaymentHistoryListWithSummaryQuery = {
	__typename?: 'Query'
	paymentHistoriesWithSummary: {
		__typename?: 'PaymentHistoryListWithSummary'
		items: Array<{
			__typename?: 'PaymentHistory'
			amount: number
			amountDue: number
			amountCaptured?: number | null
			amountCapturable?: number | null
			amountReceived?: number | null
			amountRefunded?: number | null
			amountWithoutSurcharge?: number | null
			applicationId?: string | null
			canceledAt?: any | null
			cancellationReason?: string | null
			captureMethod?: string | null
			cardBrand?: string | null
			cardType?: string | null
			cardholderName?: string | null
			charges?: any | null
			chargeId?: string | null
			clinicName?: string | null
			confirmationMethod?: string | null
			consolidatorReferenceCode?: string | null
			consolidatorReferenceCode2?: string | null
			createdAt: any
			createdByEmail?: string | null
			createdByUser?: string | null
			currency: string
			customerId?: string | null
			customerName?: string | null
			dateToFilter?: any | null
			description?: string | null
			failureCode?: string | null
			failureReason?: string | null
			id: string
			invoiceId?: string | null
			lockboxId?: string | null
			last4?: string | null
			metadata?: any | null
			notes?: any | null
			object?: string | null
			originalChargeAmountDue?: number | null
			originalChargeDate?: any | null
			paymentMethodId?: string | null
			paymentMethodOptions?: any | null
			paymentMethodType?: string | null
			pimsClientId?: string | null
			practiceId: string
			scratchPaymentMethod?: string | null
			scratchPaymentRequestType?: string | null
			status: string
			transactionStatus: string
			subscriptionId?: string | null
			subscriptionScheduleId?: string | null
			succeededAt?: any | null
			surcharge?: boolean | null
			surchargeFee?: number | null
			updatedAt: any
			disputed?: boolean | null
			disputeId?: string | null
			disputeStatus?: string | null
		}>
		summary: {
			__typename?: 'PaymentHistorySummary'
			count: number
			totalAmount?: any | null
			totalRefunded?: any | null
			totalFailed?: any | null
			totalPaid?: any | null
			totalPending?: any | null
			totalUncaptured?: any | null
			totalFailedECheck?: any | null
			totalReturnedECheck?: any | null
		}
	}
}

export type PaymentHistoryWithoutChargesQueryVariables = Exact<{
	skip?: InputMaybe<Scalars['Int']['input']>
	take?: InputMaybe<Scalars['Int']['input']>
	orderBy?: InputMaybe<Array<PaymentHistoryOrderByInput> | PaymentHistoryOrderByInput>
	where?: InputMaybe<PaymentHistoryWhereInput>
}>

export type PaymentHistoryWithoutChargesQuery = {
	__typename?: 'Query'
	paymentHistories: Array<{
		__typename?: 'PaymentHistory'
		amount: number
		amountDue: number
		amountCaptured?: number | null
		amountCapturable?: number | null
		amountReceived?: number | null
		amountRefunded?: number | null
		amountWithoutSurcharge?: number | null
		applicationId?: string | null
		canceledAt?: any | null
		cancellationReason?: string | null
		captureMethod?: string | null
		cardBrand?: string | null
		cardType?: string | null
		cardholderName?: string | null
		clinicName?: string | null
		confirmationMethod?: string | null
		consolidatorReferenceCode?: string | null
		consolidatorReferenceCode2?: string | null
		createdAt: any
		createdByEmail?: string | null
		createdByUser?: string | null
		currency: string
		customerId?: string | null
		customerName?: string | null
		dateToFilter?: any | null
		description?: string | null
		failureCode?: string | null
		failureReason?: string | null
		id: string
		invoiceId?: string | null
		lockboxId?: string | null
		last4?: string | null
		metadata?: any | null
		notes?: any | null
		object?: string | null
		originalChargeAmountDue?: number | null
		originalChargeDate?: any | null
		paymentMethodId?: string | null
		paymentMethodOptions?: any | null
		paymentMethodType?: string | null
		pimsClientId?: string | null
		practiceId: string
		scratchPaymentMethod?: string | null
		scratchPaymentRequestType?: string | null
		status: string
		transactionStatus: string
		subscriptionId?: string | null
		subscriptionScheduleId?: string | null
		succeededAt?: any | null
		surcharge?: boolean | null
		surchargeFee?: number | null
		updatedAt: any
		disputed?: boolean | null
		disputeId?: string | null
		disputeStatus?: string | null
	}>
}

export type PaymentHistorySummaryQueryVariables = Exact<{
	where: PaymentHistoryWhereInput
}>

export type PaymentHistorySummaryQuery = {
	__typename?: 'Query'
	paymentHistorySummary: {
		__typename?: 'PaymentHistorySummary'
		count: number
		totalAmount?: any | null
		totalRefunded?: any | null
		totalFailed?: any | null
		totalPaid?: any | null
		totalPending?: any | null
		totalUncaptured?: any | null
		totalFailedECheck?: any | null
		totalReturnedECheck?: any | null
	}
}

export type PaymentRequestQueryVariables = Exact<{
	skip?: InputMaybe<Scalars['Int']['input']>
	take?: InputMaybe<Scalars['Int']['input']>
	orderBy?: InputMaybe<Array<PaymentRequestOrderByInput> | PaymentRequestOrderByInput>
	where?: InputMaybe<PaymentRequestWhereInput>
}>

export type PaymentRequestQuery = {
	__typename?: 'Query'
	paymentRequests: Array<{
		__typename?: 'PaymentRequest'
		amount: number
		consolidatorId?: string | null
		createdAt: any
		customerId?: string | null
		customerName?: string | null
		description?: string | null
		id: string
		metadata?: any | null
		pimsClientId?: string | null
		practiceId: string
		scratchPaymentMethod?: string | null
		status: string
		succeededAt?: any | null
		updatedAt: any
		paidSeparately?: boolean | null
		practice: {
			__typename?: 'PaymentRequestPractice'
			id: string
			name: string
			consolidatorReferenceCode?: string | null
			netsuiteId?: string | null
			skyworldId?: string | null
		}
	}>
}

export type PaymentRequestWithSummaryQueryVariables = Exact<{
	skip?: InputMaybe<Scalars['Int']['input']>
	take?: InputMaybe<Scalars['Int']['input']>
	orderBy?: InputMaybe<Array<PaymentRequestOrderByInput> | PaymentRequestOrderByInput>
	where?: InputMaybe<PaymentRequestWhereInput>
}>

export type PaymentRequestWithSummaryQuery = {
	__typename?: 'Query'
	paymentRequestsWithSummary: {
		__typename?: 'PaymentRequestListWithSummary'
		amount?: any | null
		total: number
		items: Array<{
			__typename?: 'PaymentRequest'
			amount: number
			consolidatorId?: string | null
			createdAt: any
			customerId?: string | null
			customerName?: string | null
			description?: string | null
			id: string
			metadata?: any | null
			pimsClientId?: string | null
			practiceId: string
			scratchPaymentMethod?: string | null
			status: string
			succeededAt?: any | null
			updatedAt: any
			paidSeparately?: boolean | null
			practice: {
				__typename?: 'PaymentRequestPractice'
				id: string
				name: string
				consolidatorReferenceCode?: string | null
				netsuiteId?: string | null
				skyworldId?: string | null
			}
		}>
	}
}

export type UpdatePaymentRequestMutationVariables = Exact<{
	id?: InputMaybe<Scalars['String']['input']>
	arDeliveryMethod?: InputMaybe<Scalars['String']['input']>
	customerEmail?: InputMaybe<Scalars['String']['input']>
	customerPhone?: InputMaybe<Scalars['String']['input']>
}>

export type UpdatePaymentRequestMutation = {
	__typename?: 'Mutation'
	updatePaymentRequest: { __typename?: 'PaymentRequest'; id: string; metadata?: any | null }
}

export type ProcessUploadFileMutationVariables = Exact<{
	fileName: Scalars['String']['input']
}>

export type ProcessUploadFileMutation = {
	__typename?: 'Mutation'
	processUploadedFile: {
		__typename?: 'PaymentRequestFile'
		id: string
		fileName: string
		createdBy: string
		consolidatorId?: string | null
	}
}

export type PaymentRequestFileListQueryVariables = Exact<{
	skip?: InputMaybe<Scalars['Int']['input']>
	take?: InputMaybe<Scalars['Int']['input']>
	orderBy?: InputMaybe<Array<PaymentRequestFileOrderByInput> | PaymentRequestFileOrderByInput>
	where?: InputMaybe<PaymentRequestFileWhereInput>
}>

export type PaymentRequestFileListQuery = {
	__typename?: 'Query'
	paymentRequestFiles: {
		__typename?: 'PaymentRequestFileList'
		total: number
		items: Array<{
			__typename?: 'PaymentRequestFile'
			id: string
			createdAt: any
			createdBy: string
			recordsCount: number
			succeededRecordsCount: number
			failureReason?: string | null
			failedRecordsCount: number
			fileName: string
			createdByUser: { __typename?: 'User'; name?: string | null; displayName?: string | null }
		}>
	}
}

export type GetPracticesQueryVariables = Exact<{
	skip?: InputMaybe<Scalars['Int']['input']>
	take?: InputMaybe<Scalars['Int']['input']>
	orderBy?: InputMaybe<Array<PracticeOrderByInput> | PracticeOrderByInput>
	where?: InputMaybe<PracticeWhereInput>
}>

export type GetPracticesQuery = {
	__typename?: 'Query'
	practices: Array<{
		__typename?: 'Practice'
		id: string
		stripeAccountId?: string | null
		name?: string | null
		city?: string | null
		state?: string | null
		address?: string | null
		zip?: string | null
	}>
}

export type GetAllPracticesCountQueryVariables = Exact<{
	skip?: InputMaybe<Scalars['Int']['input']>
	take?: InputMaybe<Scalars['Int']['input']>
	orderBy?: InputMaybe<Array<PracticeOrderByInput> | PracticeOrderByInput>
	where?: InputMaybe<PracticeWhereInput>
}>

export type GetAllPracticesCountQuery = {
	__typename?: 'Query'
	practicesWithCount: {
		__typename?: 'PracticeListWithCount'
		total: number
		items: Array<{
			__typename?: 'Practice'
			id: string
			stripeAccountId?: string | null
			consolidatorId?: string | null
			name?: string | null
			city?: string | null
			state?: string | null
			address?: string | null
			zip?: string | null
			vetClinicId?: number | null
			settings?: any | null
			integrationId?: string | null
			hospitalManagerEmail?: string | null
			products?: any | null
			routingNumber?: string | null
			accountNumber?: string | null
			logoUrl?: string | null
			activeTerminalsCount?: number | null
			totalTerminalsCount?: number | null
			currentPims?: string | null
			timezone?: string | null
			isCustomPayoutEnabled?: boolean | null
			netsuiteId?: string | null
			skyworldId?: string | null
			consolidator?: { __typename?: 'Consolidator'; id: string; featureFlags?: any | null } | null
		}>
	}
}

export type GetAllPracticesQueryVariables = Exact<{
	skip?: InputMaybe<Scalars['Int']['input']>
	take?: InputMaybe<Scalars['Int']['input']>
	orderBy?: InputMaybe<Array<PracticeOrderByInput> | PracticeOrderByInput>
	where?: InputMaybe<PracticeWhereInput>
}>

export type GetAllPracticesQuery = {
	__typename?: 'Query'
	practices: Array<{
		__typename?: 'Practice'
		id: string
		stripeAccountId?: string | null
		consolidatorId?: string | null
		name?: string | null
		city?: string | null
		state?: string | null
		address?: string | null
		zip?: string | null
		vetClinicId?: number | null
		settings?: any | null
		integrationId?: string | null
		hospitalManagerEmail?: string | null
		products?: any | null
		routingNumber?: string | null
		accountNumber?: string | null
		logoUrl?: string | null
		activeTerminalsCount?: number | null
		totalTerminalsCount?: number | null
		currentPims?: string | null
		timezone?: string | null
		isCustomPayoutEnabled?: boolean | null
		customPayoutSettings?: any | null
		netsuiteId?: string | null
		skyworldId?: string | null
		consolidator?: { __typename?: 'Consolidator'; id: string; featureFlags?: any | null } | null
	}>
}

export type GetPracticeContextQueryVariables = Exact<{
	skip?: InputMaybe<Scalars['Int']['input']>
	take?: InputMaybe<Scalars['Int']['input']>
	orderBy?: InputMaybe<Array<PracticeOrderByInput> | PracticeOrderByInput>
	where?: InputMaybe<PracticeWhereInput>
}>

export type GetPracticeContextQuery = {
	__typename?: 'Query'
	practices: Array<{
		__typename?: 'Practice'
		id: string
		stripeAccountId?: string | null
		name?: string | null
		city?: string | null
		address?: string | null
		state?: string | null
		vetClinicId?: number | null
		terminalLocation?: string | null
		consolidatorId?: string | null
		timezone?: string | null
		locale?: string | null
		consolidatorReferenceCode?: string | null
		consolidatorReferenceCode2?: string | null
		surchargeEnabled?: boolean | null
		surchargeFeePct?: number | null
		settings?: any | null
		financingStatus?: string | null
		isDashboardEnabled?: boolean | null
		isUnderwritten?: boolean | null
		originationFlags?: any | null
		underwrittingStatus?: string | null
		regionalManagerId?: string | null
		isCustomPayoutEnabled?: boolean | null
		consolidator?: {
			__typename?: 'Consolidator'
			id: string
			featureFlags?: any | null
			financingFlags?: any | null
			customization?: any | null
			customFields?: any | null
			timezone?: string | null
		} | null
	}>
}

export type UpsertCustomPayoutSettingsMutationVariables = Exact<{
	practiceId: Scalars['String']['input']
	settings: CustomPayoutSettingsInput
}>

export type UpsertCustomPayoutSettingsMutation = {
	__typename?: 'Mutation'
	upsertCustomPayoutSettings: {
		__typename?: 'UpsertCustomPayoutSettingsResponse'
		success: boolean
		customPayoutSettings?: any | null
	}
}

export type EnableCustomPayoutV2MutationVariables = Exact<{
	practiceId: Scalars['ID']['input']
}>

export type EnableCustomPayoutV2Mutation = {
	__typename?: 'Mutation'
	enableCustomPayoutV2: {
		__typename?: 'EnableCustomPayoutV2Response'
		practicePayoutInfo: {
			__typename?: 'PracticeCustomPayoutInfo'
			id: string
			stripeAccountId?: string | null
			name?: string | null
			consolidatorId?: string | null
			customPayoutTarget?: CustomPayoutBatchTarget | null
			customPayoutSettings?: any | null
			isCustomPayoutEnabled?: boolean | null
		}
	}
}

export type ListRefundRequestsQueryVariables = Exact<{
	skip?: InputMaybe<Scalars['Int']['input']>
	take?: InputMaybe<Scalars['Int']['input']>
	orderBy?: InputMaybe<Array<RefundRequestOrderByInput> | RefundRequestOrderByInput>
	where?: InputMaybe<RefundRequestWhereInput>
	all?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ListRefundRequestsQuery = {
	__typename?: 'Query'
	listRefundRequests: {
		__typename?: 'RefundRequestList'
		total: number
		skip: number
		take?: number | null
		items: Array<{
			__typename?: 'RefundRequest'
			id: string
			consolidatorId: string
			paymentIntentId: string
			originalPaymentAmount: number
			originalPaymentProcessedAt: any
			requestedRefundAmount: number
			requestedById: string
			requestedByEmail: string
			approverRole: string
			notesRequestor?: string | null
			status: RefundRequestStatuses
			pimsClientId?: string | null
			writebackPimsClientId?: string | null
			createdAt?: any | null
			updatedAt?: any | null
			chargeId?: string | null
			notesApprover?: string | null
			resolvedAt?: any | null
			resolvedById?: string | null
			paymentIntent: { __typename?: 'PaymentIntent'; metadata?: any | null }
			requestedBy: { __typename?: 'User'; name?: string | null; displayName?: string | null }
			practice: {
				__typename?: 'Practice'
				id: string
				name?: string | null
				currentPims?: string | null
			}
		}>
	}
}

export type CreateRefundRequestMutationVariables = Exact<{
	paymentIntentId: Scalars['String']['input']
	pimsClientId?: InputMaybe<Scalars['String']['input']>
	notes?: InputMaybe<Scalars['String']['input']>
	requestedRefundAmount: Scalars['Float']['input']
	chargeId?: InputMaybe<Scalars['String']['input']>
	writebackPimsClientId?: InputMaybe<Scalars['String']['input']>
}>

export type CreateRefundRequestMutation = {
	__typename?: 'Mutation'
	upsertRefundRequest: {
		__typename?: 'RefundRequest'
		id: string
		status: RefundRequestStatuses
		requestedRefundAmount: number
	}
}

export type UpdateRefundRequestMutationVariables = Exact<{
	id: Scalars['ID']['input']
	status: Scalars['String']['input']
	notes?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateRefundRequestMutation = {
	__typename?: 'Mutation'
	upsertRefundRequest: {
		__typename?: 'RefundRequest'
		id: string
		consolidatorId: string
		paymentIntentId: string
		originalPaymentAmount: number
		originalPaymentProcessedAt: any
		requestedRefundAmount: number
		requestedById: string
		requestedByEmail: string
		approverRole: string
		notesRequestor?: string | null
		notesApprover?: string | null
		status: RefundRequestStatuses
		pimsClientId?: string | null
		writebackPimsClientId?: string | null
		createdAt?: any | null
		updatedAt?: any | null
		chargeId?: string | null
		resolvedAt?: any | null
		resolvedById?: string | null
	}
}

export type ListMonthlyStatementsQueryVariables = Exact<{
	skip?: InputMaybe<Scalars['Int']['input']>
	take?: InputMaybe<Scalars['Int']['input']>
	orderBy?: InputMaybe<Array<MonthlyStatementOrderByInput> | MonthlyStatementOrderByInput>
	where?: InputMaybe<MonthlyStatementWhereInput>
}>

export type ListMonthlyStatementsQuery = {
	__typename?: 'Query'
	monthlyStatements: Array<{
		__typename?: 'MonthlyStatement'
		id: string
		month: any
		feeTier: string
		currency: string
		numAuthorized: number
		numSettled: number
		amountSettled: any
		surchargeVolume?: any | null
		totalFees: any
		otherFees?: any | null
		practiceId: string
		dayLines?: Array<{
			__typename?: 'MonthlyStatementDayLine'
			day: any
			numAuthorized: number
			numSettled: number
			amountSettled: any
			totalFees: any
			surchargeVolume?: any | null
		}> | null
		feeLines?: Array<{
			__typename?: 'MonthlyStatementFeeLine'
			feeType: string
			rate?: any | null
			amount?: any | null
			totalFees?: any | null
		}> | null
		paymentLines?: Array<{
			__typename?: 'MonthlyStatementPaymentLine'
			paymentMethodType: string
			numAuthorized: number
			numSettled: number
			amountSettled: any
			totalFees: any
			surchargeVolume?: any | null
		}> | null
	}>
}

export type ListUsersQueryVariables = Exact<{
	skip?: InputMaybe<Scalars['Int']['input']>
	take?: InputMaybe<Scalars['Int']['input']>
	orderBy?: InputMaybe<Array<UserOrderByInput> | UserOrderByInput>
	where?: InputMaybe<UserWhereInput>
	all?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ListUsersQuery = {
	__typename?: 'Query'
	listUsers: {
		__typename?: 'UserList'
		skip: number
		take?: number | null
		items: Array<{
			__typename?: 'User'
			consolidatorId?: string | null
			createdAt?: any | null
			createdBy?: string | null
			dashboardUserId?: number | null
			disabled?: boolean | null
			displayName?: string | null
			email?: string | null
			emailMapped?: boolean | null
			extraEmailForNotifications?: string | null
			firstName?: string | null
			id: string
			initialRole?: string | null
			isAdmin?: boolean | null
			isRegionalManager?: boolean | null
			isScratchAdmin?: boolean | null
			isUserNameLogin?: boolean | null
			lastLoginDate?: any | null
			lastName?: string | null
			name?: string | null
			passwordLastUpdatedAt?: any | null
			primaryClinicId?: string | null
			primaryStripeAccountId?: string | null
			uid?: string | null
			updatedAt?: any | null
			username?: string | null
			practices: Array<{
				__typename?: 'PracticeUser'
				isRegionalManager: boolean
				isHospitalManager: boolean
				isStaff?: boolean | null
				practice: { __typename?: 'Practice'; id: string; stripeAccountId?: string | null }
			}>
			primaryClinic?: {
				__typename?: 'Practice'
				id: string
				name?: string | null
				consolidatorReferenceCode2?: string | null
				netsuiteId?: string | null
				skyworldId?: string | null
			} | null
		}>
	}
}

export type ListUsersWithCountQueryVariables = Exact<{
	skip?: InputMaybe<Scalars['Int']['input']>
	take?: InputMaybe<Scalars['Int']['input']>
	orderBy?: InputMaybe<Array<UserOrderByInput> | UserOrderByInput>
	where?: InputMaybe<UserWhereInput>
	all?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ListUsersWithCountQuery = {
	__typename?: 'Query'
	listUsersWithCount: {
		__typename?: 'UserListWithCount'
		total: number
		skip: number
		take?: number | null
		items: Array<{
			__typename?: 'User'
			consolidatorId?: string | null
			createdAt?: any | null
			createdBy?: string | null
			dashboardUserId?: number | null
			disabled?: boolean | null
			displayName?: string | null
			email?: string | null
			emailMapped?: boolean | null
			extraEmailForNotifications?: string | null
			firstName?: string | null
			id: string
			initialRole?: string | null
			isAdmin?: boolean | null
			isRegionalManager?: boolean | null
			isScratchAdmin?: boolean | null
			isUserNameLogin?: boolean | null
			lastLoginDate?: any | null
			lastName?: string | null
			name?: string | null
			passwordLastUpdatedAt?: any | null
			primaryClinicId?: string | null
			primaryStripeAccountId?: string | null
			uid?: string | null
			updatedAt?: any | null
			username?: string | null
			practices: Array<{
				__typename?: 'PracticeUser'
				isRegionalManager: boolean
				isHospitalManager: boolean
				isStaff?: boolean | null
				practice: { __typename?: 'Practice'; id: string; stripeAccountId?: string | null }
			}>
			primaryClinic?: {
				__typename?: 'Practice'
				id: string
				name?: string | null
				consolidatorReferenceCode2?: string | null
				netsuiteId?: string | null
				skyworldId?: string | null
			} | null
		}>
	}
}

export type ListUserQueryVariables = Exact<{
	skip?: InputMaybe<Scalars['Int']['input']>
	take?: InputMaybe<Scalars['Int']['input']>
	orderBy?: InputMaybe<Array<UserOrderByInput> | UserOrderByInput>
	where?: InputMaybe<UserWhereInput>
	all?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ListUserQuery = {
	__typename?: 'Query'
	listUsers: {
		__typename?: 'UserList'
		items: Array<{
			__typename?: 'User'
			consolidatorId?: string | null
			createdAt?: any | null
			createdBy?: string | null
			dashboardUserId?: number | null
			disabled?: boolean | null
			displayName?: string | null
			email?: string | null
			emailMapped?: boolean | null
			extraEmailForNotifications?: string | null
			firstName?: string | null
			id: string
			initialRole?: string | null
			isAdmin?: boolean | null
			isRegionalManager?: boolean | null
			isScratchAdmin?: boolean | null
			isUserNameLogin?: boolean | null
			lastLoginDate?: any | null
			lastName?: string | null
			name?: string | null
			passwordLastUpdatedAt?: any | null
			uid?: string | null
			updatedAt?: any | null
			username?: string | null
			practices: Array<{
				__typename?: 'PracticeUser'
				isHospitalManager: boolean
				isRegionalManager: boolean
				isStaff?: boolean | null
				practice: {
					__typename?: 'Practice'
					id: string
					stripeAccountId?: string | null
					consolidatorReferenceCode?: string | null
					consolidatorReferenceCode2?: string | null
					name?: string | null
				}
			}>
			primaryClinic?: { __typename?: 'Practice'; id: string; name?: string | null } | null
		}>
	}
}

export const ListActionNeededDocument = gql`
	query ListActionNeeded(
		$skip: Int
		$take: Int
		$orderBy: [ActionNeededOrderByInput!]
		$where: ActionNeededWhereInput
	) {
		listActionNeeded(skip: $skip, take: $take, orderBy: $orderBy, where: $where) {
			items {
				id
				isComplete
				amount
				clientId
				clientName
				actionItemType
				paid
				chargeId
				paymentIntentId
				invoiceId
				metadata
				failureCode
				failureMessage
				scratchPaymentMethod
				createdAt
				updatedAt
				dateAdjustedInPims
				resolution
				resolutionNote
				completedBy
				completedAt
				notes
				stripeCreatedDate
				practice {
					name
					id
					consolidatorReferenceCode
					consolidatorReferenceCode2
					netsuiteId
					skyworldId
				}
			}
			skip
			take
			total
		}
	}
`

export function useListActionNeededQuery(
	options?: Omit<Urql.UseQueryArgs<ListActionNeededQueryVariables>, 'query'>
) {
	return Urql.useQuery<ListActionNeededQuery, ListActionNeededQueryVariables>({
		query: ListActionNeededDocument,
		...options
	})
}
export const UpdateConsolidatedPracticeStatusDocument = gql`
	mutation updateConsolidatedPracticeStatus(
		$id: String!
		$processType: String
		$processStatus: String
	) {
		updateConsolidatedPracticeStatus(
			id: $id
			processType: $processType
			processStatus: $processStatus
		) {
			id
			status
		}
	}
`

export function useUpdateConsolidatedPracticeStatusMutation() {
	return Urql.useMutation<
		UpdateConsolidatedPracticeStatusMutation,
		UpdateConsolidatedPracticeStatusMutationVariables
	>(UpdateConsolidatedPracticeStatusDocument)
}
export const ListCustomPayoutDetailsDocument = gql`
	query listCustomPayoutDetails(
		$skip: Int
		$take: Int
		$orderBy: [CustomPayoutDetailsOrderByInput!]
		$where: CustomPayoutDetailsWhereInput
	) {
		listCustomPayoutDetails(skip: $skip, take: $take, orderBy: $orderBy, where: $where) {
			items {
				id
				payoutEstimatedArrivalAt
				practiceConsolidatorReferenceCode
				practiceConsolidatorReferenceCode2
				practiceName
				transactionAmount
				transactionFee
				transactionNet
				paymentIntentId
				transactionDate
				transactionType
				paymentType
				cardBrand
				cardholderName
				payoutDescription
				payoutStatementDescriptor
				stripePayoutId
				practiceId
				customPayoutPracticeBatchId
				customPayoutConsolidatorBatchId
				consolidatorId
				pimsClientId
				currency
				lockboxId
				createdAt
				surchargeFee
			}
			skip
			take
			total
		}
	}
`

export function useListCustomPayoutDetailsQuery(
	options?: Omit<Urql.UseQueryArgs<ListCustomPayoutDetailsQueryVariables>, 'query'>
) {
	return Urql.useQuery<ListCustomPayoutDetailsQuery, ListCustomPayoutDetailsQueryVariables>({
		query: ListCustomPayoutDetailsDocument,
		...options
	})
}
export const SumCustomPayoutDetailsByCardBrandDocument = gql`
	query sumCustomPayoutDetailsByCardBrand($where: CustomPayoutDetailsWhereInput) {
		sumCustomPayoutDetailsByCardBrand(where: $where) {
			items {
				cardBrand
				transactionAmount
				transactionFee
				transactionNet
			}
		}
	}
`

export function useSumCustomPayoutDetailsByCardBrandQuery(
	options?: Omit<Urql.UseQueryArgs<SumCustomPayoutDetailsByCardBrandQueryVariables>, 'query'>
) {
	return Urql.useQuery<
		SumCustomPayoutDetailsByCardBrandQuery,
		SumCustomPayoutDetailsByCardBrandQueryVariables
	>({ query: SumCustomPayoutDetailsByCardBrandDocument, ...options })
}
export const ListPayoutsDocument = gql`
	query ListPayouts(
		$skip: Int
		$take: Int
		$orderBy: [CustomPayoutPracticeBatchOrderByInput!]
		$where: CustomPayoutPracticeBatchWhereInput
	) {
		listPayouts(skip: $skip, take: $take, orderBy: $orderBy, where: $where) {
			items {
				id
				paymentAmount
				paymentApplicationFee
				refundAmount
				refundApplicationFee
				payoutAmount
				payoutEstimatedArrivalAt
				startWindowDate
				stripePayoutId
				description
				surchargeFee
				notes
				practice {
					id
					name
					consolidatorReferenceCode
				}
			}
			skip
			take
			total
		}
	}
`

export function useListPayoutsQuery(
	options?: Omit<Urql.UseQueryArgs<ListPayoutsQueryVariables>, 'query'>
) {
	return Urql.useQuery<ListPayoutsQuery, ListPayoutsQueryVariables>({
		query: ListPayoutsDocument,
		...options
	})
}
export const ListPayoutsWithDetailsDocument = gql`
	query ListPayoutsWithDetails(
		$skip: Int
		$take: Int
		$orderBy: [CustomPayoutPracticeBatchOrderByInput!]
		$where: CustomPayoutPracticeBatchWhereInput
	) {
		listPayouts(skip: $skip, take: $take, orderBy: $orderBy, where: $where) {
			items {
				id
				paymentAmount
				paymentApplicationFee
				refundAmount
				refundApplicationFee
				payoutAmount
				payoutEstimatedArrivalAt
				startWindowDate
				stripePayoutId
				description
				surchargeFee
				practice {
					id
					name
					consolidatorReferenceCode
				}
			}
			skip
			take
			total
		}
	}
`

export function useListPayoutsWithDetailsQuery(
	options?: Omit<Urql.UseQueryArgs<ListPayoutsWithDetailsQueryVariables>, 'query'>
) {
	return Urql.useQuery<ListPayoutsWithDetailsQuery, ListPayoutsWithDetailsQueryVariables>({
		query: ListPayoutsWithDetailsDocument,
		...options
	})
}
export const PaymentHistoryDocument = gql`
	query PaymentHistory(
		$skip: Int
		$take: Int
		$orderBy: [PaymentHistoryOrderByInput!]
		$where: PaymentHistoryWhereInput
	) {
		paymentHistories(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
			amount
			amountDue
			amountCaptured
			amountCapturable
			amountReceived
			amountRefunded
			amountWithoutSurcharge
			applicationId
			canceledAt
			cancellationReason
			captureMethod
			cardBrand
			cardType
			cardholderName
			charges
			chargeId
			clinicName
			confirmationMethod
			consolidatorReferenceCode
			consolidatorReferenceCode2
			createdAt
			createdByEmail
			createdByUser
			currency
			customerId
			customerName
			dateToFilter
			description
			failureCode
			failureReason
			id
			invoiceId
			lockboxId
			last4
			metadata
			notes
			object
			originalChargeAmountDue
			originalChargeDate
			paymentMethodId
			paymentMethodOptions
			paymentMethodType
			pimsClientId
			practiceId
			scratchPaymentMethod
			scratchPaymentRequestType
			status
			transactionStatus
			subscriptionId
			subscriptionScheduleId
			succeededAt
			surcharge
			surchargeFee
			updatedAt
			disputed
			disputeId
			disputeStatus
		}
	}
`

export function usePaymentHistoryQuery(
	options?: Omit<Urql.UseQueryArgs<PaymentHistoryQueryVariables>, 'query'>
) {
	return Urql.useQuery<PaymentHistoryQuery, PaymentHistoryQueryVariables>({
		query: PaymentHistoryDocument,
		...options
	})
}
export const PaymentHistoryWithCountDocument = gql`
	query PaymentHistoryWithCount(
		$skip: Int
		$take: Int
		$orderBy: [PaymentHistoryOrderByInput!]
		$where: PaymentHistoryWhereInput
	) {
		paymentHistoriesWithCount(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
			items {
				amount
				amountDue
				amountCaptured
				amountCapturable
				amountReceived
				amountRefunded
				amountWithoutSurcharge
				applicationId
				canceledAt
				cancellationReason
				captureMethod
				cardBrand
				cardType
				cardholderName
				charges
				chargeId
				clinicName
				confirmationMethod
				consolidatorReferenceCode
				consolidatorReferenceCode2
				createdAt
				createdByEmail
				createdByUser
				currency
				customerId
				customerName
				dateToFilter
				description
				failureCode
				failureReason
				id
				invoiceId
				lockboxId
				last4
				metadata
				notes
				object
				originalChargeAmountDue
				originalChargeDate
				paymentMethodId
				paymentMethodOptions
				paymentMethodType
				pimsClientId
				practiceId
				scratchPaymentMethod
				scratchPaymentRequestType
				status
				transactionStatus
				subscriptionId
				subscriptionScheduleId
				succeededAt
				surcharge
				surchargeFee
				updatedAt
				disputed
				disputeId
				disputeStatus
			}
			total
		}
	}
`

export function usePaymentHistoryWithCountQuery(
	options?: Omit<Urql.UseQueryArgs<PaymentHistoryWithCountQueryVariables>, 'query'>
) {
	return Urql.useQuery<PaymentHistoryWithCountQuery, PaymentHistoryWithCountQueryVariables>({
		query: PaymentHistoryWithCountDocument,
		...options
	})
}
export const PaymentHistoryListWithSummaryDocument = gql`
	query PaymentHistoryListWithSummary(
		$skip: Int
		$take: Int
		$orderBy: [PaymentHistoryOrderByInput!]
		$where: PaymentHistoryWhereInput
	) {
		paymentHistoriesWithSummary(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
			items {
				amount
				amountDue
				amountCaptured
				amountCapturable
				amountReceived
				amountRefunded
				amountWithoutSurcharge
				applicationId
				canceledAt
				cancellationReason
				captureMethod
				cardBrand
				cardType
				cardholderName
				charges
				chargeId
				clinicName
				confirmationMethod
				consolidatorReferenceCode
				consolidatorReferenceCode2
				createdAt
				createdByEmail
				createdByUser
				currency
				customerId
				customerName
				dateToFilter
				description
				failureCode
				failureReason
				id
				invoiceId
				lockboxId
				last4
				metadata
				notes
				object
				originalChargeAmountDue
				originalChargeDate
				paymentMethodId
				paymentMethodOptions
				paymentMethodType
				pimsClientId
				practiceId
				scratchPaymentMethod
				scratchPaymentRequestType
				status
				transactionStatus
				subscriptionId
				subscriptionScheduleId
				succeededAt
				surcharge
				surchargeFee
				updatedAt
				disputed
				disputeId
				disputeStatus
			}
			summary {
				count
				totalAmount
				totalRefunded
				totalFailed
				totalPaid
				totalPending
				totalUncaptured
				totalFailedECheck
				totalReturnedECheck
			}
		}
	}
`

export function usePaymentHistoryListWithSummaryQuery(
	options?: Omit<Urql.UseQueryArgs<PaymentHistoryListWithSummaryQueryVariables>, 'query'>
) {
	return Urql.useQuery<
		PaymentHistoryListWithSummaryQuery,
		PaymentHistoryListWithSummaryQueryVariables
	>({ query: PaymentHistoryListWithSummaryDocument, ...options })
}
export const PaymentHistoryWithoutChargesDocument = gql`
	query PaymentHistoryWithoutCharges(
		$skip: Int
		$take: Int
		$orderBy: [PaymentHistoryOrderByInput!]
		$where: PaymentHistoryWhereInput
	) {
		paymentHistories(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
			amount
			amountDue
			amountCaptured
			amountCapturable
			amountReceived
			amountRefunded
			amountWithoutSurcharge
			applicationId
			canceledAt
			cancellationReason
			captureMethod
			cardBrand
			cardType
			cardholderName
			clinicName
			confirmationMethod
			consolidatorReferenceCode
			consolidatorReferenceCode2
			createdAt
			createdByEmail
			createdByUser
			currency
			customerId
			customerName
			dateToFilter
			description
			failureCode
			failureReason
			id
			invoiceId
			lockboxId
			last4
			metadata
			notes
			object
			originalChargeAmountDue
			originalChargeDate
			paymentMethodId
			paymentMethodOptions
			paymentMethodType
			pimsClientId
			practiceId
			scratchPaymentMethod
			scratchPaymentRequestType
			status
			transactionStatus
			subscriptionId
			subscriptionScheduleId
			succeededAt
			surcharge
			surchargeFee
			updatedAt
			disputed
			disputeId
			disputeStatus
		}
	}
`

export function usePaymentHistoryWithoutChargesQuery(
	options?: Omit<Urql.UseQueryArgs<PaymentHistoryWithoutChargesQueryVariables>, 'query'>
) {
	return Urql.useQuery<
		PaymentHistoryWithoutChargesQuery,
		PaymentHistoryWithoutChargesQueryVariables
	>({ query: PaymentHistoryWithoutChargesDocument, ...options })
}
export const PaymentHistorySummaryDocument = gql`
	query PaymentHistorySummary($where: PaymentHistoryWhereInput!) {
		paymentHistorySummary(where: $where) {
			count
			totalAmount
			totalRefunded
			totalFailed
			totalPaid
			totalPending
			totalUncaptured
			totalFailedECheck
			totalReturnedECheck
		}
	}
`

export function usePaymentHistorySummaryQuery(
	options: Omit<Urql.UseQueryArgs<PaymentHistorySummaryQueryVariables>, 'query'>
) {
	return Urql.useQuery<PaymentHistorySummaryQuery, PaymentHistorySummaryQueryVariables>({
		query: PaymentHistorySummaryDocument,
		...options
	})
}
export const PaymentRequestDocument = gql`
	query PaymentRequest(
		$skip: Int
		$take: Int
		$orderBy: [PaymentRequestOrderByInput!]
		$where: PaymentRequestWhereInput
	) {
		paymentRequests(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
			amount
			consolidatorId
			createdAt
			customerId
			customerName
			description
			id
			metadata
			pimsClientId
			practiceId
			scratchPaymentMethod
			status
			succeededAt
			updatedAt
			paidSeparately
			practice {
				id
				name
				consolidatorReferenceCode
				netsuiteId
				skyworldId
			}
		}
	}
`

export function usePaymentRequestQuery(
	options?: Omit<Urql.UseQueryArgs<PaymentRequestQueryVariables>, 'query'>
) {
	return Urql.useQuery<PaymentRequestQuery, PaymentRequestQueryVariables>({
		query: PaymentRequestDocument,
		...options
	})
}
export const PaymentRequestWithSummaryDocument = gql`
	query PaymentRequestWithSummary(
		$skip: Int
		$take: Int
		$orderBy: [PaymentRequestOrderByInput!]
		$where: PaymentRequestWhereInput
	) {
		paymentRequestsWithSummary(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
			items {
				amount
				consolidatorId
				createdAt
				customerId
				customerName
				description
				id
				metadata
				pimsClientId
				practiceId
				scratchPaymentMethod
				status
				succeededAt
				updatedAt
				paidSeparately
				practice {
					id
					name
					consolidatorReferenceCode
					netsuiteId
					skyworldId
				}
			}
			amount
			total
		}
	}
`

export function usePaymentRequestWithSummaryQuery(
	options?: Omit<Urql.UseQueryArgs<PaymentRequestWithSummaryQueryVariables>, 'query'>
) {
	return Urql.useQuery<PaymentRequestWithSummaryQuery, PaymentRequestWithSummaryQueryVariables>({
		query: PaymentRequestWithSummaryDocument,
		...options
	})
}
export const UpdatePaymentRequestDocument = gql`
	mutation UpdatePaymentRequest(
		$id: String
		$arDeliveryMethod: String
		$customerEmail: String
		$customerPhone: String
	) {
		updatePaymentRequest(
			id: $id
			arDeliveryMethod: $arDeliveryMethod
			customerEmail: $customerEmail
			customerPhone: $customerPhone
		) {
			id
			metadata
		}
	}
`

export function useUpdatePaymentRequestMutation() {
	return Urql.useMutation<UpdatePaymentRequestMutation, UpdatePaymentRequestMutationVariables>(
		UpdatePaymentRequestDocument
	)
}
export const ProcessUploadFileDocument = gql`
	mutation ProcessUploadFile($fileName: String!) {
		processUploadedFile(fileName: $fileName) {
			id
			fileName
			createdBy
			consolidatorId
		}
	}
`

export function useProcessUploadFileMutation() {
	return Urql.useMutation<ProcessUploadFileMutation, ProcessUploadFileMutationVariables>(
		ProcessUploadFileDocument
	)
}
export const PaymentRequestFileListDocument = gql`
	query PaymentRequestFileList(
		$skip: Int
		$take: Int
		$orderBy: [PaymentRequestFileOrderByInput!]
		$where: PaymentRequestFileWhereInput
	) {
		paymentRequestFiles(skip: $skip, take: $take, orderBy: $orderBy, where: $where) {
			items {
				id
				createdAt
				createdBy
				recordsCount
				succeededRecordsCount
				failureReason
				failedRecordsCount
				fileName
				createdByUser {
					name
					displayName
				}
			}
			total
		}
	}
`

export function usePaymentRequestFileListQuery(
	options?: Omit<Urql.UseQueryArgs<PaymentRequestFileListQueryVariables>, 'query'>
) {
	return Urql.useQuery<PaymentRequestFileListQuery, PaymentRequestFileListQueryVariables>({
		query: PaymentRequestFileListDocument,
		...options
	})
}
export const GetPracticesDocument = gql`
	query GetPractices(
		$skip: Int
		$take: Int
		$orderBy: [PracticeOrderByInput!]
		$where: PracticeWhereInput
	) {
		practices(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
			id
			stripeAccountId
			name
			city
			state
			address
			zip
		}
	}
`

export function useGetPracticesQuery(
	options?: Omit<Urql.UseQueryArgs<GetPracticesQueryVariables>, 'query'>
) {
	return Urql.useQuery<GetPracticesQuery, GetPracticesQueryVariables>({
		query: GetPracticesDocument,
		...options
	})
}
export const GetAllPracticesCountDocument = gql`
	query GetAllPracticesCount(
		$skip: Int
		$take: Int
		$orderBy: [PracticeOrderByInput!]
		$where: PracticeWhereInput
	) {
		practicesWithCount(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
			items {
				id
				stripeAccountId
				consolidatorId
				name
				city
				state
				address
				zip
				vetClinicId
				settings
				integrationId
				hospitalManagerEmail
				products
				routingNumber
				accountNumber
				logoUrl
				activeTerminalsCount
				totalTerminalsCount
				currentPims
				timezone
				isCustomPayoutEnabled
				consolidator {
					id
					featureFlags
				}
				netsuiteId
				skyworldId
			}
			total
		}
	}
`

export function useGetAllPracticesCountQuery(
	options?: Omit<Urql.UseQueryArgs<GetAllPracticesCountQueryVariables>, 'query'>
) {
	return Urql.useQuery<GetAllPracticesCountQuery, GetAllPracticesCountQueryVariables>({
		query: GetAllPracticesCountDocument,
		...options
	})
}
export const GetAllPracticesDocument = gql`
	query GetAllPractices(
		$skip: Int
		$take: Int
		$orderBy: [PracticeOrderByInput!]
		$where: PracticeWhereInput
	) {
		practices(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
			id
			stripeAccountId
			consolidatorId
			name
			city
			state
			address
			zip
			vetClinicId
			settings
			integrationId
			hospitalManagerEmail
			products
			routingNumber
			accountNumber
			logoUrl
			activeTerminalsCount
			totalTerminalsCount
			currentPims
			timezone
			isCustomPayoutEnabled
			customPayoutSettings
			consolidator {
				id
				featureFlags
			}
			netsuiteId
			skyworldId
		}
	}
`

export function useGetAllPracticesQuery(
	options?: Omit<Urql.UseQueryArgs<GetAllPracticesQueryVariables>, 'query'>
) {
	return Urql.useQuery<GetAllPracticesQuery, GetAllPracticesQueryVariables>({
		query: GetAllPracticesDocument,
		...options
	})
}
export const GetPracticeContextDocument = gql`
	query GetPracticeContext(
		$skip: Int
		$take: Int
		$orderBy: [PracticeOrderByInput!]
		$where: PracticeWhereInput
	) {
		practices(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
			id
			stripeAccountId
			name
			city
			address
			state
			vetClinicId
			terminalLocation
			consolidatorId
			timezone
			locale
			consolidatorReferenceCode
			consolidatorReferenceCode2
			surchargeEnabled
			surchargeFeePct
			settings
			financingStatus
			isDashboardEnabled
			isUnderwritten
			originationFlags
			underwrittingStatus
			regionalManagerId
			isCustomPayoutEnabled
			consolidator {
				id
				featureFlags
				financingFlags
				customization
				customFields
				timezone
			}
		}
	}
`

export function useGetPracticeContextQuery(
	options?: Omit<Urql.UseQueryArgs<GetPracticeContextQueryVariables>, 'query'>
) {
	return Urql.useQuery<GetPracticeContextQuery, GetPracticeContextQueryVariables>({
		query: GetPracticeContextDocument,
		...options
	})
}
export const UpsertCustomPayoutSettingsDocument = gql`
	mutation UpsertCustomPayoutSettings($practiceId: String!, $settings: CustomPayoutSettingsInput!) {
		upsertCustomPayoutSettings(practiceId: $practiceId, settings: $settings) {
			success
			customPayoutSettings
		}
	}
`

export function useUpsertCustomPayoutSettingsMutation() {
	return Urql.useMutation<
		UpsertCustomPayoutSettingsMutation,
		UpsertCustomPayoutSettingsMutationVariables
	>(UpsertCustomPayoutSettingsDocument)
}
export const EnableCustomPayoutV2Document = gql`
	mutation EnableCustomPayoutV2($practiceId: ID!) {
		enableCustomPayoutV2(practiceId: $practiceId) {
			practicePayoutInfo {
				id
				stripeAccountId
				name
				consolidatorId
				customPayoutTarget
				customPayoutSettings
				isCustomPayoutEnabled
			}
		}
	}
`

export function useEnableCustomPayoutV2Mutation() {
	return Urql.useMutation<EnableCustomPayoutV2Mutation, EnableCustomPayoutV2MutationVariables>(
		EnableCustomPayoutV2Document
	)
}
export const ListRefundRequestsDocument = gql`
	query ListRefundRequests(
		$skip: Int
		$take: Int
		$orderBy: [RefundRequestOrderByInput!]
		$where: RefundRequestWhereInput
		$all: Boolean
	) {
		listRefundRequests(skip: $skip, take: $take, orderBy: $orderBy, where: $where, all: $all) {
			total
			skip
			take
			items {
				id
				consolidatorId
				paymentIntentId
				paymentIntent {
					metadata
				}
				originalPaymentAmount
				originalPaymentProcessedAt
				requestedRefundAmount
				requestedById
				requestedByEmail
				requestedBy {
					name
					displayName
				}
				approverRole
				notesRequestor
				status
				pimsClientId
				writebackPimsClientId
				createdAt
				updatedAt
				practice {
					id
					name
					currentPims
				}
				chargeId
				notesApprover
				resolvedAt
				resolvedById
			}
		}
	}
`

export function useListRefundRequestsQuery(
	options?: Omit<Urql.UseQueryArgs<ListRefundRequestsQueryVariables>, 'query'>
) {
	return Urql.useQuery<ListRefundRequestsQuery, ListRefundRequestsQueryVariables>({
		query: ListRefundRequestsDocument,
		...options
	})
}
export const CreateRefundRequestDocument = gql`
	mutation CreateRefundRequest(
		$paymentIntentId: String!
		$pimsClientId: String
		$notes: String
		$requestedRefundAmount: Float!
		$chargeId: String
		$writebackPimsClientId: String
	) {
		upsertRefundRequest(
			paymentIntentId: $paymentIntentId
			pimsClientId: $pimsClientId
			notes: $notes
			chargeId: $chargeId
			requestedRefundAmount: $requestedRefundAmount
			writebackPimsClientId: $writebackPimsClientId
		) {
			id
			status
			requestedRefundAmount
		}
	}
`

export function useCreateRefundRequestMutation() {
	return Urql.useMutation<CreateRefundRequestMutation, CreateRefundRequestMutationVariables>(
		CreateRefundRequestDocument
	)
}
export const UpdateRefundRequestDocument = gql`
	mutation UpdateRefundRequest($id: ID!, $status: String!, $notes: String) {
		upsertRefundRequest(id: $id, status: $status, notes: $notes) {
			id
			consolidatorId
			paymentIntentId
			originalPaymentAmount
			originalPaymentProcessedAt
			requestedRefundAmount
			requestedById
			requestedByEmail
			approverRole
			notesRequestor
			notesApprover
			status
			pimsClientId
			writebackPimsClientId
			createdAt
			updatedAt
			chargeId
			notesApprover
			resolvedAt
			resolvedById
		}
	}
`

export function useUpdateRefundRequestMutation() {
	return Urql.useMutation<UpdateRefundRequestMutation, UpdateRefundRequestMutationVariables>(
		UpdateRefundRequestDocument
	)
}
export const ListMonthlyStatementsDocument = gql`
	query ListMonthlyStatements(
		$skip: Int
		$take: Int
		$orderBy: [MonthlyStatementOrderByInput!]
		$where: MonthlyStatementWhereInput
	) {
		monthlyStatements(skip: $skip, take: $take, orderBy: $orderBy, where: $where) {
			id
			month
			feeTier
			currency
			numAuthorized
			numSettled
			amountSettled
			surchargeVolume
			totalFees
			otherFees
			practiceId
			otherFees
			dayLines {
				day
				numAuthorized
				numSettled
				amountSettled
				totalFees
				surchargeVolume
			}
			feeLines {
				feeType
				rate
				amount
				totalFees
			}
			paymentLines {
				paymentMethodType
				numAuthorized
				numSettled
				amountSettled
				totalFees
				surchargeVolume
			}
		}
	}
`

export function useListMonthlyStatementsQuery(
	options?: Omit<Urql.UseQueryArgs<ListMonthlyStatementsQueryVariables>, 'query'>
) {
	return Urql.useQuery<ListMonthlyStatementsQuery, ListMonthlyStatementsQueryVariables>({
		query: ListMonthlyStatementsDocument,
		...options
	})
}
export const ListUsersDocument = gql`
	query ListUsers(
		$skip: Int
		$take: Int
		$orderBy: [UserOrderByInput!]
		$where: UserWhereInput
		$all: Boolean
	) {
		listUsers(skip: $skip, take: $take, orderBy: $orderBy, where: $where, all: $all) {
			skip
			take
			items {
				consolidatorId
				createdAt
				createdBy
				dashboardUserId
				disabled
				displayName
				email
				emailMapped
				extraEmailForNotifications
				firstName
				id
				initialRole
				isAdmin
				isRegionalManager
				isScratchAdmin
				isUserNameLogin
				lastLoginDate
				lastName
				name
				passwordLastUpdatedAt
				practices {
					isRegionalManager
					isHospitalManager
					isStaff
					practice {
						id
						stripeAccountId
					}
				}
				primaryClinic {
					id
					name
					consolidatorReferenceCode2
					netsuiteId
					skyworldId
				}
				primaryClinicId
				primaryStripeAccountId
				uid
				updatedAt
				username
			}
		}
	}
`

export function useListUsersQuery(
	options?: Omit<Urql.UseQueryArgs<ListUsersQueryVariables>, 'query'>
) {
	return Urql.useQuery<ListUsersQuery, ListUsersQueryVariables>({
		query: ListUsersDocument,
		...options
	})
}
export const ListUsersWithCountDocument = gql`
	query ListUsersWithCount(
		$skip: Int
		$take: Int
		$orderBy: [UserOrderByInput!]
		$where: UserWhereInput
		$all: Boolean
	) {
		listUsersWithCount(skip: $skip, take: $take, orderBy: $orderBy, where: $where, all: $all) {
			total
			skip
			take
			items {
				consolidatorId
				createdAt
				createdBy
				dashboardUserId
				disabled
				displayName
				email
				emailMapped
				extraEmailForNotifications
				firstName
				id
				initialRole
				isAdmin
				isRegionalManager
				isScratchAdmin
				isUserNameLogin
				lastLoginDate
				lastName
				name
				passwordLastUpdatedAt
				practices {
					isRegionalManager
					isHospitalManager
					isStaff
					practice {
						id
						stripeAccountId
					}
				}
				primaryClinic {
					id
					name
					consolidatorReferenceCode2
					netsuiteId
					skyworldId
				}
				primaryClinicId
				primaryStripeAccountId
				uid
				updatedAt
				username
			}
		}
	}
`

export function useListUsersWithCountQuery(
	options?: Omit<Urql.UseQueryArgs<ListUsersWithCountQueryVariables>, 'query'>
) {
	return Urql.useQuery<ListUsersWithCountQuery, ListUsersWithCountQueryVariables>({
		query: ListUsersWithCountDocument,
		...options
	})
}
export const ListUserDocument = gql`
	query ListUser(
		$skip: Int
		$take: Int
		$orderBy: [UserOrderByInput!]
		$where: UserWhereInput
		$all: Boolean
	) {
		listUsers(skip: $skip, take: $take, orderBy: $orderBy, where: $where, all: $all) {
			items {
				consolidatorId
				createdAt
				createdBy
				dashboardUserId
				disabled
				displayName
				email
				emailMapped
				extraEmailForNotifications
				firstName
				id
				initialRole
				isAdmin
				isRegionalManager
				isScratchAdmin
				isUserNameLogin
				lastLoginDate
				lastName
				name
				passwordLastUpdatedAt
				practices {
					isHospitalManager
					isRegionalManager
					isStaff
					practice {
						id
						stripeAccountId
						consolidatorReferenceCode
						consolidatorReferenceCode2
						name
					}
				}
				primaryClinic {
					id
					name
				}
				uid
				updatedAt
				username
			}
		}
	}
`

export function useListUserQuery(
	options?: Omit<Urql.UseQueryArgs<ListUserQueryVariables>, 'query'>
) {
	return Urql.useQuery<ListUserQuery, ListUserQueryVariables>({
		query: ListUserDocument,
		...options
	})
}
