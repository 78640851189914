import { useMemo } from 'react'
import { DateTime } from 'luxon'
import { useCurrentPractice } from 'contexts/PracticeContext'
import { Banner, BannerVariant } from 'components/common/banner'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import { isEmpty } from 'lodash'
import { Button } from '@mui/material'
import { isFinancingOnlyPractice, isIndependentPractice } from '../../../../util/practice'

export const IndependentPracticeVMXBanner = () => {
	const { currentPracticeObject } = useCurrentPractice()

	const isSMBPractice = useMemo(
		() => !isEmpty(currentPracticeObject) && isIndependentPractice(currentPracticeObject),
		[currentPracticeObject]
	)

	const isLendingOnlyPractice = useMemo(
		() => isFinancingOnlyPractice(currentPracticeObject),
		[currentPracticeObject]
	)

	const isBetweenDates = () => {
		const now = DateTime.now()
		const startOfJan17 = DateTime.local(2025, 1, 17).startOf('day')
		const endOfJan29 = DateTime.local(2025, 1, 29).endOf('day')

		return now >= startOfJan17 && now <= endOfJan29
	}

	const showVMXBanner = isSMBPractice && isLendingOnlyPractice && isBetweenDates()

	if (isEmpty(currentPracticeObject) || !showVMXBanner) {
		return null
	}

	return (
		<Banner
			customIcon={
				<EventAvailableIcon
					sx={(theme) => ({
						color: theme.palette.mode === 'dark' ? 'white' : 'black',
						marginX: 2,
						fontSize: '30px'
					})}
				/>
			}
			customActionButtons={
				<Button
					href={
						'https://get.scratchpay.com/demo/checkout-vmx?utm_source=dashboard&utm_medium=banner-ad&utm_campaign=VMX_2025&utm_content=15bps-offer'
					}
					target="_blank"
					component="a"
					sx={(theme) => ({
						marginRight: 4,
						height: '40px',
						width: '140px',
						fontWeight: 'bold',
						backgroundColor: theme.palette.mode === 'dark' ? '#e8e8ff' : '#5B70FF',
						color: theme.palette.mode === 'dark' ? '#6376f5' : '#E8E8FF',
						'&:hover': {
							backgroundColor: theme.palette.mode === 'dark' ? '#fcfcff' : '#314bff'
						}
					})}
				>
					Book Demo
				</Button>
			}
			showButton={false}
			title="Get Early Access to Limited-Time VMX Offers!"
			subtitle="Save an extra 0.15% on Credit Card Processing. Book Demo to Learn More."
			variant={BannerVariant.VMX}
			showCloseButton={false}
		/>
	)
}
