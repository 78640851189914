import { useReducer, useEffect, useRef, DependencyList } from 'react'
import { format } from 'date-fns'
import { DateTime } from 'luxon'
import type { Stripe } from 'stripe'
import { Charge, PaymentIntent } from 'types/stripe'
import toast from 'react-hot-toast'
import {
	addDoc,
	and,
	collection,
	deleteDoc,
	doc,
	DocumentData,
	documentId,
	DocumentReference,
	DocumentSnapshot,
	getDoc,
	getDocs,
	limit,
	onSnapshot,
	or,
	orderBy,
	Query,
	query,
	QueryDocumentSnapshot,
	queryEqual,
	QuerySnapshot,
	refEqual,
	serverTimestamp,
	setDoc,
	startAfter,
	updateDoc,
	where
} from 'firebase/firestore'

import { firestoreClient } from '../lib/firebase'
import { STATE_SUCCEEDED, checkScratchPaymentMethods } from '../constants'

const firestore = firestoreClient()

const COLLECTION_NAME_CHARGES = 'stripeCharges'
const COLLECTION_NAME_PAYMENT_INTENTS = 'paymentIntents'
const COLLECTION_NAME_INVOICES = 'stripeInvoices'
const COLLECTION_WEB_PAYMENT_PRACTICES = 'webPaymentPractices'
const COLLECTION_NAME_RETRUNED_CHECKS = 'returnedChecks'
const COLLECTION_NAME_CUSTOMERS = 'stripeCustomers'
const COLLECTION_NAME_DISPUTES = 'stripeDisputes'
const COLLECTION_CONVERSATIONS = 'conversations'
const COLLECTION_CONVERSATION_MESSAGES = 'conversation_messages'

const DEFAULT_PRACTICE_CHUNK_SIZE = 15

const FIRESTORE_WHERE_IN_LIMIT = 30

export enum GroupBy {
	GROUP_BY_TRANSACTION_DATE = 'transaction',
	GROUP_BY_PAYOUT_DATE = 'payout'
}

const reducer = (state, action) => {
	switch (action.type) {
		case 'idle':
			return { status: 'idle', data: undefined, error: undefined }
		case 'loading':
			return { status: 'loading', data: undefined, error: undefined }
		case 'success':
			return { status: 'success', data: action.payload, error: undefined }
		case 'error':
			toast.error('Data Loading Issue.  You may need to refresh the page.')
			console.error(JSON.stringify(action.payload))
			return { status: 'error', data: undefined, error: action.payload }
		default:
			throw new Error('invalid action')
	}
}

interface ArrayReducerState {
	status: string
	shard: any[]
	data: any
	errorShard?: any[]
	error: any
}
interface ArrayReducerPayload {
	type: string
	index?: number
	payload?: any
}
type ArrayReducer = React.Reducer<ArrayReducerState, ArrayReducerPayload>

// We load all of the data in parallel and call it "loaded" once all the arrays are filled.
const arrayReducer: ArrayReducer = (state: ArrayReducerState, action: ArrayReducerPayload) => {
	switch (action.type) {
		case 'idle':
			return {
				...state,
				status: 'idle'
			}
		case 'loading':
			return {
				...state,
				status: 'loading'
			}
		case 'success': {
			// Set the shard
			state.shard[action.index!] = action.payload
			if (state.status === 'error') {
				return { status: 'error', shard: state.data, data: undefined, error: state.error }
			}
			// If we have all the shards, merge them into a single list and return them.
			const allLoaded = !state.shard.find((a) => a === undefined)
			return {
				...state,
				status: allLoaded ? 'success' : 'loading',
				data: allLoaded ? Array.prototype.concat.apply([], state.shard) : undefined
			}
		}
		case 'error':
			if (state.errorShard && action.index) {
				state.errorShard[action.index] = action.payload
			}
			return {
				...state,
				errorShard: state.errorShard,
				error: action.payload, // Just keep the latest
				status: 'idle'
			}
		default:
			throw new Error('invalid action')
	}
}

// Store an old value and return the old value if it's the same to prevent requerying.
function useMemoCompare<T>(
	next: T,
	compare: (left: T | undefined, right: T | undefined) => boolean
) {
	const previousRef = useRef<T>()
	const previous = previousRef.current
	const isEqual = compare(previous, next)
	useEffect(() => {
		if (!isEqual) {
			previousRef.current = next
		}
	})
	return isEqual ? previous : next
}

// Store an old value and return the old value if it's the same to prevent requerying.
function useMemoCompareArray<T>(
	next: T[],
	compare: (left: T | undefined, right: T | undefined) => boolean
) {
	const previousRef = useRef<T[]>()
	const previous = previousRef.current
	const isEqual =
		previous?.length === next?.length &&
		(!previous || previous.every((val, index) => compare(val, next[index])))
	useEffect(() => {
		if (!isEqual) {
			previousRef.current = next
		}
	})
	return isEqual ? previous : next
}

// Get doc data and merge doc.id => get array of doc data => create useQuery hook
function getDocData(d: any) {
	return d.exists() === true ? { id: d.id, ...d.data() } : null
}
function getCollectionData<T>(c: QuerySnapshot<T>) {
	return c.docs.map(getDocData)
}

// Internal function to memoize the queries and return state.
function memoizeQuery<T>(q: Query<T> | DocumentReference<T> | null, isQuery: boolean) {
	const initialState = {
		status: q ? 'loading' : 'idle',
		data: undefined,
		error: undefined
	}
	const [state, dispatch] = useReducer(reducer, initialState)
	const queryCached = isQuery
		? useMemoCompare<Query>(q as Query, (prevQuery, right) =>
				prevQuery && right ? queryEqual(right, prevQuery) : false
			)
		: useMemoCompare<DocumentReference>(q as DocumentReference, (prevQuery, right) =>
				prevQuery && right ? refEqual(right, prevQuery) : false
			)
	useEffect(() => {
		if (!queryCached) {
			dispatch({ type: 'idle' })
			return
		}
		dispatch({ type: 'loading' })
		// eslint-disable-next-line consistent-return
		if (isQuery) {
			return onSnapshot(
				queryCached as Query,
				(response) => {
					const data = getCollectionData(response)
					dispatch({ type: 'success', payload: data })
				},
				(error) => {
					dispatch({ type: 'error', payload: error })
				}
			)
		}
		return onSnapshot(
			queryCached as DocumentReference,
			(response) => {
				const data = getDocData(response)
				dispatch({ type: 'success', payload: data })
			},
			(error) => {
				dispatch({ type: 'error', payload: error })
			}
		)
	}, [queryCached, isQuery]) // Only run effect if queryCached changes
	return state
}

// With modularity, have a different version for Doc Ref and Query/Collection ref
function useQuery<T>(q: Query<T> | null) {
	return memoizeQuery(q, true)
}

// With modularity, have a different version for Doc Ref and Query/Collection ref
function useDocQuery<T>(q: DocumentReference<T> | null) {
	return memoizeQuery(q, false)
}

// Internal function to memoize the results of multiple queries to firestore and aggregate the results into a specific list.
function useQueries<T>(queries: Query<T>[]) {
	const initialState: ArrayReducerState = {
		status: queries.length > 0 ? 'loading' : 'idle',
		shard: new Array(queries.length), // Initialize the shared to undefined arrays.
		errorShard: new Array(queries.length),
		data: undefined,
		error: undefined
	}
	const [state, dispatch] = useReducer(arrayReducer, initialState)

	// useMemoCompare needs to use a single ref for all the queries regardless of length.
	const queriesCached = useMemoCompareArray(queries, (prevQuery, right) =>
		prevQuery && right ? queryEqual(right, prevQuery) : false
	)
	useEffect(() => {
		if (!queriesCached?.length) {
			// If there isn't anything to query, we're idle
			dispatch({ type: 'idle' })
			return
		}
		dispatch({ type: 'loading' })
		// Get all of the queries and keep their unsubscribe methods
		const unsubscribes = queriesCached.map((q, i) =>
			onSnapshot(
				q!,
				(response) => {
					const data = getCollectionData(response)
					dispatch({ type: 'success', index: i, payload: data })
				},
				(error) => {
					dispatch({ type: 'error', index: i, payload: error })
				}
			)
		)
		return () => unsubscribes.forEach((fn) => fn())
	}, [queriesCached]) // Only run effect if queryCached changes
	return state
}

// Internal function to memoize the results of multiple queries to firestore and aggregate the results into a specific list.
function useQueryForPractices<T>(
	q: Query<T>,
	practices: string[] | null,
	practiceField: string,
	chunkSize: number = DEFAULT_PRACTICE_CHUNK_SIZE
) {
	const queries: Query<T>[] = []
	if (practices) {
		for (let i = 0; i < practices.length; i += chunkSize) {
			const chunk = practices.slice(i, i + chunkSize).filter((a) => !!a)
			queries.push(query(q, where(practiceField, 'in', chunk)))
		}
	}

	return useQueries(queries)
}

/** MEMOIZE API CALL */
/**
 * Temporary hack to allow API calls to look like firestore onSnapshot calls with memoization.
 *
 * @param api the api call to perform.
 * @returns a state object with status, data, and error, that will be updated with useEffect upon changes in results
 */
export function memoizeApiCall<T>(api: () => Promise<T> | null, deps?: DependencyList) {
	const initialState = {
		status: 'loading',
		data: undefined,
		error: undefined
	}
	const [state, dispatch] = useReducer(reducer, initialState)
	// Next two lines are needed to keep "law of hooks" working, since firebase memoizes the query using useRef/useEffect
	useRef(null)
	useEffect(() => {
		'ignore'
	})

	useEffect((): void => {
		const call = api()
		if (call === null) {
			// If there isn't anything to query, we're idle
			dispatch({ type: 'idle' })
			return
		}
		dispatch({ type: 'loading' })
		call
			.then((result) => {
				if ((result as any)?.error) {
					dispatch({ type: 'error', payload: (result as any)?.error })
				} else if (result) {
					dispatch({ type: 'success', payload: result })
				}
			})
			.catch((error) => {
				dispatch({ type: 'error', payload: error })
			})
		// Ignore the useEffect callback
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps ?? []) // Only run the effect once to prevent looping.  Including api here makes errors loop infinitely.
	return state
}

// export function batch() { firestore.batch(); }

/** ** PRACTICES *** */
export function usePractice(id: string | undefined) {
	return useDocQuery(id ? doc(collection(firestore, 'practices'), id) : null)
}

export function getPracticeDocRef(id: string | undefined) {
	return getDoc(doc(collection(firestore, 'practices'), id))
}
export function updatePractice(cuid: string | undefined, data: IPractice) {
	return updateDoc(doc(collection(firestore, 'practices'), cuid), data as any)
}

export function useConsolidator(consolidatorId: string | undefined) {
	return useDocQuery(
		consolidatorId ? doc(collection(firestore, 'consolidators'), consolidatorId) : null
	)
}

export function getAllConsolidators() {
	return useQuery(query(collection(firestore, 'consolidators'), orderBy('consolidatorName')))
}

// Get it once.
export function getConsolidator(consolidatorId: string | undefined) {
	return consolidatorId && getDoc(doc(collection(firestore, 'consolidators'), consolidatorId))
}

/** ** USERS *** */
export function useUser(uid: string | undefined) {
	return useDocQuery(uid ? doc(collection(firestore, 'users'), uid) : null)
}

export const getUser = (uid: string | undefined) => getDoc(doc(collection(firestore, 'users'), uid))

/**
 * @deprecated use /apis/user/updateUser
 */
export function updateUser(uid: string | undefined, data: any) {
	return updateDoc(doc(collection(firestore, 'users'), uid), data).then()
}

export function createUser(uid: string | undefined, data: any) {
	return setDoc(doc(collection(firestore, 'users'), uid), { uid, ...data }, { merge: true })
}

export function createClinic(cuid: string | undefined, data: any) {
	return setDoc(doc(collection(firestore, 'practices'), cuid), { cuid, ...data }, { merge: true })
}

export function sendContactEmail(data: any) {
	return addDoc(collection(firestore, 'emailTrigger'), {
		// const response = collection(firestore, 'emailTrigger').add({
		...data,
		createdAt: serverTimestamp()
	})
	// console.log('SendEmail', response);
	// return response;
}

export function createUsername(email: string | undefined, data: any) {
	return setDoc(doc(collection(firestore, 'usernames'), email), { ...data }, { merge: true })
}

export function useUsername(clinicGeneralEmail: string) {
	return useDocQuery(
		clinicGeneralEmail ? doc(collection(firestore, 'usernames'), clinicGeneralEmail) : null
	)
}

/** ** ITEMS / CHARGES / INVOICES / ETC *** */

// export function useDisputes(acctID) {
// return useQuery(acctID && firestore
// .collection(firestore, 'disputesTest2')
// where('acctID', '==', acctID)
// orderBy('created', 'desc'));

export function useAllUntransferedRefunds() {
	return useQuery(
		query(
			collection(firestore, 'refundReverseTransferNeeded'),
			where('refundTransfer', '==', undefined),
			orderBy('created', 'desc')
		)
	)
}

export function useAllDisputes() {
	return useQuery(query(collection(firestore, 'disputesTest2'), orderBy('created', 'desc')))
}

export function useAllPractices() {
	return useQuery(collection(firestore, 'practices'))
	// orderBy('created', 'desc'));
}

export function useAllPracticesByConsolidatorId(consolidatorId: string | undefined) {
	return useQuery(
		consolidatorId
			? query(collection(firestore, 'practices'), where('consolidatorId', '==', consolidatorId))
			: null
	)
}

export async function useUserPractices(practices: string[]) {
	const ref = await query(
		collection(firestore, 'practices'),
		where('cuid', 'in', practices),
		orderBy('clinicName')
	)
	const practiceDocs = await getDocs(ref)
	return practiceDocs.docs.map((d) => d.data())
}

function makeStartDayLiteral(date: Date | number, toUtc: boolean) {
	const dateTime = DateTime.fromJSDate(new Date(date), { zone: toUtc ? 'utc' : 'local' })
	return dateTime.startOf('day').toSeconds()
}

// Convert the end date to a literal (which is a number, clamping to UTC midnight if toUTC)
function makeEndDayLiteral(date: Date | number, toUtc: boolean) {
	const dateTime = DateTime.fromJSDate(new Date(date), { zone: toUtc ? 'utc' : 'local' })
	return dateTime.endOf('day').toSeconds()
}

export function useChargesByClinic(acctID, startDate: number, endDate: number) {
	const response = useQuery(
		acctID
			? query(
					collection(firestore, 'stripeCharges'),
					where('transfer_data.destination', '==', acctID), // ownerID
					where('created', '>=', startDate),
					where('created', '<=', endDate),
					orderBy('created', 'desc')
				)
			: null
	)
	return response
}

// Return the column to use for payoutReporting date querying
function getPayoutDateColumn(groupBy: GroupBy) {
	return groupBy === GroupBy.GROUP_BY_TRANSACTION_DATE
		? 'transactionDateUtcUNIX'
		: 'automaticPayoutEffectiveAtDayUtcUNIX'
}

export function usePayoutsByConsolidator(
	consolidatorId,
	startDate: Date | number,
	endDate: Date | number,
	groupBy: GroupBy
) {
	console.log({ consolidatorId, startDate, endDate, groupBy })
	const column = getPayoutDateColumn(groupBy)
	const response = useQuery(
		consolidatorId
			? query(
					collection(firestore, 'payoutReporting'),
					where('consolidatorId', '==', consolidatorId), // ownerID
					where(
						column,
						'>=',
						makeStartDayLiteral(startDate, groupBy !== GroupBy.GROUP_BY_TRANSACTION_DATE)
					),
					where(
						column,
						'<=',
						makeEndDayLiteral(endDate, groupBy !== GroupBy.GROUP_BY_TRANSACTION_DATE)
					),
					orderBy(column, 'desc')
				)
			: null
	)
	console.log('CONSOLIDATOR PAYOUTS:DB', response)
	return response
}

export function useUnpaidPayoutsByConsolidator(
	consolidatorId,
	startDate: Date | number,
	endDate: Date | number
) {
	console.log({ consolidatorId, startDate, endDate })
	const response = useQuery(
		consolidatorId
			? query(
					collection(firestore, 'payoutReporting'),
					where('consolidatorId', '==', consolidatorId), // ownerID
					where('automaticPayoutEffectiveAtDayUtcUNIX', '==', null),
					where('transactionDateUtcUNIX', '>=', makeStartDayLiteral(startDate, true)),
					where('transactionDateUtcUNIX', '<=', makeEndDayLiteral(endDate, true)),
					orderBy('transactionDateUtcUNIX', 'desc')
				)
			: null
	)
	console.log('CONSOLIDATOR PAYOUTS:DB', response)
	return response
}

function useCustomBatchTotalsByConsolidatorByPayout(
	consolidatorId,
	startDate: Date | number,
	endDate: Date | number
) {
	return useQuery(
		consolidatorId
			? query(
					collection(firestore, 'customCardPayoutBatchTotal'),
					where('consolidatorId', '==', consolidatorId), // ownerID
					where('payoutEstArrival', '>=', makeStartDayLiteral(startDate, true)),
					where('payoutEstArrival', '<=', makeEndDayLiteral(endDate, true)),
					orderBy('payoutEstArrival', 'desc')
				)
			: null
	)
}

function getTransactionDateFormat(date) {
	// Format will fail on an invalid date, which sometimes comes back, so "ignore it."
	try {
		return format(new Date(date), 'yyyy/MM/dd')
	} catch (x) {
		return null
	}
}

function useCustomBatchTotalsByConsolidatorgroupBy(
	consolidatorId: string | undefined,
	startDate: Date | number,
	endDate: Date | number
) {
	return useQuery(
		consolidatorId
			? query(
					collection(firestore, 'customCardPayoutBatchTotal'),
					where('consolidatorId', '==', consolidatorId), // ownerID
					where('transactionDate', '>=', getTransactionDateFormat(startDate)),
					where('transactionDate', '<=', getTransactionDateFormat(endDate)),
					orderBy('transactionDate', 'desc')
				)
			: null
	)
}

export function useCustomBatchTotalsByConsolidator(
	consolidatorId: string | undefined,
	startDate: Date | number,
	endDate: Date | number,
	groupBy: GroupBy
) {
	console.log({ consolidatorId, startDate, endDate, groupBy })
	const response =
		groupBy === GroupBy.GROUP_BY_TRANSACTION_DATE
			? useCustomBatchTotalsByConsolidatorgroupBy(consolidatorId, startDate, endDate)
			: useCustomBatchTotalsByConsolidatorByPayout(consolidatorId, startDate, endDate)
	console.log('CONSOLIDATOR PAYOUTS:DB', response)
	return response
}

export function usePayoutsByClinic(
	acctID: string | undefined,
	startDate: Date | number,
	endDate: Date | number,
	groupBy: GroupBy
) {
	console.log({ acctID, startDate, endDate, groupBy })
	const column = getPayoutDateColumn(groupBy)
	const response = useQuery(
		acctID
			? query(
					collection(firestore, 'payoutReporting'),
					where('connectedAccount', '==', acctID), // ownerID
					where(
						column,
						'>=',
						makeStartDayLiteral(startDate, groupBy !== GroupBy.GROUP_BY_TRANSACTION_DATE)
					),
					where(
						column,
						'<=',
						makeEndDayLiteral(endDate, groupBy !== GroupBy.GROUP_BY_TRANSACTION_DATE)
					),
					orderBy(column, 'desc')
				)
			: null
	)
	console.log(
		`column: ${column} :fff ${makeStartDayLiteral(startDate, groupBy !== GroupBy.GROUP_BY_TRANSACTION_DATE)}`
	)
	console.log('db response: PAYOUTS', response)
	return response
}

export function useCheckAttemptsByClinic(
	acctID: string | undefined,
	startDate: number,
	endDate: number
) {
	console.log({ acctID, startDate, endDate })
	const response = useQuery(
		acctID
			? query(
					collection(firestore, 'stripeCharges'),
					where('transfer_data.destination', '==', acctID),
					where('payment_method_details.type', '==', 'us_bank_account'),
					where('created', '>=', startDate),
					where('created', '<=', endDate),
					orderBy('created', 'desc'),
					orderBy('id', 'desc')
				)
			: null
	)
	console.log('db response: CheckAttempts', response)
	return response
}

export function useFailedCheckAttemptsByClinic(acctID: string) {
	const response = useQuery(
		acctID
			? query(
					collection(firestore, 'stripeCharges'),
					where('transfer_data.destination', '==', acctID),
					where('payment_method_details.type', '==', 'us_bank_account'),
					where('failure_code', '!=', null),
					orderBy('failure_code', 'asc')
				)
			: null
	)
	console.log('Failed CheckAttempts', response)
	return response
}

export function useCheckAttemptsByConsolidator(
	consolidatorId: string | undefined,
	startDate: number,
	endDate: number
) {
	console.log({ consolidatorId, startDate, endDate })
	const response = useQuery(
		consolidatorId
			? query(
					collection(firestore, 'stripeCharges'),
					where('payment_method_details.type', '==', 'us_bank_account'),
					where('metadata.consolidatorId', '==', consolidatorId),
					where('created', '>=', startDate),
					where('created', '<=', endDate),
					orderBy('created', 'desc'),
					orderBy('id', 'desc')
				)
			: null
	)
	console.log('db response: CheckAttemptsEnterprise', response)
	return response
}

export function useLockboxChecksByConsolidator(consolidatorId: string) {
	const response = useQuery(
		consolidatorId
			? query(
					collection(firestore, 'stripeCharges'),
					where('payment_method_details.type', '==', 'us_bank_account'),
					where('metadata.consolidatorId', '==', consolidatorId),
					orderBy('created', 'desc'),
					orderBy('metadata.lockboxInvoiceId', 'asc')
				)
			: null
	)
	return response
}

export function useConsolidatorMonthlyFeeSummary(
	consolidatorId: string | number,
	startDate: number,
	endDate: number
) {
	const response = useQuery(
		consolidatorId
			? query(
					collection(firestore, 'consolidatorMonthlyCostSummary'),
					where('consolidatorId', '==', consolidatorId),
					where('datalLoadtimeUtcUnix', '>=', startDate),
					where('datalLoadtimeUtcUnix', '<=', endDate)
				)
			: null
	)
	// orderBy('created', 'desc')
	// orderBy('id', 'desc'));
	return response
}

export function useFeesByConsolidator(
	consolidatorId: string | number,
	startDate: number,
	endDate: number
) {
	const response = useQuery(
		consolidatorId
			? query(
					collection(firestore, 'feeReporting'),
					where('consolidatorId', '==', consolidatorId),
					where('datalLoadtimeUtcUnix', '>=', startDate),
					where('datalLoadtimeUtcUnix', '<=', endDate)
				)
			: null
	)
	// orderBy('created', 'desc')
	// orderBy('id', 'desc'));
	return response
}

export function useConsolidatorTerminalCosts(
	consolidatorId: string | number,
	startDate: number,
	endDate: number
) {
	const response = useQuery(
		consolidatorId
			? query(
					collection(firestore, 'consolidatorTerminalCosts'),
					where('consolidatorId', '==', consolidatorId),
					where('datalLoadtimeUtcUnix', '>=', startDate),
					where('datalLoadtimeUtcUnix', '<=', endDate)
				)
			: null
	)
	// orderBy('created', 'desc')
	// orderBy('id', 'desc'));
	return response
}

export function useConsolidatorOtherServicesAndFeeCosts(
	consolidatorId: string | number,
	startDate: number,
	endDate: number
) {
	const response = useQuery(
		consolidatorId
			? query(
					collection(firestore, 'consolidatorOtherServicesAndFeeCosts'),
					where('consolidatorId', '==', consolidatorId),
					where('datalLoadtimeUtcUnix', '>=', startDate),
					where('datalLoadtimeUtcUnix', '<=', endDate)
				)
			: null
	)
	// orderBy('created', 'desc')
	// orderBy('id', 'desc'));
	return response
}

export function useConsolidatorOtherCostPcrCnt(
	consolidatorId: string | number,
	startDate: number,
	endDate: number
) {
	const response = useQuery(
		consolidatorId
			? query(
					collection(firestore, 'consolidatorOtherCostPcrCnt'),
					where('consolidatorId', '==', consolidatorId),
					where('datalLoadtimeUtcUnix', '>=', startDate),
					where('datalLoadtimeUtcUnix', '<=', endDate)
				)
			: null
	)
	// orderBy('created', 'desc')
	// orderBy('id', 'desc'));
	console.log('db response: ConsolidatorPCRcntFees', response)
	return response
}

export function useConsolidatorMonthlyFeeByLocation(
	consolidatorId: string | number,
	startDate: number,
	endDate: number
) {
	const response = useQuery(
		consolidatorId
			? query(
					collection(firestore, 'consolidatorMonthlyFeeByLocation'),
					where('consolidatorId', '==', consolidatorId),
					where('datalLoadtimeUtcUnix', '>=', startDate),
					where('datalLoadtimeUtcUnix', '<=', endDate)
				)
			: null
	)
	// orderBy('created', 'desc')
	// orderBy('id', 'desc'));
	return response
}

export function useConsolidatorEoMPaymentsInTransit(
	consolidatorId: string | number,
	startDate: number,
	endDate: number
) {
	const response = useQuery(
		consolidatorId
			? query(
					collection(firestore, 'consolidatorEoMPaymentsInTransit'),
					where('consolidatorId', '==', consolidatorId),
					where('datalLoadtimeUtcUnix', '>=', startDate),
					where('datalLoadtimeUtcUnix', '<=', endDate)
				)
			: null
	)
	// orderBy('created', 'desc')
	// orderBy('id', 'desc'));
	return response
}

export function useConsolidatorEoMLendingInTransit(
	consolidatorId: string | number,
	startDate: number,
	endDate: number
) {
	const response = useQuery(
		consolidatorId
			? query(
					collection(firestore, 'consolidatorEoMLendingInTransit'),
					where('consolidatorId', '==', consolidatorId),
					where('datalLoadtimeUtcUnix', '>=', startDate),
					where('datalLoadtimeUtcUnix', '<=', endDate)
				)
			: null
	)
	// orderBy('created', 'desc')
	// orderBy('id', 'desc'));
	return response
}

export function usePaymentIntents(startDate: number, endDate: number) {
	const response = useQuery(
		query(
			collection(firestore, 'paymentIntents'),
			where('created', '>=', startDate),
			where('created', '<=', endDate),
			orderBy('created', 'desc')
		)
	)
	return response
}

export function useChargesByClinicDataGrid(acctID: string | undefined) {
	const response = useQuery(
		acctID
			? query(
					collection(firestore, 'stripeCharges'),
					where('transfer_data.destination', '==', acctID), // ownerID
					// where('created', '>=', startDate)
					// where('created', '<=', endDate)
					orderBy('created', 'desc')
				)
			: null
	)
	return response
}

export function useDailyTotalsChargesByClinic(
	acctID: string | undefined,
	startDate: number,
	endDate: number
) {
	const response = useQuery(
		acctID
			? query(
					collection(firestore, 'stripeCharges'),
					where('transfer_data.destination', '==', acctID), // ownerID
					where('created', '>=', startDate),
					where('created', '<=', endDate),
					where('status', 'in', ['succeeded', 'pending', 'failed']),
					orderBy('created', 'desc')
				)
			: null
	)
	return response
}

export function useChargesByCustomer(acctID?: string, customerID?: string) {
	return useQuery(
		acctID
			? query(
					collection(firestore, 'stripeCharges'),
					where('transfer_data.destination', '==', acctID), // ownerID
					where('customer', '==', customerID), // customerID
					orderBy('created', 'desc')
				)
			: null
	)
}

export function usePayoutsTest() {
	return useQuery(collection(firestore, 'payoutsTest'))
}

export function useReconciliationByClinic(
	acctID: string | undefined,
	startDate: number,
	endDate: number
) {
	return useQuery(
		acctID
			? query(
					collection(firestore, 'stripeCharges'),
					where('transfer_data.destination', '==', acctID), // ownerID
					where('paid', '==', true),
					where('refunded', '==', false),
					where('created', '>=', startDate),
					where('created', '<=', endDate),
					orderBy('created', 'desc')
				)
			: null
	)
}

export function useRefundsByClinic(acctID: string | undefined) {
	return useQuery(
		acctID
			? query(
					collection(firestore, 'stripeCharges'),
					where('transfer_data.destination', '==', acctID), // ownerID
					where('refunds.total_count', '!=', 0),
					orderBy('refunds.total_count', 'desc'),
					orderBy('created', 'desc')
				)
			: null
	)
}

export function useRefundsByClinics(
	practices: string[] | null,
	chunkSize: number = DEFAULT_PRACTICE_CHUNK_SIZE
) {
	return useQueryForPractices(
		query(
			collection(firestore, 'stripeCharges'),
			where('refunds.total_count', '!=', 0),
			orderBy('refunds.total_count', 'desc'),
			orderBy('created', 'desc')
		),
		practices,
		'transfer_data.destination',
		chunkSize
	)
}

export function useLockboxInvoicesByClinic(
	acctID: string | undefined,
	startDate: number,
	endDate: number
) {
	return useQuery(
		acctID
			? query(
					collection(firestore, 'stripeInvoices'),
					where('transfer_data.destination', '==', acctID),
					where('metadata.scratchPaymentMethod', '==', 'check_payment'),
					where('created', '>=', startDate),
					where('created', '<=', endDate),
					orderBy('created', 'desc')
				)
			: null
	)
}

export function useLockboxInvoicesByClinics(
	practices: string[] | null,
	startDate: number,
	endDate: number,
	chunkSize: number = DEFAULT_PRACTICE_CHUNK_SIZE
) {
	return useQueryForPractices(
		query(
			collection(firestore, 'stripeInvoices'),
			where('metadata.scratchPaymentMethod', '==', 'check_payment'),
			where('created', '>=', startDate),
			where('created', '<=', endDate),
			orderBy('created', 'desc')
		),
		practices,
		'transfer_data.destination',
		chunkSize
	)
}

export function useDisputesByClinic(acctID: string | undefined) {
	return useQuery(
		acctID
			? query(
					collection(firestore, 'stripeCharges'),
					where('transfer_data.destination', '==', acctID), // ownerID
					where('dispute', '>', ''),
					orderBy('dispute', 'desc'),
					orderBy('created', 'desc')
				)
			: null
	)
}

export async function getAllInvoicesByConsolidatorId(consolidatorId) {
	if (!consolidatorId) return []

	const collectionPath = collection(firestore, 'paymentIntents')
	return getDocs(
		query(
			collectionPath,
			where('metadata.consolidatorId', '==', consolidatorId),
			where('metadata.scratchPaymentMethod', 'in', ['payment_request', 'accounts_receivable']),
			orderBy('created', 'desc')
		)
	).then(formatDocs)
}

export function useAllOpenPaymentIntentsByConsolidator(practices: string[]) {
	return useQuery(
		practices
			? query(
					collection(firestore, 'paymentIntents'),
					where('transfer_data.destination', 'in', practices),
					where('metadata.scratchPaymentMethod', 'in', ['payment_request', 'accounts_receivable']),
					orderBy('created', 'desc')
				)
			: null
	)
}

export function useAllOpenPaymentIntentsByClinic(acctID: string | undefined) {
	return useQuery(
		acctID
			? query(
					collection(firestore, 'paymentIntents'),
					where('transfer_data.destination', '==', acctID),
					where('metadata.scratchPaymentMethod', 'in', ['payment_request', 'accounts_receivable']),
					orderBy('created', 'desc')
				)
			: null
	)
}

export function useClinicPaymentIntentsByScratchPaymentMethod(
	acctID: string | undefined,
	scratchPaymentMethod
) {
	return useQuery(
		acctID
			? query(
					collection(firestore, 'paymentIntents'),
					where('transfer_data.destination', '==', acctID),
					where('metadata.scratchPaymentMethod', '==', scratchPaymentMethod),
					orderBy('created', 'desc')
				)
			: null
	)
}

// Todo: refactor to use useClinicPaymentIntentsByScratchPaymentMethod instead
export function useCheckInvoicesByClinic(acctID: string | undefined) {
	return useQuery(
		acctID
			? query(
					collection(firestore, 'stripeInvoices'),
					where('transfer_data.destination', '==', acctID),
					where('metadata.scratchPaymentMethod', '==', 'check_payment'),
					orderBy('created', 'desc')
				)
			: null
	)
}

export function useCheckInvoicesByConsolidator(
	consolidatorId: string | number | undefined,
	start: number,
	end: number
) {
	return useQuery(
		consolidatorId
			? query(
					collection(firestore, 'stripeInvoices'),
					where('metadata.scratchPaymentMethod', '==', 'check_payment'),
					where('metadata.consolidatorId', '==', consolidatorId),
					where('created', '>=', start),
					where('created', '<=', end),
					orderBy('created', 'desc')
				)
			: null
	)
}

export function useCheckPaymentIntentsByConsolidatorId(
	consolidatorId: string | undefined,
	start: number,
	end: number
) {
	return useQuery(
		consolidatorId
			? query(
					collection(firestore, 'paymentIntents'),
					where('metadata.consolidatorId', '==', consolidatorId),
					where('metadata.scratchPaymentMethod', 'in', checkScratchPaymentMethods),
					where('created', '>=', start),
					where('created', '<=', end),
					orderBy('created', 'desc')
				)
			: null
	)
}

export function useInvoice(id: string | undefined) {
	return useDocQuery(id ? doc(collection(firestore, 'stripeInvoices'), id) : null)
}

export function useInvoiceNotes(invoiceId: string | undefined) {
	return useQuery(
		invoiceId
			? query(
					collection(firestore, 'paymentIntents', invoiceId, 'invoiceNotes'),
					orderBy('created_at', 'desc')
				)
			: null
	)
}

export function addInvoiceNote(invoiceId: string | undefined, note: any, created_by?: string) {
	return addDoc(collection(firestore, 'paymentIntents', invoiceId!, 'invoiceNotes'), {
		note,
		created_at: serverTimestamp(),
		...(created_by && { created_by })
	})
}

export function useManageClinics(consolidatorId: string | undefined) {
	return useQuery(
		consolidatorId
			? query(
					collection(firestore, 'practices'),
					where('consolidatorId', '==', consolidatorId),
					orderBy('clinicName')
				)
			: null
	)
}

export function useManageNotifications(stripeAccountId: string | undefined) {
	return useQuery(
		stripeAccountId
			? query(
					collection(firestore, 'actionNeeded'),
					where('destination', '==', stripeAccountId),
					where('isComplete', '==', false),
					orderBy('created', 'desc')
				)
			: null
	)
}

export function useSelectRegionalManagerClinics(uid?: string) {
	return useQuery(
		uid
			? query(
					collection(firestore, 'practices'),
					where('regionalManagerId', '==', uid),
					orderBy('clinicName')
				)
			: null
	)
}

export function updateVetcorPractice(practiceID: string | undefined, data: any) {
	return updateDoc(doc(collection(firestore, 'vetcorPractices'), practiceID), data)
}

export function updateARitem(id: string | undefined, data: any) {
	console.log(id, data)
	return updateDoc(doc(collection(firestore, 'vetcorARtest'), id), data)
}

export function usePracticeInfo(currentPractice: string | undefined) {
	return useQuery(
		currentPractice
			? query(
					collection(firestore, 'practices'),
					where('stripeAccountId', '==', currentPractice)
					// orderBy('created', 'desc'))
				)
			: null
	)
}

// TO DO: Add consolidatorId to the Stripe Metadata, and then add that as a filter here.
export function useStripeAccounts(consolidatorId: string | undefined) {
	return useQuery(
		query(
			collection(firestore, 'stripeAccounts'),
			where('metadata.consolidatorId', '==', consolidatorId),
			orderBy('id', 'desc')
		)
	)
}

// Fetch item data
export function useCharge(id: string | undefined) {
	return useDocQuery(id ? doc(collection(firestore, 'stripeCharges'), id) : null)
}

export function usePractices(cuid: string | undefined) {
	return useDocQuery(cuid ? doc(collection(firestore, 'practices'), cuid) : null)
}

export async function updateCharge(id: string | undefined, data: any) {
	console.log(id, data)
	const chargeRef = doc(collection(firestore, 'stripeCharges'), id)
	const res = updateDoc(chargeRef, data)
	return res
}

export async function addCharge(id: string | undefined, data: any) {
	console.log(id, data)
	return setDoc(doc(collection(firestore, 'stripeCharges'), id), data, { merge: true })
}

export function useItem(id: string | undefined) {
	return useDocQuery(id ? doc(collection(firestore, 'items'), id) : null)
}
export async function updateItem(id: string | undefined, data: any) {
	return updateDoc(doc(collection(firestore, 'items'), id), data)
}
export async function createItem(data: any) {
	return addDoc(collection(firestore, 'items'), {
		...data,
		createdAt: serverTimestamp()
	})
}
export function createCanadaInstallmentPlan(data: any) {
	return addDoc(collection(firestore, 'canadaInstallmentPlans'), {
		...data,
		createdAt: serverTimestamp()
	})
}
// export function createInstallmentPlan(uid, data) {
//   return firestore
//     .collection(firestore, 'installmentPlans')
//     .doc(uid)
//     .set({
//       ...data,
//       createdAt: serverTimestamp()
//     }, { merge: true });
// }

export function deleteItem(id) {
	return deleteDoc(doc(collection(firestore, 'items'), id))
}

export function usePimsClientByClinic(acctID) {
	return useQuery(
		acctID ? query(collection(firestore, 'pimsClient'), where('ClinicID', '==', acctID)) : null
	)
}

export function usePimsClientByClinicBatched(acctID, pageSize, lastDoc) {
	return getDocs(
		query(
			collection(firestore, 'pimsClient'),
			orderBy('ClientCell', 'asc'),
			where('ClinicID', '==', acctID),
			startAfter(lastDoc),
			limit(pageSize)
		)
	)
}

export function usePimsClientById(docId) {
	return useDocQuery(docId ? doc(collection(firestore, 'pimsClient'), docId) : null)
}

export function usePimsClient(clientId, acctID) {
	return useDocQuery(
		clientId && acctID ? doc(collection(firestore, 'pimsClient'), `${acctID}-${clientId}`) : null
	)
}

export function usePimsClientByFirstLastPhone(firstName, lastName, phone, acctID) {
	if (!phone) {
		return useQuery(
			firstName && lastName && phone && firestore
				? query(
						collection(firestore, 'pimsClient'),
						where('ClinicID', '==', acctID),
						where('ClientFirst', '==', firstName),
						where('ClientLast', '==', lastName)
					)
				: null
		)
	}
	const phoneArea = phone.substring(2, 5)
	const phoneNumPartOne = phone.substring(5, 8)
	const phoneNumPartTwo = phone.substring(8)
	return useQuery(
		firstName && lastName && phone
			? query(
					collection(firestore, 'pimsClient'),
					where('ClinicID', '==', acctID),
					where('ClientFirst', '==', firstName),
					where('ClientLast', '==', lastName),
					where('ClientArea', '==', phoneArea),
					where('ClientPhone', '==', `${phoneNumPartOne}-${phoneNumPartTwo}`)
				)
			: null
	)
}

export function usePimsAnimal(animalId, acctID) {
	return useDocQuery(
		animalId && acctID ? doc(collection(firestore, 'pimsAnimal'), `${acctID}-${animalId}`) : null
	)
}

export function usePimsAppointmentByClinic(acctID) {
	return useQuery(
		acctID
			? query(
					collection(firestore, 'pimsAppointment'),
					where('ClinicID', '==', acctID),
					// where('AppointmentDate', '>=', startDate),
					// where('AppointmentDate', '<=', endDate),
					orderBy('AppointmentDate', 'asc'),
					orderBy('AppointmentTime', 'asc')
				)
			: null
	)
}

export function usePimsAppointmentByParent(animalId, acctID) {
	return useQuery(
		animalId && acctID
			? query(
					collection(firestore, 'pimsAppointment'),
					where('ClinicID', '==', acctID),
					where('AppointmentParent', '==', animalId),
					orderBy('AppointmentDate', 'asc'),
					orderBy('AppointmentTime', 'asc')
				)
			: null
	)
}

export function usePimsClientByDoc(docId) {
	return useDocQuery(docId && doc(collection(firestore, 'pimsClient'), docId))
}

export function usePimsAnimalByNameClientClinic(animalName, clientId, acctId) {
	return useQuery(
		animalName && clientId && acctId
			? query(
					collection(firestore, 'pimsAnimal'),
					where('ClinicID', '==', acctId),
					where('AnimalName', '==', animalName),
					where('AnimalClient', '==', clientId)
				)
			: null
	)
}

export function useCustomersByMetadata(acctID) {
	return useQuery(
		acctID
			? query(
					collection(firestore, 'stripeCustomers'),
					where('metadata.stripeAccountId', '==', acctID)
				)
			: null
	)
}

export function useUsersByStripeAccount(stripeAccount) {
	console.log(stripeAccount)
	return useQuery(
		stripeAccount
			? query(collection(firestore, 'users'), where('practices', 'array-contains', stripeAccount))
			: null
	)
	// orderBy('Created', 'desc')
	// orderBy('Practices', 'desc'));
}
export function useUsersPracticesId(practiceId) {
	console.log(practiceId)
	return useQuery(
		practiceId
			? query(collection(firestore, 'users'), where('practices', 'array-contains', practiceId))
			: null
	)
	// orderBy('Created', 'desc')
	// orderBy('Practices', 'desc'));
}

export function useUsersByHmUsers(HmUsers) {
	console.log(HmUsers)
	return useQuery(
		HmUsers ? query(collection(firestore, 'users'), where('id', 'in', HmUsers)) : null
	)
	// orderBy('clinicName'));
}

export function useUsersByConsolidatorID(consolidatorID) {
	console.log(consolidatorID)
	return useQuery(
		consolidatorID
			? query(collection(firestore, 'users'), where('consolidatorId', '==', consolidatorID))
			: null
	)
	// orderBy('Created', 'desc')
	// orderBy('Practices', 'desc'));
}

export function useRegionalManagers(consolidatorID) {
	return useQuery(
		consolidatorID
			? query(
					collection(firestore, 'users'),
					where('consolidatorId', '==', consolidatorID),
					where('isRegionalManager', '==', true),
					orderBy('name', 'asc')
				)
			: null
	)
}

export function useAdminUsersByPracticeID(practiceID) {
	console.log(practiceID)
	return useQuery(
		practiceID
			? query(collection(firestore, 'users'), where('adminUser', 'array-contains', practiceID))
			: null
	)
}

// export function useAdminUsersByPracticeID2(practiceID) {
//   console.log(practiceID);
//   return useQuery(practiceID && firestore
//     .collectionGroup('roles')
//     .doc(practiceID));
// }

export function useStaffUsersByPracticeID(practiceID) {
	console.log(practiceID)
	return useQuery(
		practiceID
			? query(collection(firestore, 'users'), where('staffUser', 'array-contains', practiceID))
			: null
	)
}

export function useStripeCustomersByID(id) {
	return useDocQuery(id && doc(collection(firestore, 'stripeCustomers'), id))
}

export function useUserByEmail(email) {
	return useDocQuery(email && doc(collection(firestore, 'users'), email))
}

export function usePaymentIntentByID(id) {
	return useDocQuery(id && doc(collection(firestore, 'paymentIntents'), id))
}

export function mergePaymentIntentByID(id, data) {
	console.log(id, data)
	const paymentIntentRef = doc(collection(firestore, 'paymentIntents'), id)
	console.log(paymentIntentRef)
	const res = setDoc(paymentIntentRef, data, { merge: true })
	console.log(res)
	return res
}

export function mergeInvoiceByID(id, data) {
	console.log(id, data)
	const invoiceRef = doc(collection(firestore, 'stripeInvoices'), id)
	console.log(invoiceRef)
	const res = setDoc(invoiceRef, data, { merge: true })
	console.log(res)
	return res
}

export function usePracticeByID(id) {
	return useDocQuery(id && doc(collection(firestore, 'practices'), id))
}

export function useUserByID(id) {
	return useDocQuery(id && doc(collection(firestore, 'users'), id))
}

export function useAccountByID(id) {
	return useDocQuery(id && doc(collection(firestore, 'accounts'), id))
}

export function usePracticeFormTemplates(id) {
	return useQuery(
		id
			? query(collection(firestore, 'customFormTemplates'), where('stripeAccountId', '==', id))
			: null
	)
}

export function usePracticeSnippets(id) {
	return useQuery(
		id ? query(collection(firestore, 'customSnippets'), where('stripeAccountId', '==', id)) : null
	)
}

export function deleteSnippet(id) {
	return deleteDoc(doc(collection(firestore, 'customSnippets'), id))
}

export function deleteCustomFormTemplate(id) {
	return deleteDoc(doc(collection(firestore, 'customFormTemplates'), id))
}

export function addCustomSnippet(data) {
	const response = addDoc(collection(firestore, 'customSnippets'), data)
	return response
}

export function updateCustomSnippet(id, data) {
	const response = updateDoc(doc(collection(firestore, 'customSnippets'), id), data)
	return response
}

export function useCustomFormTemplate(id) {
	return useDocQuery(id && doc(collection(firestore, 'customFormTemplates'), id))
}

export function updateCustomFormTemplate(id, data) {
	const response = updateDoc(doc(collection(firestore, 'customFormTemplates'), id), data)
	return response
}

export function addCustomFormTitle(data) {
	const response = addDoc(collection(firestore, 'customFormTemplates'), data)
	return response
}

export function addCustomFormResponse(data) {
	return addDoc(collection(firestore, 'customFormResponses'), {
		...data,
		createdAt: serverTimestamp()
	})
}

export function useFormResponseByConvoSid(convoSid) {
	return useQuery(
		convoSid
			? query(
					collection(firestore, 'customFormResponses'),
					where('conversationSid', '==', convoSid)
				)
			: null
	)
}

export function useClinicSelect(
	isAdmin,
	isRegionalManager,
	practices,
	uid,
	consolidatorId,
	isScratchAdmin
) {
	if (isScratchAdmin === true) {
		return useQuery(uid ? query(collection(firestore, 'practices'), orderBy('clinicName')) : null)
	}
	if (isAdmin === true) {
		return useQuery(
			consolidatorId
				? query(
						collection(firestore, 'practices'),
						where('consolidatorId', '==', consolidatorId),
						orderBy('clinicName')
					)
				: null
		)
	}
	if (isRegionalManager === true) {
		return useQuery(
			uid
				? query(
						collection(firestore, 'practices'),
						where('regionalManagerId', '==', uid),
						orderBy('clinicName')
					)
				: null
		)
	}

	if (!practices || !practices.length) return { status: 'success', data: [], error: undefined }

	if (practices.length <= FIRESTORE_WHERE_IN_LIMIT) {
		return useQuery(
			practices
				? query(
						collection(firestore, 'practices'),
						where('cuid', 'in', practices),
						orderBy('clinicName')
					)
				: null
		)
	}

	const chunks: string[][] = []
	for (let i = 0; i < practices.length; i += FIRESTORE_WHERE_IN_LIMIT) {
		chunks.push(practices.slice(i, i + FIRESTORE_WHERE_IN_LIMIT))
	}

	const queries = chunks.map((chunk) =>
		query(collection(firestore, 'practices'), where('cuid', 'in', chunk), orderBy('clinicName'))
	)

	return useQueries(queries)
}

export function useInviteUser(isAdmin, isRegionalManager, practices, uid, consolidatorId) {
	console.log({ isAdmin, isRegionalManager, practices, uid, consolidatorId })
	if (isAdmin === true) {
		return useQuery(
			consolidatorId
				? query(
						collection(firestore, 'practices'),
						where('consolidatorId', '==', consolidatorId),
						orderBy('clinicName')
					)
				: null
		)
	}
	if (isRegionalManager === true) {
		return useQuery(
			uid
				? query(
						collection(firestore, 'practices'),
						where('regionalManagerId', '==', uid),
						orderBy('clinicName')
					)
				: null
		)
	}
	console.log(uid)
	return useQuery(
		uid
			? query(
					collection(firestore, 'practices'),
					where('hmUsers', 'array-contains', uid),
					orderBy('clinicName')
				)
			: null
	)
}

export function usePaymentIntentsByRequestSucceededAtFilter(acctID, startDate, endDate) {
	return useQuery(
		acctID
			? query(
					collection(firestore, 'paymentIntents'),
					and(
						where('transfer_data.destination', '==', acctID), // ownerID
						or(
							where('metadata.paymentRequestSucceededAt', '>=', startDate),
							where('metadata.paymentRequestSucceededAt', '>=', startDate.toString())
						),
						or(
							where('metadata.paymentRequestSucceededAt', '<=', endDate),
							where('metadata.paymentRequestSucceededAt', '<=', endDate.toString())
						)
					),
					orderBy('metadata.paymentRequestSucceededAt', 'desc')
				)
			: null
	)
}

export function usePaymentIntentsByRequestSucceededAtFilterBulk(
	practices,
	startDate,
	endDate,
	chunkSize: number = DEFAULT_PRACTICE_CHUNK_SIZE
) {
	return useQueryForPractices(
		query(
			collection(firestore, 'paymentIntents'),
			where('metadata.paymentRequestSucceededAt', '>=', startDate),
			where('metadata.paymentRequestSucceededAt', '<=', endDate),
			orderBy('metadata.paymentRequestSucceededAt', 'desc')
		),
		practices,
		'transfer_data.destination',
		chunkSize
	)
}

function addPaymentIntentStatusFilter(q: Query, status: string) {
	switch (status) {
		case 'all':
			return q
		case undefined:
			return q
		case 'pending':
			return query(
				q,
				where('status', 'in', [
					'requires_payment_method',
					'requires_confirmation',
					'requires_action',
					'processing'
				])
			)
		default:
			return query(q, where('status', '==', status))
	}
}

export function usePaymentIntentsByClinic(acctID, startDate, endDate, status) {
	if (!acctID) {
		return useQuery(null)
	}

	let q = query(
		collection(firestore, 'paymentIntents'),
		where('transfer_data.destination', '==', acctID), // ownerID
		where('created', '>=', startDate),
		where('created', '<=', endDate),
		orderBy('created', 'desc')
	)

	q = addPaymentIntentStatusFilter(q, status)

	return useQuery(q)
}

export function usePaymentIntentsByClinics(
	practices,
	startDate,
	endDate,
	status,
	chunkSize: number = DEFAULT_PRACTICE_CHUNK_SIZE
) {
	console.log({ practices, startDate, endDate, status })

	let q = query(
		collection(firestore, 'paymentIntents'),
		where('created', '>=', startDate),
		where('created', '<=', endDate),
		orderBy('created', 'desc')
	)

	q = addPaymentIntentStatusFilter(q, status)
	return useQueryForPractices(q, practices, 'transfer_data.destination', chunkSize)
}

export function usePaymentIntentTestByClinic(acctID) {
	return useQuery(
		acctID
			? query(
					collection(firestore, 'paymentIntentTest'),
					where('paymentIntent.transfer_data.destination', '==', acctID), // ownerID
					orderBy('paymentIntent.created', 'desc')
				)
			: null
	)
}

export function usePaymentIntentTest2ByClinic(acctID) {
	return useQuery(
		acctID
			? query(
					collection(firestore, 'paymentIntentTest2'),
					where('transfer_data.destination', '==', acctID), // ownerID
					orderBy('created', 'desc')
				)
			: null
	)
}

export function useVetcorARtest() {
	return useQuery(collection(firestore, 'vetcorARtest'))
	// orderBy('uuid', 'desc'));
}

export function useVetcorARtestPerClinic(stripeID) {
	return useQuery(
		stripeID
			? query(collection(firestore, 'vetcorARtest'), where('stripeAccountId', '==', stripeID))
			: null
	)
}

// export function getContentById(ids, startDate, endDate) {
//   return new Promise((res) => {
//     // don't run if there aren't any ids or a path for the collection
//     if (!ids || !ids.length) return res([]);

//     // const collectionPath = collection(path);
//     const batches = [];

//     while (ids.length) {
//       // firestore limits batches to 10
//       const batch = ids.splice(0, 10);

//       // add the batch request to to a queue
//       batches.push(
//         new Promise((response) => {
//           collection(firestore, 'stripeCharges')
//             where('created', '>=', startDate)
//             where('created', '<=', endDate)
//             where('destination', 'in', [...batch])
//             .get()
//             .then((results) => response(results.docs.map((result) => ({ ...result.data() }))));
//         })
//       );
//     }

//     // after all of the data is fetched, return it
//     Promise.all(batches).then((content) => {
//       res(content.flat());
//       return [content];
//     });
//   });
// }
export function useGetTerminalOrdersList() {
	return useQuery(collection(firestore, 'terminalOrders'))
}

export function useGetReadersReportList() {
	return useQuery(collection(firestore, 'readersReport'))
}

export function useGetUploadReadersReportTask() {
	return useDocQuery(doc(collection(firestore, 'workerTasks'), 'readers_report.upload'))
}

export const getDocIfExists = (d: QueryDocumentSnapshot | DocumentSnapshot): any =>
	d.exists() ? { id: d.id, ...d.data() } : null

export const formatDocs = (response: QuerySnapshot) => response.docs.map(getDocIfExists)

export function getChargesByClientId(clientId, accountID, startDate) {
	return getDocs(
		query(
			collection(firestore, 'stripeCharges'),
			where('payment_method_details.type', '==', 'us_bank_account'),
			where('metadata.clientId', '==', clientId),
			where('transfer_data.destination', '==', accountID),
			where('created', '>=', startDate),
			orderBy('created', 'desc'),
			limit(1)
		)
	)
		.then(formatDocs)
		.then((docs) => (docs ? docs[0] : null))
}

export const getPaymentByClientIdAndAmount = (clientId, accountID, date, amount) =>
	getDocs(
		query(
			collection(firestore, 'paymentIntents'),
			where('metadata.clientId', '==', clientId),
			where('transfer_data.destination', '==', accountID),
			where('amount', '==', amount),
			where('status', '==', STATE_SUCCEEDED),
			where('created', '>=', date),
			limit(1)
		)
	)
		.then(formatDocs)
		.then((docs) => (docs ? docs[0] : null))

export function updateTemplateFormFields(templateId, updatedFields) {
	return setDoc(
		doc(collection(firestore, 'customFormTemplates'), templateId),
		{ fields: updatedFields },
		{ merge: true }
	)
}

export function useSpecialName() {
	return useQuery(query(collection(firestore, 'specialName'), orderBy('name', 'asc')))
}

export function useReturnedChecks(accountID) {
	return useQuery(
		accountID
			? query(
					collection(firestore, COLLECTION_NAME_RETRUNED_CHECKS),
					where('destination', '==', accountID),
					orderBy('createdAt', 'desc')
				)
			: null
	)
}

export function useReturnedChecksByAcctIDAndDateRange(accountID, startDate, endDate) {
	return useQuery(
		accountID
			? query(
					collection(firestore, COLLECTION_NAME_RETRUNED_CHECKS),
					where('destination', '==', accountID),
					where('dateAdjustedInPIMS', '>=', startDate),
					where('dateAdjustedInPIMS', '<=', endDate),
					orderBy('dateAdjustedInPIMS', 'desc')
				)
			: null
	)
}

export function useReturnedChecksForConsolidator(
	practices,
	startDate,
	endDate,
	chunkSize: number = DEFAULT_PRACTICE_CHUNK_SIZE
) {
	return useQueryForPractices(
		query(
			collection(firestore, COLLECTION_NAME_RETRUNED_CHECKS),
			where('dateAdjustedInPIMS', '>=', startDate),
			where('dateAdjustedInPIMS', '<=', endDate),
			orderBy('dateAdjustedInPIMS', 'desc')
		),
		practices,
		'destination',
		chunkSize
	)
}

export const getChargeById = (id: string): Promise<Charge> =>
	getDoc(doc(collection(firestore, COLLECTION_NAME_CHARGES), id)).then(getDocIfExists)
export const getPaymentIntentById = (id: string): Promise<PaymentIntent> =>
	getDoc(doc(collection(firestore, COLLECTION_NAME_PAYMENT_INTENTS), id)).then(getDocIfExists)
export const getInvoiceById = (id: string): Promise<Stripe.Invoice> =>
	getDoc(doc(collection(firestore, COLLECTION_NAME_INVOICES), id)).then(getDocIfExists)

export function getFirstWebPaymentPracticeByPracticeId(practiceId) {
	return getDocs(
		query(
			collection(firestore, COLLECTION_WEB_PAYMENT_PRACTICES),
			where('stripe_account_id', '==', practiceId)
		)
	)
		.then(formatDocs)
		.then((docs) => (docs ? docs[0] : null))
}

export function getPhoneLookup(phoneNumber) {
	return getDocs(
		query(collection(firestore, 'twilioLookupResponse'), where(documentId(), '==', phoneNumber))
	)
		.then(formatDocs)
		.then((docs) => (docs ? docs[0] : null))
}

export function getCustomer(accountID, clientId) {
	return getDocs(
		query(
			collection(firestore, COLLECTION_NAME_CUSTOMERS),
			where('metadata.stripeAccountId', '==', accountID),
			where('metadata.clientId', '==', clientId),
			orderBy('created', 'desc'),
			limit(1)
		)
	)
		.then(formatDocs)
		.then((docs) => (docs ? docs[0] : null))
}

export async function getCustomerWithSavedCard(accountID: string, clientId: string) {
	return getDocs(
		query(
			collection(firestore, COLLECTION_NAME_CUSTOMERS),
			where('metadata.stripeAccountId', '==', accountID),
			where('metadata.clientId', '==', clientId),
			where('metadata.hasSavedCard', '==', 'true'),
			orderBy('created', 'desc'),
			limit(1)
		)
	)
		.then(formatDocs)
		.then((docs) => (docs ? docs[0] : null))
}

export function getAllCustomersWithSavedCard(accountID) {
	return useQuery(
		query(
			collection(firestore, COLLECTION_NAME_CUSTOMERS),
			where('metadata.stripeAccountId', '==', accountID),
			where('metadata.hasSavedCard', '==', 'true'),
			orderBy('name')
		)
	)
}

export function useDisputes(consolidatorId) {
	return useQuery(
		query(
			collection(firestore, COLLECTION_NAME_DISPUTES),
			where('practiceData.consolidatorId', '==', consolidatorId),
			orderBy('evidence_details.due_by', 'desc')
		)
	)
}

export function getClientsbyClinic(clinicId) {
	return getDocs(query(collection(firestore, 'pimsClient'), where('ClinicID', '==', clinicId)))
		.then(formatDocs)
		.then((docs) => docs || null)
}

export function useDisputesByPractice(practiceId) {
	return useQuery(
		practiceId
			? query(
					collection(firestore, COLLECTION_NAME_DISPUTES),
					where('charge.destination', '==', practiceId),
					orderBy('evidence_details.due_by', 'desc')
				)
			: null
	)
}

// return disputes that needs evidence submission
export function useDisputesByEvidenceNeeded(practiceId) {
	return useQuery(
		practiceId
			? query(
					collection(firestore, COLLECTION_NAME_DISPUTES),
					where('charge.destination', '==', practiceId),
					where('status', 'in', ['needs_response', 'warning_needs_response'])
				)
			: null
	)
}

export async function getPracticeInstance(id) {
	const locationDocRef = doc(collection(firestore, 'locations'), id)
	const locationDoc = await getDoc(locationDocRef)
	if (!locationDoc.exists()) {
		return {}
	}

	const locationData = locationDoc.data()

	let instances: DocumentData[] = []
	if (locationData.status === 'online') {
		const instancesCollectionRef = collection(locationDocRef, 'instances')
		const instancesSnapshot = await getDocs(instancesCollectionRef)
		instances = instancesSnapshot.docs.map((d) => d.data())
	}

	return {
		...locationData,
		connectedInstances: instances
	}
}

export function useConversations(practiceId) {
	return useQuery(
		practiceId
			? query(
					collection(firestore, COLLECTION_CONVERSATIONS),
					where('practiceId', '==', practiceId)
				)
			: null
	)
}

export function useConversationsMessages(conversationId) {
	return useQuery(
		conversationId
			? query(
					collection(firestore, COLLECTION_CONVERSATION_MESSAGES),
					where('conversationId', '==', conversationId),
					orderBy('dateCreated')
				)
			: null
	)
}

export async function getUnCompletedActionNeededByConsolidatorID(consolidatorID) {
	return getDocs(
		query(
			collection(firestore, 'actionNeeded'),
			where('metadata.consolidatorId', '==', consolidatorID),
			where('isComplete', '==', false),
			orderBy('created', 'desc'),
			orderBy('destination', 'desc')
		)
	).then(formatDocs)
}

export async function getPracticesByConsolidatorID(consolidatorID) {
	return getDocs(
		query(collection(firestore, 'practices'), where('consolidatorId', '==', consolidatorID))
	).then(formatDocs)
}

export async function getPracticesByConsolidatorIDAndClinicPIMSLocationID(
	consolidatorID,
	clinicPIMsLocationID
) {
	return getDocs(
		query(
			collection(firestore, 'practices'),
			where('consolidatorId', '==', consolidatorID),
			where('clinicPIMsLocationID', '==', clinicPIMsLocationID)
		)
	).then(formatDocs)
}

export const getCustomersByIds = (ids) =>
	getDocs(
		query(collection(firestore, COLLECTION_NAME_CUSTOMERS), where(documentId(), 'in', ids))
	).then(formatDocs)

export function useAppointmentRequestByID(practiceId, appointmentRequestId) {
	return useQuery(
		practiceId && appointmentRequestId
			? query(
					collection(firestore, 'appointmentInsertQueue'),
					where('clinicId', '==', practiceId),
					where('appointmentRequestId', '==', appointmentRequestId),
					limit(1)
				)
			: null
	)
}
