import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useLocation, Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, Button, Chip, Drawer, Hidden, Grid, Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useSelector, useDispatch } from 'react-redux'
import { getPendingActionItemsV2 } from 'apis/actionNeeded'
import { setConsolidatorActionItemsCount } from 'slices/actionNeeded'
import { getReportingSectionProps } from 'services/dashboardSidebar.service'
import { isEmpty } from 'lodash'
import { selectAppointmentRequestsCount } from '../../selectors/apptRequests'
import {
	DRAWER_WIDTH,
	FOOTER_HEIGHT,
	HEADER_HEIGHT,
	PRACTICE_ROLE_HOSPITAL_MANAGER,
	RequestLevel,
	ROUTES
} from '../../constants'
import Scrollbar from '../Scrollbar'
import { useCurrentPractice } from '../../contexts/PracticeContext'
import {
	isCheckOnlyPractice,
	shouldShowCommunicationsSection,
	shouldShowPaymentRequestsPage
} from '../../util/practice'
import { selectUser } from '../../selectors/auth'
import { isAdminUser, isScratchAdminUser } from '../../util/user'
import CustomizedLabels from '../../util/CustomizedLabels'
import { getAdminSectionProps, shouldHideComingSoon } from '../../util/consolidator'
import { RootState } from '../../types/store'
import { isUnifiedEnabled } from '../../util/unified'
import AccordionSection from '../AccordionSection'
import { CustomBadge } from '../common/CustomBadge'
import { ScratchLogo } from 'components/common/ScratchLogo'

export interface NavItem {
	title: string
	path: string
	icon?: React.ReactNode
	practiceAllowedProperty?: string
	chip?: React.ReactNode
}

const actionNeededLink = (actionNeededCount?: number): NavItem => ({
	title: 'Action Items',
	path: '/dashboard/ActionNeeded',
	icon: <CustomBadge color="error" badgeContent={actionNeededCount} />
})

export const getPaymentHistoryLink = (showPaymentHistoryV2?: boolean) =>
	!showPaymentHistoryV2 ? '/dashboard/payments' : '/dashboard/payments-v2'

const getDailyTotalsLink = (useDailyTotalsV2?: boolean) =>
	useDailyTotalsV2 ? '/dashboard/dailyTotals-v2' : '/dashboard/dailyTotals'

const getPaymentSection = (
	disputesNotificationCount: number | undefined,
	showPaymentRequests: boolean
): { title: string; items: NavItem[] }[] => [
	{
		title: 'Payments',
		items: [
			{
				title: 'Collect Payment',
				path: '/dashboard/collect-payment'
			},
			{
				...(showPaymentRequests
					? {
							title: 'Open Invoices',
							path: '/dashboard/invoices-v2'
						}
					: {
							title: 'Open Invoices',
							path: '/dashboard/invoices'
						})
			},
			{
				title: 'Disputes',
				path: '/dashboard/disputes',
				icon: <CustomBadge color="error" badgeContent={disputesNotificationCount} />
			}
		]
	}
]

const getReportingSection = (
	reportingSectionProps: ReportingSectionProps,
	consolidatorId: string | undefined,
	actionNeededCountV2?: number
): { title: string; items: NavItem[] }[] => {
	const sections: { title: string; items: NavItem[] }[] = [
		{
			title: 'Reporting',
			items: [
				{
					title: 'Daily Totals',
					path: getDailyTotalsLink(reportingSectionProps.showDailyTotalsV2)
				},
				{
					title: 'Payment History',
					path: getPaymentHistoryLink(reportingSectionProps.showPaymentHistoryV2)
				},
				{
					...actionNeededLink(actionNeededCountV2)
				}
			]
		}
	]

	if (reportingSectionProps.showMonthlyStatements) {
		// enabled for independents
		sections[0].items.push({
			title: 'Monthly Statements',
			path: '/dashboard/monthlyTotals'
		})
	}

	if (reportingSectionProps.showCheckPayments || reportingSectionProps.showECheckReportingPage) {
		sections[0].items.push({
			title: 'eCheck Reporting',
			path: '/dashboard/checkReporting'
		})
	}
	if (!reportingSectionProps.hideReconciliation) {
		sections[0].items.push({
			title: 'Reconciliation',
			path: '/dashboard/reconciliation'
		})
	}

	if (!reportingSectionProps.hideAccountsReceivable) {
		sections[0].items.push({
			title: 'Accounts Receivable',
			path: '/dashboard/ar'
		})
	}
	if (!reportingSectionProps.hidePayoutsPage) {
		const path =
			// Require it to be explicitly off
			reportingSectionProps.showCustomPayoutsV2 !== false
				? '/dashboard/payouts-v2'
				: '/dashboard/payouts'
		sections[0].items.push({
			title: 'Payouts',
			path,
			chip: <Chip label="Chip Filled" />
		})
	}

	if (reportingSectionProps.showInstallmentPlans) {
		sections[0].items.push({
			title: CustomizedLabels.getCustomLabel(
				'installmentPlans.reporting.sidebarTitle',
				'Recurring Payments'
			),
			path: '/dashboard/installment-plans'
		})

		sections[0].items.push({
			title: CustomizedLabels.getCustomLabel(
				'installmentPlans.reporting.pastDue.sidebarTitle',
				'Late Recurring Payments'
			),
			path: '/dashboard/past-due-plans'
		})
	}

	if (reportingSectionProps.showSavedCards) {
		sections[0].items.push({
			title: 'Stored Cards',
			path: '/dashboard/storedcard'
		})
	}

	if (reportingSectionProps.showRefundRequests) {
		sections[0].items.push({
			title: 'Refund Requests',
			path: '/dashboard/refund-requests'
		})
	}

	return sections
}

const independantPracticesSection: { title: string; items: NavItem[] }[] = [
	{
		title: 'Settings',
		items: [
			{
				title: 'Banking Information',
				path: '/settings/banking-info'
			},
			{
				title: 'Required Information',
				path: '/settings/required-info'
			}
		]
	}
]

const getAdminSectionItems = (props: AdminSectionProps): { title: string; items: NavItem[] }[] => {
	const unifiedEnabled = isUnifiedEnabled()
	const items: { title: string; items: NavItem[] }[] = [
		{
			title: 'Administrator',
			items: [
				{
					title: 'Locations',
					path: !unifiedEnabled ? '/dashboard/practices' : '/dashboard/practices-v2'
				},
				{
					title: 'Users',
					path: '/dashboard/users'
				},
				{
					title: 'All Open Invoices',
					path: '/dashboard/all-invoices-v2'
				},
				{
					title: 'All Payouts',
					path:
						props.showCustomPayoutsV2 !== false
							? '/dashboard/allpayouts-v2'
							: '/dashboard/allpayouts'
				},
				{
					title: 'Billing',
					path: '/dashboard/Billing'
				}
			]
		}
	]

	if (props.showConsolidatorPaymentHistory) {
		items[0].items.splice(2, 0, {
			title: 'All Payment History',
			path: '/dashboard/all-payments'
		})
	}

	if (props.showAccountsReceivablePage) {
		items[0].items.splice(3, 0, {
			title: 'Accounts Receivables',
			path: '/dashboard/allAR'
		})
	}

	if (props.showVendorNamesPage) {
		items[0].items.splice(7, 0, {
			title: 'Vendor Names',
			path: '/VendorNames'
		})
	}

	if (props.showOpenActionItemsPage) {
		items[0].items.splice(8, 0, {
			title: 'Open Action Items',
			path: '/dashboard/open-action-items',
			icon: <CustomBadge badgeContent={props.openActionItemsCount} />
		})
	}

	if (props.showHelpDeskPage) {
		items[0].items.splice(9, 0, {
			title: 'Help Desk',
			path: '/dashboard/help-desk'
		})
	}

	if (props.showPostcardPage) {
		items[0].items.splice(10, 0, {
			title: 'Postcard',
			path: '/dashboard/postcard-file'
		})
	}

	if (props.showLockboxChecksPage) {
		items[0].items.splice(11, 0, {
			title: 'Lockbox Checks',
			path: '/LockboxChecks'
		})
	}

	if (props.showCheckReportingPage) {
		const checkReportingUrl = props.showCheckReportingV2
			? '/dashboard/echeck-lockbox-report-v2'
			: '/dashboard/echeck-lockbox-report'
		items[0].items.splice(6, 0, {
			title: 'eCheck and Lockbox Report',
			path: checkReportingUrl
		})
	}

	if (props.showAllInvoicesReportingPage) {
		items[0].items.push({
			title: 'Invoices',
			path: '/dashboard/all-invoices'
		})
	}

	if (props.showAllDisputesPage) {
		items[0].items.push({
			title: 'All Disputes',
			path: '/dashboard/all-disputes'
		})
	}

	if (props.showDailyTransactionFeed) {
		items[0].items.push({
			title: 'Daily Transaction Feed',
			path: '/dashboard/daily-feed'
		})
	}

	if (props.showConsolidatorMonthlyReport) {
		items[0].items.push({
			title: 'Monthly Reports',
			path: '/dashboard/monthly-report'
		})
	}

	return items
}

const sectionScratchAdmin: { title: string; items: NavItem[] }[] = [
	{
		title: 'Scratch Management',
		items: [
			{
				title: 'All Disputes',
				path: '/dashboard/alldisputes'
			},
			{
				title: 'All Locations',
				path: '/dashboard/allpractices'
			},
			{
				title: 'Products',
				path: '/dashboard/Products'
			},
			{
				title: 'Terminal Orders',
				path: '/dashboard/terminal-orders'
			},
			{
				title: 'Readers Reporting',
				path: '/dashboard/readers-reporting'
			},
			{
				title: 'Accounts Invitations',
				path: '/dashboard/accounts/invitations'
			},
			{
				title: 'Billing v2 (beta)',
				path: '/dashboard/Billing-v2'
			},
			{
				title: 'Opt Outs',
				path: '/dashboard/opt-outs'
			},
			{
				title: 'Custom Payouts',
				path: '/dashboard/custom-payouts'
			}
		]
	}
]

const sectionCommunications = (
	appointmentRequestCount,
	isDirectBookingEnabled,
	unreadMessages
): { title: string; items: NavItem[] }[] => [
	{
		title: 'Communications',
		items: [
			{
				title: 'Chat',
				path: '/dashboard/chat/new',
				icon: <CustomBadge color="error" badgeContent={unreadMessages} />,
				practiceAllowedProperty: 'isChatEnabled'
			},
			{
				title: 'Reminders',
				path: '/communication/reminders/reporting',
				practiceAllowedProperty: 'isRemindersEnabled'
			},
			{
				title: isDirectBookingEnabled ? 'Appointments' : 'Appt. Requests',
				path: '/communication/appointment-requests',
				icon: <CustomBadge color="error" badgeContent={appointmentRequestCount} />,
				practiceAllowedProperty: 'isAppointmentRequestsEnabled'
			},
			{
				title: 'Forms',
				path: '/communication/forms',
				practiceAllowedProperty: 'isFormsEnabled'
			},
			{
				title: 'Reputation',
				path: '/communication/reputation-management',
				practiceAllowedProperty: 'isReputationEnabled'
			},
			{
				title: 'Opt-outs',
				path: '/communication/opt-outs',
				practiceAllowedProperty: 'isOptOutsEnabled'
			},
			{
				title: 'Priority Call List',
				path: '/communication/priority-call-list',
				practiceAllowedProperty: 'isPriorityCallListEnabled'
			},
			{
				title: 'One-time',
				path: '/communication/one-time-email/create',
				practiceAllowedProperty: 'isOneTimeEmailsEnabled'
			}
		]
	}
]

const sectionsSoon: { title: string; items: NavItem[] }[] = [
	{
		title: 'Coming Soon',
		items: [
			{
				title: 'Client Communications',
				path: ''
			},
			{
				title: 'Customers',
				path: ''
			},
			{
				title: 'Appointments',
				path: ''
			}
		]
	}
]

const sectionFinancing: { title: string; items: NavItem[] }[] = [
	{
		title: 'Financing',
		items: [
			{
				title: 'Plans',
				path: '/financing/plans'
			},
			{
				title: 'Get Started',
				path: '/financing/get-started'
			},
			{
				title: 'Training',
				path: '/financing/training'
			},
			{
				title: 'Account',
				path: `/${ROUTES.accountUri}`
			}
		]
	}
]

const useStyles = makeStyles()(() => ({
	logo: {
		height: 28
		// marginRight: theme.spacing(2)
	},
	drawerList: {
		width: DRAWER_WIDTH
	},
	spacer: {
		flexGrow: 1
	},
	sidebarContainer: {
		'& .MuiBox-root .MuiListItem-root a, & .MuiButton-text': {
			padding: '3px 6px'
		},
		'& .MuiBox-root .MuiListItem-root a:not(.active), & .MuiButton-text:not(.active)': {
			color: 'inherit'
		},
		'& .MuiSvgIcon-fontSizeSmall': {
			padding: '2px'
		}
	}
}))

const DashboardSidebar = (props) => {
	const { onMobileClose, openMobile } = props
	const {
		userRolePractice,
		currentPracticeObject,
		disputesNotificationCount,
		currentUserConsolidator
	} = useCurrentPractice()
	const location = useLocation()
	const user = useSelector(selectUser)
	const appointmentRequestCount = useSelector(selectAppointmentRequestsCount)
	const { unreadMessages } = useSelector((state: any) => state?.chat)
	const { actionNeededCount: actionNeededCountV2, consolidatorActionItemsCount } = useSelector(
		(state: RootState) => state.actionNeeded
	)
	const dispatch = useDispatch()

	const admin = user?.isAdmin || false
	const scratchAdmin = user?.isScratchAdmin
	const { classes } = useStyles()

	const [sumUnreadMessages, setSumUnreadMessages] = useState(0)

	const showCommunicationsSection = useMemo(
		() => shouldShowCommunicationsSection(currentPracticeObject),
		[currentPracticeObject]
	)

	const showPaymentRequestsPage = useMemo(
		() => shouldShowPaymentRequestsPage(currentPracticeObject),
		[currentPracticeObject]
	)

	const hideComingSoon = useMemo(
		() => shouldHideComingSoon(currentUserConsolidator),
		[currentUserConsolidator]
	)

	const reportingSectionProps: ReportingSectionProps = useMemo(
		() => getReportingSectionProps(currentPracticeObject, currentUserConsolidator),
		[currentPracticeObject, currentUserConsolidator]
	)

	const paymentSection = useMemo(
		() => getPaymentSection(disputesNotificationCount, showPaymentRequestsPage),
		[disputesNotificationCount, showPaymentRequestsPage]
	)

	const reportingSection = useMemo(
		() =>
			getReportingSection(
				reportingSectionProps,
				currentUserConsolidator?.consolidatorId,
				actionNeededCountV2
			),
		[reportingSectionProps, currentUserConsolidator?.consolidatorId, actionNeededCountV2]
	)

	const sectionAdminProps: AdminSectionProps = useMemo(
		() => getAdminSectionProps(currentUserConsolidator, consolidatorActionItemsCount),
		[currentUserConsolidator, consolidatorActionItemsCount]
	)

	const sectionsAdmin = useMemo(() => getAdminSectionItems(sectionAdminProps), [sectionAdminProps])

	const communicationsSection = useMemo(
		() =>
			sectionCommunications(
				appointmentRequestCount,
				currentPracticeObject?.settings?.isDirectBookingEnabled,
				sumUnreadMessages
			),
		[
			appointmentRequestCount,
			currentPracticeObject?.settings?.isDirectBookingEnabled,
			sumUnreadMessages
		]
	)

	// Show financing if showFinancing, or unified with isDashboard, or lending only with isDashboardEnabled.
	const showFinancingDev = useMemo(
		() =>
			currentPracticeObject?.settings?.showFinancingDev ||
			((isUnifiedEnabled(user?.email) || !currentPracticeObject?.stripeAccountId) &&
				currentPracticeObject?.isDashboardEnabled),
		[currentPracticeObject, user]
	)

	// TODO: Have an explicit "isPayments"/"isLending" flag on the consolidator.
	const showPayments = useMemo(
		() => currentPracticeObject?.stripeAccountId || currentUserConsolidator?.mcc,
		[currentPracticeObject, currentUserConsolidator]
	)

	const fetchConsolidatorIncompleteActionItems = useCallback(async () => {
		const fetch = async (fetchFn: () => Promise<number>) => {
			try {
				const totalCount = await fetchFn()
				dispatch(setConsolidatorActionItemsCount(totalCount))
			} catch (err) {
				console.error(`Failed to fetch consolidator incomplete action items: ${err}`)
			}
		}

		if (
			(isAdminUser(user) || isScratchAdminUser(user)) &&
			currentUserConsolidator?.consolidatorId
		) {
			fetch(() =>
				getPendingActionItemsV2(RequestLevel.ADMIN).then(
					(res) => res.data?.listActionNeeded.total ?? 0
				)
			)
		}
	}, [dispatch, user, currentUserConsolidator])

	useEffect(() => {
		const unreadCounts = Object.values(unreadMessages) as number[]
		const sumUnreadCount = unreadCounts.reduce((a: number, v: number) => (a += v), 0)
		setSumUnreadMessages(sumUnreadCount)
	}, [unreadMessages])

	useEffect(() => {
		fetchConsolidatorIncompleteActionItems() // run immediately and then each 30 seconds

		const intervalId = setInterval(() => {
			fetchConsolidatorIncompleteActionItems()
		}, 30000)

		return () => clearInterval(intervalId)
	}, [fetchConsolidatorIncompleteActionItems])

	const checkOnlySection: { title: string; items: NavItem[] }[] = [
		{
			title: 'Payments',
			items: [
				{
					title: 'Collect Payment',
					path: '/dashboard/collect-payment'
				}
			]
		},
		{
			title: 'Reporting',
			items: [
				{
					title: 'Payment History',
					path: getPaymentHistoryLink(reportingSectionProps.showPaymentHistoryV2)
				},
				{
					title: 'eCheck Reporting',
					path: '/dashboard/checkReporting'
				},
				{
					...actionNeededLink(actionNeededCountV2)
				}
			]
		}
	]

	const isIndependantPracticeUser =
		user.initialRole === PRACTICE_ROLE_HOSPITAL_MANAGER &&
		!isEmpty(currentPracticeObject) &&
		!currentPracticeObject.consolidatorId

	const showPaymentsSignUp = useMemo(
		() =>
			user && !showPayments && currentPracticeObject?.id && !currentPracticeObject?.consolidatorId,
		[user, showPayments, currentPracticeObject]
	)

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose()
		}
	}, [location.pathname])

	// Each collapsible section with its title and boolean conditional for being shown
	const sidebarSections = useMemo(
		() => [
			{
				title: paymentSection[0].title,
				condition: user && showPayments,
				items: paymentSection[0].items
			},
			{
				title: reportingSection[0].title,
				condition: user && showPayments,
				items: reportingSection[0].items
			},
			{
				title: sectionFinancing[0].title,
				condition: user && showFinancingDev,
				items: sectionFinancing[0].items
			},
			{
				title: 'Payments',
				condition: showPaymentsSignUp,
				signupLink: '/dashboard/payments/sign-up',
				items: []
			},
			{
				title: communicationsSection[0].title,
				condition: user && showCommunicationsSection,
				items: communicationsSection[0].items
			},
			{
				title: independantPracticesSection[0].title,
				condition: user && isIndependantPracticeUser,
				items: independantPracticesSection[0].items
			},
			{
				title: sectionsAdmin[0].title,
				condition: user && admin === true,
				items: sectionsAdmin[0].items
			},
			{
				title: sectionScratchAdmin[0].title,
				condition: user && scratchAdmin === true,
				items: sectionScratchAdmin[0].items
			},
			{
				title: sectionsSoon[0].title,
				condition: !hideComingSoon,
				items: sectionsSoon[0].items
			}
		],
		[
			paymentSection,
			user,
			showPayments,
			reportingSection,
			showFinancingDev,
			showPaymentsSignUp,
			communicationsSection,
			showCommunicationsSection,
			isIndependantPracticeUser,
			admin,
			userRolePractice?.hospitalManager,
			sectionsAdmin,
			scratchAdmin,
			hideComingSoon
		]
	)

	const [expandedSection, setExpandedSection] = useState<string | null>(null)
	const [isInitialLoad, setIsInitialLoad] = useState(true)
	const userInteractionRef = useRef(false)

	const visibleSections = useMemo(
		() => sidebarSections.filter((section) => Boolean(section.condition)),
		[sidebarSections]
	)

	const currentSectionFromPath = useMemo(
		() =>
			visibleSections.find(
				(section) =>
					section.items?.some((item) => item.path === location.pathname) ||
					section.signupLink === location.pathname
			),
		[visibleSections, location.pathname]
	)

	useEffect(() => {
		if (!user || isEmpty(currentPracticeObject)) return

		if (isInitialLoad || (!expandedSection && visibleSections.length > 0)) {
			const firstVisibleSection = visibleSections[0]?.title
			if (firstVisibleSection) {
				setExpandedSection(firstVisibleSection)
			}
			setIsInitialLoad(false)
		} else if (
			currentSectionFromPath &&
			currentSectionFromPath.title !== expandedSection &&
			!userInteractionRef.current
		) {
			setExpandedSection(currentSectionFromPath.title)
		}

		userInteractionRef.current = false
	}, [
		user,
		currentPracticeObject,
		visibleSections,
		expandedSection,
		isInitialLoad,
		currentSectionFromPath,
		location.pathname
	])

	const handleAccordionChange = useCallback(
		(sectionTitle: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
			userInteractionRef.current = true
			setExpandedSection(isExpanded ? sectionTitle : null)
		},
		[]
	)

	const content = (
		<Box
			className={classes.sidebarContainer}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%'
			}}
		>
			<Scrollbar options={{ suppressScrollX: true }} style={{ flexGrow: 1 }}>
				<Box sx={{ flexGrow: 1, p: 2 }}>
					<Box sx={{ ml: 2 }} />
					<Box
						sx={{
							alignItems: 'center',
							borderRadius: 1,
							display: 'flex',
							overflow: 'hidden',
							p: 2
						}}
					/>
					<Box
						sx={{
							alignItems: 'left',
							display: 'flex',
							justifyContent: 'start',
							pl: 2
						}}
					>
						<RouterLink to="/">
							<ScratchLogo size={30} />
						</RouterLink>
					</Box>
					{visibleSections.map((section) => (
						<Box
							key={section.title}
							sx={(theme: Theme) => ({
								position: 'relative',
								'&::after': {
									content: '""',
									position: 'absolute',
									bottom: 0,
									left: 0,
									right: 0,
									height: '1px',
									backgroundColor: theme.palette.mode === 'dark' ? '#333545' : '#E8E8FF'
								},
								'&:last-child::after': {
									display: 'none'
								}
							})}
						>
							<AccordionSection
								key={section.title}
								title={section.title}
								items={section.items}
								pathname={location.pathname}
								expanded={expandedSection === section.title}
								onChange={handleAccordionChange(section.title)}
								signupLink={section.signupLink}
								badgeCount={getTotalBadgeCount(section.items)}
							/>
						</Box>
					))}
				</Box>
			</Scrollbar>
			<BottomSection
				faqUrl={
					location.pathname.includes('/financing')
						? 'https://scratchpay-lending.stonly.com/kb/en'
						: 'https://scratchcheckout.stonly.com/kb/guide/en/how-to-process-payments-on-the-scratch-dashboard-WZjA6v2ISR/Steps/2185474?lang=en'
				}
			/>
		</Box>
	)

	const checkOnlyPracticeContent = (
		<Box
			className={classes.sidebarContainer}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				position: 'relative'
			}}
		>
			<Scrollbar options={{ suppressScrollX: true }}>
				<Box sx={{ ml: 2 }} />
				<Box
					sx={{
						alignItems: 'center',
						borderRadius: 1,
						display: 'flex',
						overflow: 'hidden',
						p: 2
					}}
				/>
				<Box sx={{ p: 2 }}>
					{checkOnlySection.map((section) => (
						<AccordionSection
							key={section.title}
							title={section.title}
							items={section.items}
							pathname={location.pathname}
							expanded={expandedSection === section.title}
							onChange={handleAccordionChange(section.title)}
							badgeCount={getTotalBadgeCount(section.items)}
						/>
					))}
				</Box>
			</Scrollbar>
			<BottomSection faqUrl="https://scratchpay.com/faqs/how-do-i-process-a-check-payment" />
		</Box>
	)
	return (
		<>
			<Hidden lgUp>
				<Drawer
					anchor="left"
					onClose={onMobileClose}
					open={openMobile}
					PaperProps={{
						sx: {
							backgroundColor: 'background.default',
							backgroundImage: 'none',
							boxShadow: 'none',
							width: DRAWER_WIDTH,
							paddingTop: HEADER_HEIGHT,
							paddingBottom: FOOTER_HEIGHT
						}
					}}
					variant="temporary"
				>
					{isCheckOnlyPractice(currentPracticeObject) ? checkOnlyPracticeContent : content}
				</Drawer>
			</Hidden>
			<Hidden lgDown>
				<Drawer
					anchor="left"
					open
					PaperProps={{
						sx: {
							borderRight: 0,
							width: DRAWER_WIDTH,
							position: 'relative'
						}
					}}
					variant="persistent"
				>
					{isCheckOnlyPractice(currentPracticeObject) ? checkOnlyPracticeContent : content}
				</Drawer>
			</Hidden>
		</>
	)
}

const getTotalBadgeCount = (items: NavItem[]) =>
	items.reduce((total, item) => {
		if (item.icon && React.isValidElement(item.icon) && item.icon.props.badgeContent) {
			return total + item.icon.props.badgeContent
		}
		return total
	}, 0)

const BottomSection = ({ faqUrl }) => (
	<Box
		sx={{
			p: 3,
			mt: 'auto'
		}}
	>
		<Grid container spacing={2}>
			<Grid item xs={6}>
				<Button
					fullWidth
					color="primary"
					sx={{
						padding: '4px 15px',
						border: 'none',
						backgroundColor: '#e8e8ff',
						borderRadius: '8px',
						fontWeight: '600',
						color: '#6376f5 !important',
						fontSize: '12px'
					}}
					size="small"
					data-cy="faq-button"
					target="_blank"
					rel="noreferrer"
					href={faqUrl}
				>
					FAQs
				</Button>
			</Grid>
			<Grid item xs={6}>
				<Button
					fullWidth
					color="primary"
					sx={{
						padding: '4px 15px',
						border: 'none',
						backgroundColor: '#e8e8ff',
						borderRadius: '8px',
						fontWeight: '600',
						color: '#6376f5 !important',
						fontSize: '12px'
					}}
					size="small"
					data-cy="faq-button"
					component={Link}
					to="/dashboard/account"
				>
					Settings
				</Button>
			</Grid>
		</Grid>
	</Box>
)

BottomSection.propTypes = {
	faqUrl: PropTypes.string.isRequired
}

DashboardSidebar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool
}

export default DashboardSidebar
