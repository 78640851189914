import { useState, useEffect, useCallback } from 'react'
import { format } from 'date-fns'
import { Link as RouterLink } from 'react-router-dom'
import {
	Badge,
	Box,
	Button,
	ButtonGroup,
	Divider,
	IconButton,
	Typography,
	Popover,
	List,
	ListItem,
	ListItemText,
	Tooltip
} from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { createClient } from 'urql'
import { LIST_ACTION_NEEDED, gqlClientOptions } from 'gql'

import { SortOrder } from 'gql/generated-types/custom-graphql'
import { setActionNeededCount, setActionNeededList } from '../../slices/actionNeeded'
import { usePopover } from '../../hooks/usePopover'
import { Speakerphone as SpeakerphoneIcon } from '../../icons/speakerphone'
import { useCurrentPractice } from '../../contexts/PracticeContext'
import { RequestLevel } from '../../constants'
import { RootState } from '../../types/store'
import { notificationTypes } from './notifications/NotificationsHelper'

export const NotificationsPopoverV2 = () => {
	const { anchorRef, open, handleOpen, handleClose } = usePopover()
	const [badgeNumber, setBadgeNumber] = useState(0)
	const { currentPracticeObject } = useCurrentPractice()
	const [loading, setLoading] = useState(false)
	const dispatch = useDispatch()

	const { actionNeededList: notifications } = useSelector((state: RootState) => state.actionNeeded)

	const fetchIncompleteActionNeeded = useCallback(async () => {
		const graphQLClientOptions = {
			requestLevel: RequestLevel.PRACTICE,
			practiceId: currentPracticeObject.id
		}
		const graphQLClient = createClient(gqlClientOptions(graphQLClientOptions))
		const queryResult = await graphQLClient
			.query(LIST_ACTION_NEEDED, {
				where: { isComplete: false },
				orderBy: { stripeCreatedDate: SortOrder.Desc },
				take: 5
			})
			.toPromise()

		if (queryResult.error) {
			return console.error(queryResult.error)
		}

		const { items: result, total: totalCount } = queryResult?.data?.listActionNeeded || {}
		setBadgeNumber(totalCount || 0)
		dispatch(setActionNeededList(result || []))
		dispatch(setActionNeededCount(totalCount))
		setLoading(false)
	}, [currentPracticeObject.id, dispatch])

	useEffect(() => {
		setLoading(true)
		fetchIncompleteActionNeeded() // run immediately and update notifications every 30 seconds
		const intervalId = setInterval(() => {
			fetchIncompleteActionNeeded()
		}, 30000)
		return () => clearInterval(intervalId)
	}, [fetchIncompleteActionNeeded])

	return (
		<>
			<Tooltip title="Outstanding Notifications">
				<IconButton color="inherit" onClick={handleOpen} ref={anchorRef} size="large">
					{!loading && (
						<Badge color="error" badgeContent={badgeNumber}>
							<SpeakerphoneIcon fontSize="small" />
						</Badge>
					)}
					{loading && (
						<Badge color="info" badgeContent="...">
							<SpeakerphoneIcon fontSize="small" />
						</Badge>
					)}
				</IconButton>
			</Tooltip>

			<Popover
				anchorEl={anchorRef.current}
				anchorOrigin={{
					horizontal: 'center',
					vertical: 'bottom'
				}}
				keepMounted
				onClose={handleClose}
				open={open}
				PaperProps={{
					sx: { width: 320 }
				}}
			>
				<List>
					<Box sx={{ ml: 2, mb: 0 }}>
						<Typography sx={{ py: 1 }}>
							<b>Notifications:</b>
						</Typography>
						<Divider />
					</Box>
					{(loading || notifications.length === 0) && (
						<Box py={2} px={3} alignContent="center">
							{!loading && notifications.length === 0 && (
								<Box sx={{ p: 0, ml: 1 }}>
									<Typography sx={{ py: 1 }}>No outstanding notifications 👍</Typography>
								</Box>
							)}
						</Box>
					)}
					{notifications?.map((notification, index) => {
						const { stripeCreatedDate, actionItemType } = notification
						return (
							<ListItem
								disableGutters
								divider={notifications.length > index + 1}
								key={notification.id}
								sx={{
									display: 'flex',
									alignItems: 'flex-start',
									flexDirection: 'column',
									p: 2
								}}
							>
								<ListItemText>
									<Box sx={{ display: 'flex', mb: 1 }}>
										<img src="/static/icons/bell.svg" width="20" alt="" />
										<Typography color="textPrimary" sx={{ ml: 1.25 }} variant="body2">
											<b>{notificationTypes[actionItemType]?.title}</b>
										</Typography>
									</Box>
									<Typography color="textSecondary" variant="caption">
										<i>{format(new Date(stripeCreatedDate), 'MMM dd, yyyy')}</i>
									</Typography>
									{': '}{' '}
									<Typography color="textSecondary" variant="caption">
										{`Alert: ${notificationTypes[actionItemType]?.content}`}
									</Typography>
								</ListItemText>
								<Box sx={{ mt: 1 }}>
									<ButtonGroup>
										<Button
											color="primary"
											component={RouterLink}
											to="/dashboard/ActionNeeded"
											size="small"
											variant="contained"
											data-cy="more-info-button"
										>
											More Info
										</Button>
									</ButtonGroup>
								</Box>
							</ListItem>
						)
					})}
				</List>
			</Popover>
		</>
	)
}

export default NotificationsPopoverV2
